import axios from 'axios';
import history from '../history';
import config from '../config';
import { notification } from 'antd';

const apiUrl = config.apiUrl;

const defaultOptions = url => ({
  timeout: 30000,
  baseURL: `${url}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getToken = tokenName => `Bearer ${localStorage.getItem(tokenName)}`;

const processQueue = (error, token = null, failedQueue) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueueAccount = [];
};

const handleGeneralResponseError = (
  error,
  originalRequest,
  refreshTokenName,
  tokenName,
  isRefreshing,
  failedQueue
) => {
  const refreshToken = () => localStorage.getItem(refreshTokenName);
  const refreshAccessToken = () =>
    axios.post(`${apiUrl}auth/refresh-token`, { refreshToken: refreshToken() });
  const updateToken = data => {
    localStorage.setItem(refreshTokenName, data.refreshToken);
    localStorage.setItem(tokenName, data.accessToken);
  };
  const removeTokenAndRedirect = () => {
    localStorage.clear();
    history.push('/login');
    notification.warning({
      message: 'Your session is expired',
    });
  };

  if (error.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({
          resolve,
          reject,
        });
      })
        .then(() => {
          originalRequest.headers.Authorization = getToken(tokenName);
          return axios(originalRequest);
        })
        .catch(err => err);
    }
    if (originalRequest.headers.Authorization !== getToken(tokenName)) {
      originalRequest.headers.Authorization = getToken(tokenName);
      return Promise.resolve(axios(originalRequest));
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      refreshAccessToken()
        .then(({ data }) => {
          updateToken(data);
          processQueue(null, data.token, failedQueue);
          originalRequest.headers.Authorization = getToken(tokenName);
          resolve(axios(originalRequest));
        })
        .catch(err => {
          processQueue(err, null, failedQueue);
          removeTokenAndRedirect();
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }
  return Promise.reject(error);
};

export const ApiService = axios.create(defaultOptions(apiUrl));
export const AuthorizedApiService = axios.create(defaultOptions(apiUrl));
export const ParentAuthorizedService = axios.create(defaultOptions(apiUrl));
export const ChildAuthorizedService = axios.create(defaultOptions(apiUrl));

let isRefreshingAccount = false;
let failedQueueAccount = [];

AuthorizedApiService.interceptors.request.use(req => {
  req.headers.Authorization = getToken('account_token');
  return req;
});
AuthorizedApiService.interceptors.response.use(
  res => res,
  err =>
    handleGeneralResponseError(
      err.response,
      err.config,
      'account_refresh_token',
      'account_token',
      isRefreshingAccount,
      failedQueueAccount
    )
);

let isRefreshingParent = false;
let failedQueueParent = [];

ParentAuthorizedService.interceptors.request.use(req => {
  req.headers.Authorization = getToken('parent_profile_token');
  return req;
});
ParentAuthorizedService.interceptors.response.use(
  res => res,
  err =>
    handleGeneralResponseError(
      err.response,
      err.config,
      'parent_profile_refresh_token',
      'parent_profile_token',
      isRefreshingParent,
      failedQueueParent
    )
);

let isRefreshingChild = false;
let failedQueueChild = [];

ChildAuthorizedService.interceptors.request.use(req => {
  req.headers.Authorization = getToken('child_profile_token');
  return req;
});
ChildAuthorizedService.interceptors.response.use(
  res => res,
  err =>
    handleGeneralResponseError(
      err.response,
      err.config,
      'child_profile_refresh_token',
      'child_profile_token',
      isRefreshingChild,
      failedQueueChild
    )
);
