/* eslint-disable no-useless-escape */
import { useState, useEffect } from 'react';
import moment from 'moment';
import { dateFormat, timeFormat, fullColors } from '../utils/constants';
import parentAvatar from '../images/layout/parentAvatar.svg';

export const useWindowsWidth = () => {
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  let checkScreenSize = () => {
    setIsScreenSmall(window.innerWidth > 768);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isScreenSmall;
};

export const setTopBorderColor = index => {
  return fullColors[index % fullColors.length];
};

const getDateAbbreviationString = (str, firstLetterUppercase) => {
  if (!firstLetterUppercase) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getDateAbbreviation = (dateTime, firstLetterUppercase = false) => {
  const today = moment().clone().startOf('day');
  const yesterday = moment().clone().subtract(1, 'days').startOf('day');
  const tomorrow = moment().clone().add(1, 'days').startOf('day');
  if (moment(dateTime).isSame(today, 'days')) {
    return getDateAbbreviationString('today', firstLetterUppercase);
  }
  if (moment(dateTime).isSame(yesterday, 'days')) {
    return getDateAbbreviationString('yesterday', firstLetterUppercase);
  }
  if (moment(dateTime).isSame(tomorrow, 'days')) {
    return getDateAbbreviationString('tomorrow', firstLetterUppercase);
  }
  return moment(dateTime).format(dateFormat);
};

export const getTimeString = dateTime => `${moment(dateTime).format(timeFormat)}`;
export const getDateTimeString = dateTime =>
  `${getDateAbbreviation(dateTime, true)} ${getTimeString(dateTime)}`;

export const getNotificationBody = (body, dateTime) => {
  let on = '';
  const dateAbbreviation = getDateAbbreviation(dateTime);
  if (moment(dateAbbreviation).isValid()) {
    on = `on ${dateAbbreviation}`;
  } else {
    on = dateAbbreviation;
  }
  return `${body} ${on} at ${getTimeString(dateTime)}`;
};

export const validateEmail = email => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const formatDate = date => {
  const courseEndDate = new Date(date);
  const yyyy = courseEndDate.getFullYear();
  let mm = courseEndDate.getMonth() + 1;
  let dd = courseEndDate.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return dd + '/' + mm + '/' + yyyy;
};

export const getFirstOfNextMonth = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 1);
  return formatDate(firstDayOfMonth);
};

export const isItemInArray = (item, array) => {
  return array?.includes(item);
};

export const englishAlphabet = value => {
  return value.match(/^[a-zA-Z ]+$/) ? false : true;
};

export const nameLength = (value = '') => {
  if (value.length > 12) {
    return value.substring(0, 12) + '...';
  }
  return value;
};

export const displayAvatar = user => {
  if (user?.type === 'CHILD') {
    if (user?.avatar) {
      return user?.avatar?.url ? user?.avatar?.url : user?.avatar;
    }
  }
  return parentAvatar;
};

export const stringLength = (value = '', stringLength) => {
  if (value.length > stringLength) {
    return value.substring(0, stringLength) + '...';
  }
  return value;
};

export const getCurrentWeek = startDate => {
  const oneDayInMiliseconds = 86400000;
  const oneDay = oneDayInMiliseconds;
  const currDate = new Date();
  const startOfCourse = new Date(startDate);

  if (
    startOfCourse.getDate() === currDate.getDate() &&
    startOfCourse.getMonth() === currDate.getMonth() &&
    startOfCourse.getYear() === currDate.getYear()
  ) {
    return 1;
  }
  if (startOfCourse > currDate) {
    return 0;
  }

  const diffDays = Math.round(Math.abs((currDate - startOfCourse) / oneDay));
  let numOfWeeks = Math.floor(diffDays / 7);
  const remainder = diffDays - numOfWeeks * 7;

  if (remainder > 0) {
    numOfWeeks++;
  }

  return numOfWeeks;
};
export const avgGradeToLetters = averageGrade => {
  if (averageGrade >= 3.75) {
    return 'A';
  } else if (averageGrade >= 2.5) {
    return 'B';
  }
  return 'C';
};

export const groupLessonsAndQuizzesByWeek = course => {
  const lessons = course?.lessons;
  const quizzes = course?.quizzes;
  const totalWeek = course?.totalWeeks;
  const result = [];

  const groupedItems = arr =>
    arr?.reduce((acc, item) => {
      if (!acc[item.week]) {
        acc[item.week] = [];
      }
      acc[item.week].push(item);
      return acc;
    }, {});
  const normalizedLessons = groupedItems(lessons);
  const normalizedQuizzes = groupedItems(quizzes);

  for (let i = 1; i <= totalWeek; i++) {
    result.push({
      week: i,
      lessons: normalizedLessons[i] || [],
      quizzes: normalizedQuizzes[i] || [],
    });
  }

  return result;
};

export const calculatePercentageCompleted = (numQuestions, numAnswered) => {
  const percentageCompleted = (numAnswered / numQuestions) * 100;
  return percentageCompleted.toFixed(2);
};

export const letterForAnswers = idx => {
  const letters = ['A.', 'B.', 'C.', 'D.', 'E.'];
  return letters[idx];
};

export const totalQuizPoints = questions => {
  return questions.reduce((totalPoints, question) => {
    const correctAnswers = question.answers.filter(answer => answer.correct);
    return totalPoints + correctAnswers.length * 5;
  }, 0);
};
export const hasAtLeastTwo = (key, array, objKey) => {
  const matches = array.filter(obj => obj[objKey] === key);
  return matches.length < 2;
};

export const formatDuration = seconds => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const getCourseWeeksLabelValue = course => {
  if (course?.courseAttendees?.[0]?.pausedDate) {
    const calculatedWeek = moment(course?.courseAttendees?.[0]?.pausedDate).diff(
      course?.courseAttendees?.[0]?.from,
      'seconds'
    )
    const weekInSeconds = 604800
    const roundNumber = Math.ceil(calculatedWeek / weekInSeconds )
    return `${roundNumber}/${course?.totalWeeks}`;
  }
  if (course?.totalWeeks < course?.currentWeek)
    return `${course?.totalWeeks}/${course?.totalWeeks}`;
  return `${course?.currentWeek}/${course?.totalWeeks}`;
};
