import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCurrentChild, setCurrentChild } from '../ParentProfile/modules/actions';
import { getAvailableCoursesForChild, enrollChild } from './modules/actions';

const mapStateToProps = state => ({
  child: state.ParentReducer.currentChild,
  courses: state.EnrollChildReducer.courses,
  id: state.EnrollChildReducer.chosenChild,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCurrentChild,
      setCurrentChild,
      getAvailableCoursesForChild,
      enrollChild,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
