import { ApiService, AuthorizedApiService, ParentAuthorizedService } from './apiService';

export const authService = async (path, postData) =>
  ApiService.post(`auth/${path}`, postData)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const loginToProfileService = async (path, postData) =>
  AuthorizedApiService.post(`auth/${path}`, postData)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getProfiles = async (path, data) =>
  AuthorizedApiService.get(`auth/${path}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const changePasswordService = async (path, postData) =>
  ParentAuthorizedService.post(`auth/${path}`, postData)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const checkUsernameService = async username =>
  ParentAuthorizedService.get(`auth/check-username?username=${username}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const loginByParentService = async data =>
  AuthorizedApiService.post(`auth/login-by-parent`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
export const verifyUser = async data =>
  AuthorizedApiService.post(`auth/verify-user`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
export const updateUserService = async (data, id, childId) => {
  const addChildIdToUrl = childIdentifier => (childIdentifier ? `?childId=${childIdentifier}` : '');
  return ParentAuthorizedService.patch(`users/update-user/${id}${addChildIdToUrl(childId)}`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
};
