import { userTypes } from '../utils/constants';
import { ChildAuthorizedService, ParentAuthorizedService } from './apiService';

const getService = userType => {
  if (userType === userTypes.child) {
    return ChildAuthorizedService;
  }
  return ParentAuthorizedService;
};

export const postAnswersQuizService = async answers =>
  ChildAuthorizedService.post(`answer-attempts`, answers)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getQuizById = async (userType, id) =>
  getService(userType)
    .get(`quizzes/${id}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getQuizOverview = async data =>
  ChildAuthorizedService.get(`finished-quiz-overview?userId=${data.userId}&quizId=${data.quizId}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
