import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import classNames from 'classnames';
import Artwork from '../../../images/footer/Artwork.png';
import Confetti from 'react-confetti';
import '../../Login/login.scss';
import './successfullyEnrolled.scss';

const SuccessfullyEnrolled = ({ layoutMode }) => {
  const history = useHistory();
  const { state } = useLocation();
  const pushToParentProfile = () => {
    history.push('/parent-profile');
  };
  return (
    <div
      className={classNames({
        background: true,
        'background-black': layoutMode === 'dark',
      })}
    >
      <div className="login-wrapper">
        <Confetti numberOfPieces={800} recycle={false} />

        <div className="successfully-enrolled-wrapper">
          <img src={Artwork} alt="successfully-enrolled" />
          <p className="successfully-enrolled-child-name">{state?.childName} is successfully</p>
          <p className="successfully-enrolled-child-name">enrolled in</p>
          <p className="successfully-enrolled-course-title">{state?.courseTitle}</p>
          <Button onClick={pushToParentProfile} className="successfully-enrolled-button">
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessfullyEnrolled;
