import React, {useEffect} from 'react';
import '../../childDashboard.scss';
import PointsScreen from './PointsScreen';
import OverviewScreen from './OverviewScreen';
import JwtDecode from 'jwt-decode';

const QuizOverviewScreen = ({
  getCourseDetails,
  actions,
  coins,
  closeWatchNowModal,
  notFinalScreen,
  quizId,
  quizOverviewData,
  overview,
  child
}) => {
  const token = localStorage.getItem('child_profile_token');
  const decoded = JwtDecode(token);

  const handleFinish = () => {
    closeWatchNowModal();
    notFinalScreen();
    actions.setActiveLesson(null);
    actions.setActiveQuiz(null);
    getCourseDetails();
    actions.setOverview(false);
  };

  const handleOverview = () => {
    actions.setOverview(true);
  };

  useEffect(() => {
    if (child.superChild) {
      handleFinish();
    }
  }, []);

  const content = overview ? (
    <OverviewScreen
      quizOverviewData={quizOverviewData}
      handleFinish={handleFinish}
      quizId={quizId}
      userId={decoded.user.id}
      getOverviewData={actions.getQuizOverviewData}
    />
  ) : (
    <PointsScreen handleOverview={handleOverview} coins={coins} />
  );

  return content;
};

export default QuizOverviewScreen;
