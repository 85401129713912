import React, { useEffect, useMemo, useCallback, useState } from 'react';
import classNames from 'classnames';
import WeekItem from '../WeekItem';
import QuizIcon from '../../../../../images/courses/test.svg';
import StopWatch from '../../../../../images/courses/stopwatch.png';
import DoneIcon from '../../../../../images/courses/DoneIcon.svg';
import TodoIcon from '../../../../../images/courses/TodoIcon.svg';
import Padlock from '../../../../../images/lesson/padlock.svg';
import ChildLessonWatchNow from '../../../../Child/ChildDashboard/ChildLessonWatchNow';
import ChildCourseQuizzes from '../../../../Child/ChildDashboard/ChildCourseQuizzes';
import CourseHeader from '../CourseHeader';
import LessonItem from '../LessonItem';
import ReactivateCourse from '../ReactivateCourse';
import NoContent from '../../../../ChildProfile/components/NoContent';
import '../../../style.scss';

const WeekOverview = ({
  userRole,
  weekOverview,
  actions,
  childProfile,
  history,
  location,
  activeQuiz,
  quizModal,
  activeLesson,
  watchNowModal,
  layoutMode,
}) => {
  const courseId = location.pathname.split('/')[3];
  const week = location.pathname.split('/')[2];
  const childId = location.pathname.split(/[?/]+/)[4];
  const searchParams = new URLSearchParams(document.location.search);

  const paused = JSON.parse(searchParams.get('paused'));

  const [uncompletedCondition, setUncompletedCondition] = useState(false);
  const [weeksCondition, setWeeksCondition] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);
  const [upcomingStatus, setUpcomingStatus] = useState(false);
  const [lockedStatus, setLockedStatus] = useState(false);

  useEffect(() => {
    if (weekOverview?.status === 'Pending') {
      setUncompletedCondition(true);
    } else {
      setUncompletedCondition(false);
    }
    if ((parseInt(weekOverview?.currentWeek) >= parseInt(week)) || childProfile?.superChild) {
      setWeeksCondition(true);
    } else {
      setWeeksCondition(false);
    }
  }, [weekOverview, week, childProfile]);

  useEffect(() => {
    actions.getWeekOverview(courseId, week, childId);
    actions.getChildProfileAction(childId, userRole);
  }, [actions, courseId, week, childId, userRole]);

  useEffect(() => {
    setUpcomingStatus(parseInt(weekOverview?.currentWeek) < parseInt(week))
  }, [weekOverview]);

  const onWeekClick = () => () => {
    if (paused && userRole === 'CHILD') return;
    actions.getWeeksList(courseId, childId);
    history.push({
      pathname: `/${userRole.toLowerCase()}-week-list/${courseId}/${childId}?paused=${paused}`,
      state: { courseTitle: weekOverview?.title },
    });
  };
  const getCourseDetails = () => {
    actions.getWeekOverview(courseId, week, childId);
    actions.getChildProfileAction(childId, userRole);
  };

  const openQuizModal = useCallback(
    quiz => () => {
      if (weeksCondition) {
        actions.startQuiz(quiz.id);
      }
    },
    [actions, weeksCondition]
  );
  const openWatchNowModal = useCallback(
    lesson => () => {
      if (weeksCondition) {
        actions.startLesson(lesson.id);
      }
    },
    [actions, weeksCondition]
  );

  const parentOptionsQuiz = useCallback(
    item => ({
      PARENT: {
        className:
          classNames({
            'week-overview-quiz-item': true,
            "lesson-locked": item.lock,
          })
      },
      CHILD: {
        onClick: !item.lock && openQuizModal(item),
        className: classNames({
          'week-overview-quiz-item cursor-pointer': true,
          "lesson-locked": item.lock,
        }),
      },
    }),
    [openQuizModal]
  );

  const expandItem = id => () => {
    if (id === expandedItem) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };

  const lessonsMapping = useMemo(
    () =>
      weekOverview?.lessons?.map(item => {
        return (
          <LessonItem
            key={item.id}
            startLesson={openWatchNowModal}
            expandItem={expandItem}
            expand={expandedItem === item.id}
            item={item}
            accountType={userRole}
            upcomingStatus={upcomingStatus}
          />
        );
      }),
    [weekOverview?.lessons, userRole, expandItem, openWatchNowModal, expandedItem, upcomingStatus]
  );

  useMemo(() => {
    if (weekOverview?.lessons?.some(item => item.lock)) {
      setLockedStatus(true);
    } else {
      setLockedStatus(false);
    }
  }, [weekOverview?.lessons]);

  const quizPoints = item => {
    if (item?.userQuizzes?.length > 0) {
      return `You scored ${item.collectedPoints}/${item.totalQuizPoints} points`;
    } else {
      return `${item.questionsCount} ${item.questionsCount > 1 ? 'Questions' : 'Question'} - ${item.totalQuizPoints
        } pts`;
    }
  };

  const quizzesMapping = useMemo(
    () => {
      const quizStatus = (item) => {
        if (upcomingStatus) {
          return { label: 'Upcoming', icon: StopWatch }
        }
        if (item?.userQuizzes?.length > 0) {
          return { label: 'Done', icon: DoneIcon }
        }
        return { label: 'To-Do', icon: TodoIcon }
      }
      return weekOverview?.quizzes?.map(item => {
        const wrapperProps = parentOptionsQuiz(item)[userRole];
        return (
          <div className='lesson-item-wrapper'>
            {
              item.lock && <div class="lesson-item-overlay">
                <img src={Padlock} alt='padlock' />
              </div>
            }
            <div key={item?.id} {...wrapperProps}>
              <div className="content-part">
                <div className="icon-container">
                  <img alt="quiz-icon" src={QuizIcon} />
                  <span className="title">{item?.title}</span>
                </div>
                <span className="description">{quizPoints(item)}</span>
              </div>
              <div className="content-part-status">
                <div className="icon-container">
                  <img alt="status-icon" className='status-icon' src={quizStatus(item).icon} />
                  <span className="status">{quizStatus(item).label}</span>
                </div>
              </div>
            </div>
          </div>
        );
      })
    },
    [weekOverview?.quizzes, parentOptionsQuiz, userRole, upcomingStatus]
  );
  return (
    <div
      className={classNames({
        background: true,
        'male-background': childProfile?.gender === 'M',
        'female-background': childProfile?.gender === 'F',
      })}
    >
      <CourseHeader title={weekOverview?.title} />
      <div className="week-overview-wrapper">
        {weekOverview?.quizzes.length || weekOverview?.lessons.length ? (
          <WeekItem
            onWeekClick={onWeekClick}
            currentWeek={weekOverview?.currentWeek}
            item={{ ...weekOverview, week }}
          />
        ) : (
          <NoContent
            title="No content yet"
            subtitle="Click here to navigate to course overview"
            action={onWeekClick()}
          />
        )}
        {lessonsMapping}
        {quizzesMapping}

        <ReactivateCourse
          paused={paused}
          userRole={userRole}
          childGender={childProfile?.gender}
          user={childProfile}
        />
      </div>
      {userRole === 'CHILD' && (
        <>
          <ChildLessonWatchNow
            activeLesson={activeLesson}
            watchNowModal={watchNowModal}
            closeWatchNowModal={actions.setWatchNowModal}
            layoutMode={layoutMode}
            getCourseDetails={getCourseDetails}
          />
          <ChildCourseQuizzes
            closeQuizModal={actions.setQuizModal}
            activeQuiz={activeQuiz}
            quizModal={quizModal}
            courseId={courseId}
            isParent={false}
            getCourseDetails={getCourseDetails}
          />
        </>
      )}
    </div>
  );
};

export default WeekOverview;
