/* eslint-disable import/no-anonymous-default-export */
import { t } from './actions';
const defaultState = {
  children: null,
  currentChild: null,
  childAndActivities: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_CHILDREN:
      return {
        ...state,
        children: action.children,
      };
    case t.SET_CURRENT_CHILD:
      return {
        ...state,
        currentChild: action.payload.child,
      };
    case t.SET_PARENT:
      return {
        ...state,
        parent: action.payload.parent,
      };
    case t.SET_CHILD_AND_ACTIVITIES:
      return {
        ...state,
        childAndActivities: action.payload.child,
      };
    default:
      return state;
  }
};
