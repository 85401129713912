import React, { useState, useMemo } from 'react';
import { Progress } from 'antd';
import classNames from 'classnames';
import { calculatePercentageCompleted, letterForAnswers } from '../../../../../utils/helpers';
import ConfirmOrNextButton from '../../ChildCourseQuizzes/components/ConfirmOrNextButton';
const QuestionsScreen = ({
  questionNum,
  activeLesson,
  videoNum,
  actions,
  isAnswerCorrect,
  activeAnswer,
  answerValue,
  answerAttempts,
}) => {
  const quiz = activeLesson?.videos[videoNum - 1]?.quizzes[0];

  const [confirm, setConfirm] = useState(false);
  const [nextOrFinishState, setNextOrFinishState] = useState('Next');

  const handleConfirmAnswer = () => {
    setConfirm(true);

    questionNum + 1 === quiz?.questions?.length
      ? setNextOrFinishState('Finish')
      : setNextOrFinishState('Next');

    isAnswerCorrect(answerValue);
  };

  const handleNext = () => {
    actions.setActiveAnswer(null);
    actions.incrementQuestionNum();
    actions.setAnswerValue(null);
    setConfirm(false);
  };

  const answersMapping = useMemo(() => {
    const handleActiveAnswer = (idx, item) => {
      if (activeAnswer === idx) {
        actions.setActiveAnswer(null);
        actions.setAnswerValue(null);
      } else {
        actions.setActiveAnswer(idx);
        actions.setAnswerValue(item);
      }
    };

    const onAnswerClick = (idx, item) => () => {
      if (!confirm) {
        handleActiveAnswer(idx, item);
      }
    };

    return quiz?.questions[questionNum]?.answers.map((item, idx) => {
      return (
        <div
          key={item.id}
          className={classNames({
            'answer-item-active': activeAnswer === idx,
            'answer-item': true,
            'answer-item-correct-answers': confirm && item.correct,
            'answer-item-incorrect-answers': confirm && !item.correct,
          })}
          onClick={onAnswerClick(idx, item)}
        >
          <span className="answer-letter">{letterForAnswers(idx)}</span>
          <span>{item.value}</span>
        </div>
      );
    });
  }, [activeAnswer, confirm, questionNum, quiz?.questions, actions]);

  const percentageCompleted = calculatePercentageCompleted(quiz?.questions.length, questionNum + 1);

  return (
    <div className="quiz-wrapper">
      {/* Question and answer screen  */}
      <div className="question-info">
        <span className="quiz-title">{quiz?.title}</span>
        <span className="question-number">
          Question {questionNum + 1} Of
          {' ' + quiz?.questions.length}
        </span>
        <Progress percent={percentageCompleted} showInfo={false} />
        {<span className="question-title">{quiz?.questions[questionNum]?.title}</span>}
      </div>
      <div className="answer">
        {/* answers */}
        {answersMapping}
        <div className="answer-buttons">
          <ConfirmOrNextButton
            confirm={confirm}
            nextOrFinishState={nextOrFinishState}
            handleNext={handleNext}
            disabledCondition={!answerValue}
            handleConfirmAnswer={handleConfirmAnswer}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionsScreen;
