import React from 'react';

const BorderColor = ({ color, iconClass }) => {
  return (
    <svg
      className={iconClass}
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 0.742676V11.7427H0C6.07513 11.7427 11 6.81781 11 0.742676Z"
        fill={color}
      />
    </svg>
  );
};

export default BorderColor;
