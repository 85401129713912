import { ParentAuthorizedService } from './apiService';

const BASE_PATH = '/frontend-logs';

export const createLog = async data => {
  try {
    const response = await ParentAuthorizedService.post(`${BASE_PATH}`, data);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};
