import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCourseById, setCourse } from '../../Child/ChildDashboard/modules/actions';
import { getCoursesOverviewForChildById } from './modules/actions';
import { enrollChild } from '../../Parent/EnrollChild/modules/actions';
import { getCurrentChild, setCurrentChild } from '../ParentProfile/modules/actions';
import { getUserPayment } from '../../../actions/userPayment';

const mapStateToProps = state => ({
  child: state.ParentReducer.currentChild,
  course: state.ChildLessonReducer.course,
  userPaymentStatus: state.userPaymentReducer.userPaymentStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCourseById,
      getCoursesOverviewForChildById,
      setCourse,
      enrollChild,
      getCurrentChild,
      setCurrentChild,
      getUserPayment,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
