import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LineOutlined } from '@ant-design/icons';

const Breadcrumb = () => {
  const { pathname, state } = useLocation();

  //Key is pathname and value is array of items that will be displayed. Last item will be different. Each item needs to have title and redirectTo. If we want to redirect user back to previous page, we set redirectTo to "goBack"
  const breadCrumbOptions = useMemo(
    () => ({
      '/parent-profile/enroll-child': [
        { title: 'Children', redirectTo: '/parent-profile' },
        { title: `Enrol ${state?.childName ? state.childName : 'child'}` },
      ],
      '/parent-profile/course-overview': [
        { title: 'Courses', redirectTo: '/parent-profile/enroll-child' },
        { title: state?.courseName },
      ],
      '/parent-profile/lesson-overview': [
        { title: 'Courses', redirectTo: '/parent-profile/enroll-child' },
        { title: state?.courseName, redirectTo: 'goBack' },
        { title: state?.lessonName },
      ],
      '/parent-profile/settings': [{ title: 'Settings' }],
      '/parent-profile/child-profile': [
        { title: 'Children', redirectTo: 'goBack' },
        { title: state?.username },
      ],
      '/parent-profile/faq': [{ title: 'FAQ' }],
      '/plans': [{ title: 'Pricing' }],
      '/payment-status': [{ title: 'Kutuby plus' }],
      '/courses': [{ title: 'My Courses' }],
      '/child-profile': [
        { title: 'My Courses', redirectTo: 'goBack' },
        { title: state?.course?.title },
      ],
      '/parent-profile/edit-child': [
        { title: 'Children', redirectTo: '/parent-profile' },
        { title: state?.username, redirectTo: 'goBack' },
        { title: 'Edit Profile' },
      ],
      'parent-week-overview': [
        { title: 'My Children', redirectTo: '/parent-profile' },
        { title: state?.courseTitle },
      ],
      'parent-week-list': [
        { title: 'My Children', redirectTo: '/parent-profile' },
        { title: state?.courseTitle },
      ],
      'child-week-overview': [
        { title: 'My Courses', redirectTo: '/courses' },
        { title: state?.courseTitle },
      ],
      'child-week-list': [
        { title: 'My Courses', redirectTo: '/courses' },
        { title: state?.courseTitle },
      ],
      '/parent-profile': [{ title: 'My children' }],
    }),
    [state]
  );
  const history = useHistory();
  const breadCrumbKeys = Object.keys(breadCrumbOptions);
  const findBreadCrumbOption = breadCrumbKeys.find(item => pathname.includes(item));

  const breadcrumbMapping = useMemo(
    () =>
      breadCrumbOptions[findBreadCrumbOption]?.map((item, i, row) => {
        const redirect = () => {
          if (item.redirectTo === 'goBack') {
            history.goBack();
          } else if (item.redirectTo) {
            history.push(item.redirectTo);
          }
        };

        if (i + 1 === row.length) {
          return (
            <div onClick={redirect} className="main">
              {item?.title && item?.title}
            </div>
          );
        }
        return (
          <div onClick={redirect} className="path">
            {item?.title && `${item?.title}`}
            <LineOutlined className="slash-icon" />
          </div>
        );
      }),
    [breadCrumbOptions, history, findBreadCrumbOption]
  );

  return <div className="breadcrumb-wrapper">{breadcrumbMapping}</div>;
};

export default Breadcrumb;
