import React from 'react';
import NoCourseIcon from '../../../images/courses/no-courses-icon.svg';
import '../childProfile.scss';

const NoContent = ({ title, subtitle, action }) => {
  return (
    <div className="child-profile-no-content">
      <img src={NoCourseIcon} alt="no-course-img" />
      <span className="title">{title}</span>
      <span role="button" onClick={action} className="subtitle">
        {subtitle}
      </span>
    </div>
  );
};

export default NoContent;
