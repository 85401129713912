import React, { useEffect, useState } from 'react';
import ChildLessonWatchNow from './ChildLessonWatchNow';
import ChildProfileHeader from '../../ChildProfile/components/ChildProfileHeader';
import { userTypes } from '../../../utils/constants';
import { groupLessonsAndQuizzesByWeek } from '../../../utils/helpers';

import './childDashboard.scss';
import WeekBlockItem from './components/WeekBlockItem';
import ChildCourseQuizzes from './ChildCourseQuizzes';

const userType = userTypes.child;

const ChildDashboard = props => {
  const {
    course,
    child,
    watchNowModal,
    quizModal,
    actions,
    lessonModal,
    location,
    layoutMode,
    activeLesson,
    activeQuiz,
  } = props;

  const getCourseDetails = () => {
    if (!!location?.state?.course?.id) {
      actions.getCourseById(userType, location?.state?.course?.id);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!child) {
      actions.getChildProfile();
    }
    getCourseDetails();
  }, [child?.coursesInProgress]);

  useEffect(() => {
    if (child) {
      actions.getUserPayment(child.parent?.id);
    }
  }, [child]);

  useEffect(() => {
    actions.setActiveLesson(null);
    actions.setActiveQuiz(null);
  }, []);

  const openLessonModal = lesson => {
    actions.setActiveLesson(lesson);
    actions.setLessonModal(true);
  };
  const openQuizModal = quiz => () => {
    actions.setActiveQuiz(quiz);
    actions.setQuizModal(true);
  };

  const closeLessonModal = () => {
    actions.setLessonModal(false);
  };

  const openWatchNowModal = lesson => () => {
    actions.setActiveLesson(lesson);
    actions.setWatchNowModal();
  };

  if (lessonModal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  // Close modal if user clicks outside of it
  if (lessonModal) {
    window.addEventListener('click', e => {
      if (
        document.getElementById('modalContainer')?.contains(e.target) &&
        !document.getElementById('lessonModal')?.contains(e.target)
      ) {
        closeLessonModal();
      }
    });
  }

  const properlyGrouperLessonsAndQuizzesByWeek = groupLessonsAndQuizzesByWeek(course);
  const contentMapping = properlyGrouperLessonsAndQuizzesByWeek?.map(
    weekItem => (
      <WeekBlockItem
        key={weekItem.id}
        weekItem={weekItem}
        course={course}
        openWatchNowModal={openWatchNowModal}
        openQuizModal={openQuizModal}
        actions={actions}
      />
    ),
    []
  );
  return (
    <div className="background">
      <ChildProfileHeader userData={child} userRole={'CHILD'} course={course} />
      <div className="course-dashboard-data-wrapper">{contentMapping}</div>

      <ChildLessonWatchNow
        activeLesson={activeLesson}
        watchNowModal={watchNowModal}
        closeWatchNowModal={actions.setWatchNowModal}
        layoutMode={layoutMode}
        getCourseDetails={getCourseDetails}
      />
      <ChildCourseQuizzes
        closeQuizModal={actions.setQuizModal}
        activeQuiz={activeQuiz}
        quizModal={quizModal}
        courseId={course?.id}
        isParent={false}
        getCourseDetails={getCourseDetails}
      />
    </div>
  );
};

export default ChildDashboard;
