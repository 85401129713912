import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getCoursesList,
  getWeeksList,
  getWeekOverview,
  getChildProfileAction,
} from './modules/actions';
import {
  getCourseById,
  setWatchNowModal,
  setQuizModal,
  setActiveLesson,
  setActiveQuiz,
} from '../Child/ChildDashboard/modules/actions';
import { switchAccountLogout } from '../../actions/auth';
import { resetState } from '../Child/ChildDashboard/ChildCourseQuizzes/modules/actions';

const mapStateToProps = state => ({
  coursesList: state.CourseReducer.coursesList,
  weeksList: state.CourseReducer.weeksList,
  weekOverview: state.CourseReducer.weekOverview,
  childProfile: state.CourseReducer.childProfile,
  watchNowModal: state.ChildLessonReducer.watchNowModal,
  quizModal: state.ChildLessonReducer.quizModal,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCoursesList,
      getWeeksList,
      getWeekOverview,
      getChildProfileAction,
      getCourseById,
      resetState,
      setActiveLesson,
      setActiveQuiz,
      setWatchNowModal,
      setQuizModal,
      switchAccountLogout,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
