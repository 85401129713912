import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { routeController } from '../utils/routesController';

export const LoginDashboardRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    routeController();
  }, []);
  return (
    <Route
      {...rest}
      component={props => (
        <div>
          <Component {...props} />
        </div>
      )}
    />
  );
};

export const AuthRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    routeController();
  }, []);
  return (
    <Route
      {...rest}
      render={props => (
        <div>
          <Component {...props} />
        </div>
      )}
    />
  );
};

export const ParentRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    routeController(rest?.type);
  }, []);
  return (
    <Route
      {...rest}
      render={props => (
        <div>
          <Component {...props} userRole={'PARENT'} />
        </div>
      )}
    />
  );
};

export const ChildRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    routeController(rest?.type);
  }, []);
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <div>
            <Component userRole={'CHILD'} {...props} />
          </div>
        );
      }}
    />
  );
};
