import { all, call, takeLeading, put } from 'redux-saga/effects';

import { t } from './actions';
import { setCourse } from '../../../Child/ChildDashboard/modules/actions';
import { getCoursesOverviewForChildByIdData } from '../../../../services/coursesService';
import { setNewErrorNotification } from '../../../../actions/errorNotification';
import { generalErrorMessage } from '../../../../utils/constants';

function* getCoursesOverviewForChildById(action) {
  const { childId, courseId } = action.payload;
  const { data, error } = yield call(getCoursesOverviewForChildByIdData, childId, courseId);
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setCourse(data));
  }
}

export default function* courseOverviewSaga() {
  yield all([takeLeading(t.GET_COURSE_OVERVIEW_BY_ID, getCoursesOverviewForChildById)]);
}
