import React, { useEffect, useState, Fragment } from 'react';
import { notification } from 'antd';
import './styles.scss';
const ErrorNotification = ({ errorNotifications, actions }) => {
  const [showAll, setShowAll] = useState(false);

  const destroyAllNotifications = () => {
    notification.destroy();
    actions.removeAllErrorNotifications();
    setShowAll(false);
  };
  const destroySingleNotifications = item => () => {
    actions.removeSingleErrorNotification(item);
  };

  const showAllNotifications = () => {
    setShowAll(true);
    notification.destroy();
    notification['info']({
      duration: 0,
      message: `Delete all notifications`,
      onClick: destroyAllNotifications,
      className: 'destroy-all-notification clickable-notification',
    });
    errorNotifications.forEach(item => {
      notification['error']({
        duration: 0,
        message: item.title,
        description: item.description,
        onClose: destroySingleNotifications(item.id),
        className: 'closable-notification closable-notification',
      });
    });
  };

  useEffect(() => {
    if (errorNotifications?.length === 1 && !showAll) {
      notification['error']({
        duration: 0,
        message: errorNotifications[0].title,
        description: errorNotifications[0].description,
        onClose: destroySingleNotifications(errorNotifications[0].id),
        className: 'closable-notification',
      });
    }
    if (errorNotifications?.length > 1 && !showAll) {
      notification.destroy();
      notification['warning']({
        duration: 0,
        message: `You have ${errorNotifications.length} notifications`,
        description: 'Click here to see all of them',
        onClick: showAllNotifications,
        className: 'closable-notification clickable-notification',
        onClose: destroyAllNotifications,
      });
      notification['error']({
        duration: 3,
        message: errorNotifications[errorNotifications.length - 1].title,
        description: errorNotifications[errorNotifications.length - 1].description,
        className: 'destroy-all-notification',
      });
    }
    if (!errorNotifications?.length) {
      notification.destroy();
      setShowAll(false);
    }
  }, [errorNotifications, showAllNotifications]);

  return <Fragment />;
};

export default ErrorNotification;
