import React, { useMemo, useEffect } from 'react';
import classNames from 'classnames';
import WeekItem from '../../items/WeekItem';
import '../../../style.scss';
import CourseHeader from '../../items/CourseHeader';
import ReactivateCourse from '../../items/ReactivateCourse';
import NoContent from '../../../../ChildProfile/components/NoContent';

const WeekList = ({
  userRole,
  weeksList,
  actions,
  childProfile,
  history,
  location,
  watchNowModal,
  quizModal,
}) => {
  const courseId = location.pathname.split('/')[2];
  const childId = location.pathname.split(/[?/]+/)[3];
  const searchParams = new URLSearchParams(document.location.search);

  const paused = JSON.parse(searchParams.get('paused'));

  useEffect(() => {
    actions.getWeeksList(courseId, childId);
    actions.getChildProfileAction(childId, userRole);
  }, [actions, courseId, childId, userRole]);

  useEffect(() => {
    actions.setActiveLesson(null);
    actions.setActiveQuiz(null);
    actions.resetState();
    if (watchNowModal) {
      actions.setWatchNowModal();
    }
    if (quizModal) {
      actions.setQuizModal();
    }
  }, []);

  const navigateToCourseOverview = () => {
    history.push(userRole === 'PARENT' ? '/parent-profile' : '/courses');
  };

  const weeksMapping = useMemo(() => {
    if (weeksList?.data.some(item => item.lessons.length || item.quizzes.length)) {
      return weeksList?.data.flatMap(item => {
        const onWeekClick = week => () => {
          history.push({
            pathname: `/${userRole.toLowerCase()}-week-overview/${week}/${courseId}/${childId}?paused=${paused}`,
            state: { courseTitle: weeksList?.title },
          });
        };

        if (item.lessons.length || item.quizzes.length) {
          return (
            <WeekItem onWeekClick={onWeekClick} item={item} currentWeek={weeksList.currentWeek} />
          );
        }
        return [];
      });
    } else {
      return (
        <NoContent
          title="No content yet"
          subtitle="Click here to navigate to child overview"
          action={navigateToCourseOverview}
        />
      );
    }
  }, [weeksList, childId, courseId, history, userRole, paused]);

  return (
    <div
      className={classNames({
        background: true,
        'male-background': childProfile?.gender === 'M',
        'female-background': childProfile?.gender === 'F',
      })}
    >
      <CourseHeader title={weeksList?.title} />
      <div className="week-overview-wrapper">{weeksMapping}</div>
      <ReactivateCourse
        paused={paused}
        userRole={userRole}
        childGender={childProfile?.gender}
        user={childProfile}
      />
    </div>
  );
};

export default WeekList;
