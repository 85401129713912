import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, Button } from 'antd';
import Logo from '../../../../images/footer/Logo.png';
import Plus from '../../../../images/footer/Plus.png';
import PricingModelIconThree from '../../../../images/payment/pricing-model3.svg';
import parentAvatar from '../../../../images/layout/parentAvatar.svg';

const PausedCourseModal = ({
  pausedModalVisibility,
  setPausedModalVisibility,
  userRole,
  childGender,
  user,
  switchAccountLogout,
}) => {
  const history = useHistory();
  const childClass =
    childGender === 'M'
      ? 'paused-course-child paused-course-child-m'
      : 'paused-course-child paused-course-child-f';

  const handleCancel = () => {
    setPausedModalVisibility(false);
  };
  const redirectToPayment = () => {
    history.push('/plans');
  };
  return (
    <div>
      <Modal
        visible={pausedModalVisibility}
        footer={null}
        header={null}
        closable={false}
        className={userRole === 'CHILD' ? childClass : 'paused-course-parent'}
      >
        <div className="pro-logo">
          <img src={Logo} alt="plus-logo" />
          <img src={Plus} alt="plus-logo" />
        </div>
        {userRole === 'CHILD' ? (
          <>
            <img className="avatar" src={parentAvatar} />
            <span className="description">
              To continue learning please reactivate Kutuby Plus on your parent's account
            </span>
            <Button onClick={switchAccountLogout} className="paused-course-button">
              Switch Accounts
            </Button>
          </>
        ) : (
          <>
            <span className="description">
              To continue {user?.firstName}'s' learning, reactivate Kutuby Plus
            </span>
            <img className="pricing-model-image" src={PricingModelIconThree} />
            <Button onClick={redirectToPayment} className="paused-course-button">
              Reactivate
            </Button>
          </>
        )}
        <span className="no-thanks" role="button" onClick={handleCancel}>
          No thanks
        </span>
      </Modal>
    </div>
  );
};

export default PausedCourseModal;
