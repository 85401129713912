import React from 'react';
import { Button } from 'antd';

const ConfirmOrNextButton = ({
  confirm,
  nextOrFinishState,
  handleNext,
  disabledCondition,
  handleConfirmAnswer,
}) => {
  return (
    <>
      {confirm ? (
        <Button className="question-button" onClick={handleNext}>
          {nextOrFinishState}
        </Button>
      ) : (
        <Button
          className="question-button"
          disabled={disabledCondition}
          onClick={handleConfirmAnswer}
        >
          Submit
        </Button>
      )}
    </>
  );
};

export default ConfirmOrNextButton;
