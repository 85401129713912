import React from 'react';
import { Button } from 'antd';
import { deviceDetect } from 'react-device-detect';
import Logo from '../../images/footer/vector-logo.jpg';
import { appStoreAppUrl, googlePlayAppUrl } from '../../utils/constants';
import './styles.scss';

const MobileView = () => {
  const device = deviceDetect();

  const redirectFunc = () => {
    if (device.os === 'Android') {
      window.location.replace(googlePlayAppUrl);
    }
    if (device.os === 'iOS') {
      window.location.replace(appStoreAppUrl);
    }
  };
  return (
    <div className="mobile-view-wrapper">
      <img src={Logo} className="logo" alt="kutuby-logo" />
      <span className="description">
        <span className="description-bold">
          To get the full experience of all features in Kutuby Mobile app,
        </span>{' '}
        <br /> download the app by clicking the button below
      </span>
      <Button onClick={redirectFunc}>Download App</Button>
    </div>
  );
};

export default MobileView;
