import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { register } from '../../actions/auth';

const mapStateToProps = state => ({
  layoutMode: state.AuthReducer.layoutMode,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      register,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
