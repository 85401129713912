import React from 'react';
import CompletedWeekIcon from '../../../../images/courses/CompletedWeekIcon.svg';
import CurrentWeekIcon from '../../../../images/courses/CurrentWeekIcon.svg';
import UpcomingWeekIcon from '../../../../images/courses/UpcomingIcon.svg';

const ProgressStatusTitle = ({ lessonItem, course }) => {
  let status, text, icon;
  switch (true) {
    case course.currentWeek > lessonItem.week:
      status = 'completed';
      text = 'Completed';
      icon = CompletedWeekIcon;
      break;
    case course.currentWeek < lessonItem.week:
      status = 'upcoming';
      text = 'Upcoming week';
      icon = UpcomingWeekIcon;
      break;
    default:
      status = 'current';
      text = 'Current week';
      icon = CurrentWeekIcon;
  }
  return (
    <span className={`course-overview-progress-status ${status}`}>
      <img src={icon} />
      {text}
    </span>
  );
};

export default ProgressStatusTitle;
