import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUserPayment } from '../../../../actions/userPayment';
import { getPaymentLogs } from '../../modules/actions';

const mapStateToProps = state => ({
  userPaymentStatus: state.userPaymentReducer.userPaymentStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUserPayment,
      getPaymentLogs,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
