export const FAQContent = [
  {
    key: 1,
    subtitle: 'Reporting Issues',
  },
  {
    key: 2,
    header: 'How do I report a bug?',
    body: `<p>If you have encountered a bug in the Kutuby app, you can report it by:</p>
            <ul>
              <li>
                <p>
                  First, check to see if the bug has already been reported by contacting the
                  customer support team.
                </p>
              </li>
              <li>
                <p>
                  If the bug has not been reported, gather as much information as possible about it,
                  including details such as the device and operating system you are using, the steps
                  you took to encounter the bug, and any error messages or other relevant details.
                </p>
              </li>
              <li>
                <p>
                  Contact the Kutuby customer support team through WhatsApp or email to report the
                  bug.
                </p>
              </li>
              <li>
                <p>Be patient as the team works to fix the issue, as bug fixes can take time.</p>
              </li>
            </ul>`,
  },
  {
    key: 3,
    header: 'How do I report a problem with a Kutuby course?',
    body: ` <p>
              If you have encountered a problem with one of the Kutuby courses, you can report it by
            </p>
            <p>
              <br />
            </p>
            <ul>
              <li>
                <p>
                  Checking to see if the problem has already been reported: You can do this by
                  contacting the customer support team to ask if they are aware of the issue.
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul>
              <li>
                <p>
                  Gather as much information as possible: It is important to provide as much
                  information as possible to help the team understand and resolve the issue. This
                  could include details such as the specific course and lesson you are experiencing
                  the problem in and the nature of the problem.
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul>
              <li>
                <p>
                  Contact the customer support team: You can contact the Kutuby customer support
                  team to report the issue. You can do this through WhatsApp or email, depending on
                  your preference. In addition to reporting the problem, you may also want to
                  consider providing feedback on the course material. This could include suggestions
                  for improvement or ideas
                </p>
              </li>
            </ul>`,
  },
  {
    key: 4,
    subtitle: 'Billing / Payment ',
  },
  {
    key: 5,
    header: 'What are the payments method allowed?',
    body: ` <p>
              If you would like to update your billing/credit card information for your Kutuby
              subscription, you will need to update your info within the subscription service you
              originally subscribed through.
            </p>
            <p>iOS:</p>
            <p>Edit a payment method:</p>
            <ol>
              <li>
                <p>Open the&nbsp;Settings&nbsp;app.</p>
              </li>
              <li>
                <p>Tap your name.</p>
              </li>
              <li>
                <p>
                  Tap&nbsp;Payment &amp; Shipping. You may be asked to sign in with your Apple ID
                </p>
              </li>
              <li>
                <p>
                  To update a payment method, tap the payment method, then edit your
                  information.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  To change the order of your payment methods, tap edit, then use the move order
                  icon.
                </p>
              </li>
            </ol>
            <p>To add a payment method, tap&nbsp;Add Payment Method.</p>
            <p>To remove a payment method, tap&nbsp;edit, then tap the&nbsp;delete&nbsp;button.</p>
            <p>
              Note: You might need to enter the security code that&apos;s printed on your credit or
              debit card. If you see Apple Pay after the last 4 digits of the card number, go to the
              Wallet app to edit the card information.
            </p>
            <p>Android:</p>
            <p>Edit a payment method:</p>
            <ol>
              <li>
                <p>Open the Google Play app.</p>
              </li>
              <li>
                <p>
                  At the top right, tap the profile icon, Tap Payments &amp; subscriptions &gt;
                  Payment methods &gt; More &gt; Payment settings.
                </p>
              </li>
              <li>
                <p>If asked, sign in to Google Pay.</p>
              </li>
              <li>
                <p>Under the payment method you want to remove, tap&nbsp;edit.</p>
              </li>
              <li>
                <p>Make your changes.&nbsp;</p>
              </li>
              <li>
                <p>Tap&nbsp;Update.</p>
              </li>
            </ol>
            <p>Add a Payment method:</p>
            <ol>
              <li>
                <p>Open the Google Play app.</p>
              </li>
              <li>
                <p>
                  At the top right, tap the profile icon, Tap Payments &amp; subscriptions &gt;
                  Payment methods &gt; Add payment method.
                </p>
              </li>
              <li>
                <p>Choose the payment method you want to add.</p>
              </li>
              <li>
                <p>Follow the on-screen instructions.</p>
              </li>
            </ol>
            <p>
              Note:&nbsp;If you&apos;re using Google Pay in India as a payment method, you&apos;ll
              need to update your UPI ID.&nbsp;
            </p>
            <p>Remove a payment method:</p>
            <ol>
              <li>
                <p>Open the Google Play app.</p>
              </li>
              <li>
                <p>
                  At the top right, tap the profile icon, Tap Payments &amp; subscriptions &gt;
                  Payment methods &gt; More &gt; Payment settings.
                </p>
              </li>
              <li>
                <p>If asked, sign in to Google Pay.</p>
              </li>
              <li>
                <p>Under the payment method you want to remove, tap Remove &gt; Remove.</p>
              </li>
            </ol>
            <p>Web / Stripe:</p>
            <ol>
              <li>
                <p>Log in to your Kutuby account on your web browser.</p>
              </li>
              <li>
                <p>Navigate to your settings from the top right menu.</p>
              </li>
              <li>
                <p>Click on Kutuby.</p>
              </li>
              <li>
                <p>Click&nbsp;Update Payment Method&nbsp;and enter the new card information.</p>
              </li>
            </ol>
            <p>
              Click Update when done. All future invoices will now be billed to the new payment
              method.
            </p>`,
  },
  {
    key: 6,
    header: ' How can I check my subscription ?',
    body: `<ul>
              <li>
                <p>Web:</p>
                <ol>
                  <li>
                    <p>Log in via a web browser (like Chrome)</p>
                  </li>
                  <li>
                    <p>
                      Follow this link:
                      <br />
                      (need to add correct link here)
                      <br />
                      It should bring you to this page:
                      <br />
                      (add correct page image)
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      If you don&rsquo;t see this page from following the link, click Profile image
                      &gt; settings &gt; you&apos;ll find Kutuby on the right-hand side of your
                      screen.
                    </p>
                  </li>
                  <li>
                    <p>
                      From here you can check your subscription status, next payment date, cancel
                      your subscription or update your payment information.
                      <br />
                    </p>
                  </li>
                  iOS:
                </ol>
                <p></p>
                <ol>
                  <li>
                    <p>
                      On your iPhone, iPad, or iPod touch, go to your devices system settings &gt;
                      [your name] /Apple ID
                    </p>
                  </li>
                  <li>
                    <p>Tap Subscriptions</p>
                  </li>
                  <li>
                    <p>
                      Find Kutuby Subscription. If status mentions &apos;expires&apos;, the
                      subscription will expire on that date. If status mentions &apos;renews/next
                      billing date&apos;, your subscription will renew on that date.&nbsp;
                      <br />
                    </p>
                  </li>
                  <br />
                </ol>
                <p></p>
                <p>Android:</p>
                <ol>
                  <li>
                    <p>
                      On your Android phone or tablet, open your device&apos;s Settings app &gt;
                      Google &gt; Manage your Google Account.
                    </p>
                  </li>
                  <li>
                    <p>At the top, tap Payments &amp; subscriptions.</p>
                  </li>
                  <li>
                    <p>Tap Manage purchases, Manage reservations, or Manage subscriptions.</p>
                  </li>
                  <li>
                    <p>To see more details, select an item.</p>
                  </li>
                </ol>
              </li>
            </ul> `,
  },
  {
    key: 7,
    header: 'How do I cancel my subscription?',
    body: `<ul>
              <li>
                <p>
                  You can cancel your Kutuby subscription at any time.&nbsp;Please note:&nbsp;You
                  will need to cancel your subscription based on how you originally started the
                  subscription:
                </p>
                <ul>
                  <li>
                    <p>Google Play (Android)</p>
                  </li>
                  <li>
                    <p>Apple (iOS)</p> 
                  </li>
                </ul>
                <br />
                <p>
                  To cancel your subscription, please follow the instructions for each platform:
                  <br />
                </p>
                <p>Google Play Store (Android)</p>
                <h4>Cancelling via Google Play store:</h4>
                <p>
                  The Google Play Store allows Android users to make payments for recurring charges
                  or subscriptions, including Kutuby. The Google Play Store charges your specified
                  payment method by default on the same date each billing period. To cancel your
                  Kutuby subscription, login to Subscriptions and Services. Find the subscription
                  you would like to cancel and select &ldquo;manage&rdquo; and then select
                  &ldquo;cancel subscription.&rdquo;&nbsp;
                </p>
                <p>
                  Please note that subscription cancellations cannot be undone, though you can
                  always re-subscribe in the future.&nbsp;
                </p>
                <p>Cancelling via Android Kutuby app:</p>
                <ol>
                  <li>
                    <p>Tap Kutuby icon</p>
                  </li>
                  <li>
                    <p>Tap Settings (top-right)</p>
                  </li>
                  <li>
                    <p>Tap &apos;Manage Subscription&apos;</p>
                  </li>
                  <li>
                    <p>Select &apos;Cancel Subscription&apos;, proceed to cancel&nbsp;</p>
                  </li>
                  <li>
                    <p>Confirm cancellation via Google Play Store&nbsp;</p>
                  </li>
                  &nbsp;
                </ol>
                <p></p>
                <h3>Apple (iOS)</h3>
                <h4>Cancelling via Apple Devices:&nbsp;</h4>
                <p>
                  Like the Google Play Store, the App Store allows iOS users to make payments for
                  recurring charges or subscriptions, including Kutuby. The App Store charges your
                  specified payment method automatically on the same date each billing period. Apple
                  requires you to cancel at least 24 hours before your subscription/trial period
                  ends. If you cancel on the LAST day of your subscription/trial, your subscription
                  will renew automatically. &nbsp;
                </p>
                <p>
                  To cancel your Kutuby subscription on your iPhone, iPad, or iPod
                  touch,&nbsp;please open the Settings app on your iOS device:
                </p>
                <ol>
                  <li>
                    <p>Go to Settings app&nbsp;</p>
                  </li>
                  <li>
                    <p>Tap your Apple ID at the top of the screen.</p>
                  </li>
                  <li>
                    <p>
                      Tap View Apple ID. You might need to sign in or use Touch ID (or use Face ID
                      if available).
                    </p>
                  </li>
                  <li>
                    <p>
                      Tap &ldquo;Subscriptions&rdquo; and select the subscription you would like to
                      cancel.
                    </p>
                  </li>
                  <li>
                    <p>Select &ldquo;Cancel Subscription&rdquo; and then &ldquo;confirm.&rdquo;</p>
                  </li>
                  Cancelling via iOS Kutuby app:
                </ol>
                <h4></h4>
                <ol>
                  <li>
                    <p>Tap Kutuby icon</p>
                  </li>
                  <li>
                    <p>Tap Settings (top-right)</p>
                  </li>
                  <li>
                    <p>Tap &apos;Manage Plan&apos;</p>
                  </li>
                  <li>
                    <p>Tap &apos;Change Plan&apos;, then &apos;Cancel Subscription&apos;</p>
                  </li>
                  <li>
                    <p>Confirm cancellation via Apple dashboard</p>
                  </li>
                  After your current period ends, Apple will no longer bill you for that
                  subscription. Please note that subscription cancellations cannot be undone, though
                  you can always re-subscribe in the future. More updated information about managing
                  your iOS subscriptions are available on Apple&apos;s website.&nbsp;
                </ol>
                <p></p>
                <p>
                  You will no longer be billed automatically once you cancel your subscription. You
                  can reactivate your subscription anytime.
                </p>
              </li>
            </ul>`,
  },
  {
    key: 8,
    subtitle: 'General',
  },
  {
    key: 9,
    header: 'What is Kutuby?',
    body: ` <p>
              Kutuby is an Islamic educational learning platform for children aged 5-11. At Kutuby,
              we teach, explain, and inspire young Muslims all around the world to live in
              accordance with Islamic values. We utilise the power of storytelling to solidify the
              foundations of Fiqh, Aqeedah, Akhlaq, Sunnah & Hadith, and Islamic history in growing
              minds. Students learn through a curriculum meticulously designed by experts in the
              field, and lessons are delivered through short and engaging animated videos. Kutuby
              conducts regular performance evaluations through brief and simple quizzes, and
              students are graded accordingly. Kutuby also keeps parents updated about their child’s
              performance through regular correspondence.
            </p>`,
  },
  {
    key: 10,
    header: 'How much is Kutuby Plus ?',
    body: `<p>
              Kutuby Plus operates on a “Pay-as-you-can” model — you get to decide how much to pay
              for using Kutuby Plus. We use this model because we want our services to be accessible
              to all children, regardless of their financial situation. If you are able to pay a
              little more, we encourage you to do so, as you will be supporting the education of
              underprivileged children
            </p>`,
  },
  {
    key: 11,
    header: 'How do my children learn with Kutuby?',
    body: `<p>
              Kutuby is an online educational platform where kids learn about Islamic values, and
              Islamic history, and are encouraged to adopt a practical Islamic way of life through
              short, engaging videos, quizzes, and more. Topics Kutuby focuses on are Islamic
              history, Fiqh, Aqeedah, Sunnah & Hadith, and Akhlaq. Parents have the ability to track
              their child's progress and are encouraged to guide them as they navigate through the
              courses.
            </p>`,
  },
  {
    key: 12,
    header: 'How are courses structured within Kutuby?',
    body: ` <p>
              Our courses are divided into weeks, and each week consists of tasks (lessons and
              quizzes) that students are expected to complete. Students can only access the tasks
              for the current week and are not able to preview future tasks. This structure is
              intended to mimic the experience of traditional schools. As students participate in
              the lessons and quizzes, they will earn points which will be used to determine their
              final grade for the course.
            </p>`,
  },
  {
    key: 13,
    header: 'How do I learn with Kutuby?',
    body: ` <p>
              Kutuby is an online educational platform where kids learn about Islamic values, and
              Islamic history, and are encouraged to adopt a practical Islamic way of life through
              short, engaging videos, quizzes, and more. Parents have the ability to track their
              child's progress and are encouraged to guide them as they navigate through the
              courses.
            </p>`,
  },
  {
    key: 14,
    subtitle: 'Using Kutuby',
  },
  {
    key: 15,
    header: 'What are the features of the basic account?',
    body: `<p p >
      With a basic account, you can add up to two child profiles and enroll your children in
              unlimited free courses(basic courses).Please note, however, that our premium courses
              on Kutuby are exclusively for Plus members.
            </p > `,
  },
  {
    key: 16,
    header: 'What are the features of a Plus account?',
    body: `  <ul>
              <li>
                <p>With the Kutuby Plus account, you will be able to:</p>
                <ul>
                  <li>
                    <p>
                      Have exclusive access to all Kutuby Plus courses and all other Kutuby courses
                    </p>
                  </li>
                  <li>
                    <p>Enrol unlimited children</p>
                  </li>
                  <li>
                    <p>
                      Exclusive access to weekly lesson packs, exercises, activities, quizzes, and
                      more
                    </p>
                  </li>
                  <li>
                    <p>Be the first to know about new launches, discounts, giveaways, and more</p>
                  </li>
                </ul>
              </li>
            </ul>`,
  },
  {
    key: 17,
    header: 'How do I upgrade my subscription on Kutuby?',
    body: ` <ul>
                    <li>
                      To upgrade your subscription to Kutuby Plus, follow these steps:
                      <ul>
                        <li>
                          <p>Log in to your Kutuby account and navigate to the settings page.</p>
                        </li>
                        <li>
                          <p>
                            Select the subscription tab and choose the &quot;Upgrade to Plus&quot; option.
                          </p>
                        </li>
                        <li>
                          <p>
                            Choose your payment amount for the upgraded subscription. Kutuby Plus operates
                            on a &quot;Pay-as-you-can&quot; pricing model, which means that you get to
                            decide how much you want to pay for the service. This model was implemented in
                            order to make our services accessible to all children, regardless of their
                            financial situation.
                          </p>
                        </li>
                        <li>
                          <p>Confirm the upgrade by completing any required payment steps.</p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <br />
                      <ul>
                        <li>
                          <p>
                            <br />
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>`,
  },
  {
    key: 18,
    header: 'How do I switch between accounts on Kutuby?',
    body: `<ul>
                    <li>
                      <span>
                        <p>To switch between accounts on Kutuby, follow these steps:</p>
                        <br />
                        <ol>
                          <li>
                            <p>Log in to your Kutuby parent account through the app or website.</p>
                          </li>
                          <li>
                            <p>Click on the chevron-down icon beside the profile avatar.</p>
                          </li>
                          <li>
                            <p>Select the &quot;Switch Account&quot; option.</p>
                          </li>
                          <li>
                            <p>You will be taken to a page where all associated accounts are visible.</p>
                          </li>
                          <li>
                            <p>Choose the account you want to switch to and click on it to enter.</p>
                          </li>
                        </ol>
                        <br />
                        <p>Can I switch between my child&apos;s account and my own account on Kutuby?</p>
                        <br />
                        <p>
                          Yes, you can switch between your child&apos;s account and your own account on
                          Kutuby. As a parent, you have the ability to access your child&apos;s account
                          through the &quot;Switch Account&quot; feature. Simply follow the steps above to
                          switch between your own account and your child&apos;s account.
                        </p>
                        <br />
                        <p>Is there a limit to the number of accounts I can switch between on Kutuby?</p>
                        <br />
                        <p>
                          There is no limit to the number of accounts you can switch between on Kutuby.
                          However, please note that the &quot;Switch Account&quot; feature is only
                          available for parent accounts and their associated children&apos;s accounts. You
                          will not be able to switch between accounts that are not associated with your
                          parent account.
                        </p>
                      </span>
                    </li>
                  </ul>`,
  },
  {
    key: 19,
    header: 'How can I add a child to my account?',
    body: `  <ul>
                    <li>
                      <span>
                        <p>
                          If you&apos;d like to add a child to your account, it&apos;s easy to do. Simply
                          follow these steps:
                        </p>
                        <br />
                        <ol>
                          <li>
                            <p>Open the Kutuby app on your device.</p>
                          </li>
                          <li>
                            <p>Tap on &quot;Children&quot; in the navigation bar</p>
                          </li>
                          <li>
                            <p>Tap on the &quot;+&quot; icon to add a new child.</p>
                          </li>
                          <li>
                            <p>
                              Enter your child&apos;s name, and date of birth, select an avatar, and set a
                              username and password for your child to use to log in to their own account.
                              Then tap &quot;Save.&quot;
                            </p>
                          </li>
                          <li>
                            <p>
                              Your child will now be added to your account, and you&apos;ll be able to
                              enroll them in courses and track their progress. Children can use their own
                              login credentials to access their accounts and complete courses on their
                              own.&nbsp;
                            </p>
                          </li>
                        </ol>
                      </span>
                    </li>
                  </ul>`,
  },
  {
    key: 20,
    header: "How do I edit my child' s profile details on Kutuby?",
    body: ` <ul>
                    <li>
                      <span>
                        <ol>
                          <li>
                            <p>Log in to your Kutuby account through the app or website.</p>
                          </li>
                          <li>
                            <p>Navigate to the children tab of your account.</p>
                          </li>
                          <li>
                            <p>
                              Find the child whose profile you wish to edit and click on their avatar or
                              name.
                            </p>
                          </li>
                          <li>
                            <p>
                              This will open the child&apos;s profile page, where you can see their
                              enrolled courses, completed courses, and points/grades.
                            </p>
                          </li>
                          <li>
                            <p>
                              Look for the settings icon next to the header in the child&apos;s profile.
                            </p>
                          </li>
                          <li>
                            <p>Click on the settings icon to open the edit profile page.</p>
                          </li>
                          <li>
                            <p>
                              From here, you can make any changes you wish to the child&apos;s profile
                              details, such as their name, avatar, or password.
                            </p>
                          </li>
                          <li>
                            <p>Once you have made your changes, click &quot;Save&quot; to apply them.</p>
                          </li>
                          <li>
                            <p>Your child&apos;s profile details will now be updated.</p>
                          </li>
                        </ol>
                        <br />
                        <p>
                          If you have any questions or need additional clarification, our customer support
                          team is always available to help.
                        </p>
                      </span>
                    </li>
                  </ul>`,
  },
  {
    key: 21,
    header: 'How can I enrol my child in a course?',
    body: `                              <ul>
                                      <li>
                                        <span>
                                          <li>
                                            <p>To enroll your children in courses, follow these steps:</p>
                                            <ol>
                                              <li>
                                                <p>Open the Kutuby app on your device.</p>
                                              </li>
                                              <li>
                                                <p>Tap on the children tab within the navigation bar</p>
                                              </li>
                                              <li>
                                                <p>
                                                  Tap on enroll button nested within the child&apos;s card who you wish to
                                                  select
                                                </p>
                                              </li>
                                              <li>
                                                <p>
                                                  Select the course you&apos;d like to enroll your child in from the list of
                                                  available courses. You can preview the lessons and quizzes each week of
                                                  the course before making your selection.
                                                </p>
                                              </li>
                                              <li>
                                                <p>
                                                  Tap on the &quot;Enroll&quot; button to enroll your child in the selected
                                                  course.
                                                </p>
                                              </li>
                                            </ol>
                                            <br />
                                            <p>
                                              Once your child is enrolled in a course, they&apos;ll be able to access the
                                              materials and track their progress. If you have any questions or need
                                              additional clarification, our customer support team is always available to
                                              help.
                                            </p>
                                          </li>
                                        </span>
                                      </li>
                                    </ul>`,
  },
  {
    key: 22,
    header: "How to Access your Child's Profile?",
    body: `<ul>
              <li>
                <span>
                  <li>
                    <p>
                      If you are a parent and want to access your child&apos;s profile, follow these
                      steps:
                    </p>
                    <ol>
                      <li>
                        <p>Log in to your Kutuby account through the app or website.</p>
                      </li>
                      <li>
                        <p>Click on the children tab in the main menu.</p>
                      </li>
                      <li>
                        <p>
                          You will see a list of all the children associated with your account, with
                          their profile avatars displayed.
                        </p>
                      </li>
                      <li>
                        <p>Click on the child whose profile you wish to view.</p>
                      </li>
                      <li>
                        <p>
                          This will open the child&apos;s profile, where you can see the courses the
                          child is enrolled in, the completed courses, and the points/grades they
                          have earned.
                        </p>
                      </li>
                    </ol>
                    <br />
                    <p>Alternatively, if you are a child and want to access your own profile:</p>
                    <br />
                    <p>
                      Log in to your Kutuby account through the app or website. Click on your avatar
                      in the top right corner of the screen. This will open your profile, where you
                      can see the courses you are enrolled in, the completed courses, and the
                      points/grades you have earned.
                    </p>
                  </li>
                </span>
              </li>
            </ul>`,
  },
  {
    key: 23,
    header: '"How can I track my child’s progress?"',
    body: `<ul>
              <li>
                <span>
                  <li>
                    <p>To track your child&apos;s progress, follow these steps:</p>
                    <ol>
                      <li>
                        <p>Open the Kutuby app on your device.</p>
                      </li>
                      <li>
                        <p>Tap on the &quot;Children&quot; tab in the navigation bar</p>
                      </li>
                      <li>
                        <p>Tap on the child&rsquo;s card whose progress you want to track.</p>
                      </li>
                      <li>
                        <p>
                          You&apos;ll see a list of the courses your child is enrolled in or
                          completed. Tap on a course to view your child&apos;s progress for that
                          course.
                        </p>
                      </li>
                      <li>
                        <p>
                          You&apos;ll see a summary of your child&apos;s progress, including their
                          current grade and the percentage of the course they have completed (what
                          week of the course are they currently on?)
                        </p>
                      </li>
                      <li>
                        <p>
                          You can also tap on a lesson or quiz to see more detailed information
                          about your child&apos;s progress for that specific lesson or quiz. This
                          will show you whether they have watched the lesson, missed it, attempted
                          the quiz, and their score if they completed it.
                        </p>
                      </li>
                    </ol>
                    <br />
                    <p>
                      If you have any questions or need further assistance, don&apos;t hesitate to
                      reach out to our customer support team.
                    </p>
                  </li>
                </span>
              </li>
            </ul>`,
  },
  {
    key: 24,
    header: 'How is my child graded in their courses?',
    body: ` <p>
          Your child's grades are based on their attendance (completed video lessons) and their
          performance on quiz assignments. Please note that it is important for your child to
          regularly attend their courses and complete all quizzes in order to earn the best
          possible grade. To find out more about tracking your child's progress, click here.
        </p>`,
  },
  {
    key: 25,
    subtitle: 'Using Child Account ',
  },
  {
    key: 26,
    header: 'How can I login into my Kutuby account as a child?',
    body: `<p>
              To log in to your Kutuby account as a child, you will need to use the username and
              password that your parent has assigned for you. You can do this by logging into the
              mobile app or through a web browser. Once you are logged in, you will be able to
              access your courses, complete lessons, and quizzes, and track your progress.
            </p>`,
  },
  {
    key: 27,
    header: 'How can I attend my courses?',
    body: `<ul>
              <li>
                <span>
                  <li>
                    <p>To attend your courses on Kutuby, follow these steps:</p>
                    <ol>
                      <li>
                        <p>
                          Log into your account on the mobile app or through a web browser using the
                          username and password your parents have assigned for you.
                        </p>
                      </li>
                      <li>
                        <p>
                          On the dashboard, you will see all of your enrolled courses. Click on the
                          course you want to attend.
                        </p>
                      </li>
                      <li>
                        <p>
                          Each course is divided into weeks. You must complete the tasks from the
                          current week in order to receive your points. You can access the tasks for
                          each week by clicking on the week on the course page.
                        </p>
                      </li>
                      <li>
                        <p>
                          To see your course progress, click on your avatar in the top left corner
                          of the screen. This will open up your profile where you can track your
                          progress for each course and see how many points you have earned through
                          the quizzes and lessons each week.
                        </p>
                      </li>
                    </ol>
                  </li>
                </span>
              </li>
            </ul>`,
  },
  {
    key: 28,
    header: 'How do I access my courses on Kutuby?',
    body: `<p>
              When you log into your Kutuby account, you will see a list of your enrolled courses on
              the dashboard. Simply click on a course to start learning.
            </p>`,
  },
  {
    key: 29,
    header: 'How are courses structured on Kutuby?',
    body: `  <p>
              Each course is divided into weeks, and each week has its own set of tasks that you
              need to complete. These tasks may include lessons and quizzes. You are only required
              to complete the tasks for the current week, but you can also view tasks from previous
              weeks if you'd like to review them.
            </p>`,
  },
  {
    key: 30,
    header: 'How do I earn points in a course?',
    body: ` <p>
              You will earn points for completing tasks in a course, such as lessons and quizzes.
              These points will be used to calculate your final grade for the course.
            </p>`,
  },
  {
    key: 31,
    header: 'Can I access all the tasks for a course at once?',
    body: ` <p>
              No, you can only access the tasks for the current week of a course. However, you can
              view tasks from previous weeks if you'd like to review them.
            </p>`,
  },
];
