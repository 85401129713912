/* eslint-disable import/no-anonymous-default-export */
import { t } from './actions';

const defaultState = {
  lessons: [],
  hasMoreLessons: true,
  child: {},
  single_quiz_banner: [],
  single_lesson_banner: [],
  lesson_to_attend: null,
  reactions: null,
  current_chapter: null,
  lesson_quiz: false,
  end_of_lesson: false,
  quizzes: [],
  hasMoreQuizzes: true,
  watchNowModal: false,
  quizModal: false,
  lessonModal: false,
  activeLesson: null,
  activeQuiz: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_LESSONS:
      return {
        ...state,
        lessons: action.payload.lessons,
      };
    case t.SET_COURSE:
      return {
        ...state,
        course: action.payload.course,
      };
    case t.ADD_LESSONS:
      return {
        ...state,
        lessons: [...state.lessons, ...action.payload.lessons],
      };
    case t.SET_CHILD_PROFILE:
      return {
        ...state,
        child: action.child,
      };
    case t.SET_SINGLE_QUIZ:
      return {
        ...state,
        single_quiz_banner: action.quiz,
      };
    case t.SET_LESSON_BY_ID:
      return {
        ...state,
        lesson_to_attend: action.lesson,
      };
    case t.SET_SINGLE_LESSON:
      return {
        ...state,
        single_lesson_banner: action.lesson,
      };
    case t.GET_LESSON_BY_ID:
      return {
        ...state,
        lesson_to_attend: null,
      };
    case t.SET_HAS_MORE_LESSONS:
      return {
        ...state,
        hasMoreLessons: action.payload.hasMore,
      };
    case t.SET_QUIZZES:
      return {
        ...state,
        quizzes: action.payload.quizzes,
      };
    case t.ADD_QUIZZES:
      return {
        ...state,
        quizzes: [...state.quizzes, ...action.payload.quizzes],
      };
    case t.SET_HAS_MORE_QUIZZES:
      return {
        ...state,
        hasMoreQuizzes: action.payload.hasMore,
      };
    case t.SET_WATCH_NOW_MODAL:
      return {
        ...state,
        watchNowModal: !state.watchNowModal,
      };
    case t.SET_QUIZ_MODAL:
      return {
        ...state,
        quizModal: !state.quizModal,
      };
    case t.SET_LESSON_MODAL:
      return {
        ...state,
        lessonModal: action.payload.status,
      };
    case t.SET_ACTIVE_LESSON:
      return {
        ...state,
        activeLesson: action.payload.activeLesson,
      };
    case t.SET_ACTIVE_QUIZ:
      return {
        ...state,
        activeQuiz: action.payload.activeQuiz,
      };
    default:
      return state;
  }
};
