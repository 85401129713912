export const t = {
  GET_AVATARS: 'GET_AVATARS',
  SET_AVATARS: 'SET_AVATARS',
  ADD_CHILD: 'ADD_CHILD',
  GET_CHILD: 'GET_CHILD',
  CHECK_USERNAME: 'CHECK_USERNAME',
  SET_USERNAME_TAKEN: 'SET_USERNAME_TAKEN',
};

export const getAvatars = () => ({
  type: t.GET_AVATARS,
});
export const setAvatars = avatars => ({
  type: t.SET_AVATARS,
  avatars,
});
export const addNewChild = child => ({
  type: t.ADD_CHILD,
  child,
});
export const getChild = id => ({
  type: t.GET_CHILD,
  id,
});
export const checkUsername = username => ({
  type: t.CHECK_USERNAME,
  username,
});
export const setUsernameTaken = usernameTaken => ({
  type: t.SET_USERNAME_TAKEN,
  usernameTaken,
});
