import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getChildren, getParent, getChildAndActivities } from './modules/actions';
import { setChosenChild } from '../EnrollChild/modules/actions';
import { getUser } from '../../../actions/auth';

const mapStateToProps = state => ({
  children: state.ParentReducer.children,
  parent: state.ParentReducer.parent,
  user: state.AuthReducer.user,
  childAndActivities: state.ParentReducer.childAndActivities,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getChildren,
      getParent,
      getUser,
      getChildAndActivities,
      setChosenChild,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
