import React, { useEffect, useMemo, useCallback } from 'react';

import './enrollChild.scss';
import { useHistory } from 'react-router-dom';
import CourseCard from '../../CourseCard';
import enrollCourseHeadlineIcon from '../../../images/courses/EnrollCourseIcon.svg';

const EnrollChild = props => {
  const { actions, child, courses, id } = props;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      actions.getCurrentChild(id);
      actions.getAvailableCoursesForChild(id);
      return () => {
        actions.setCurrentChild(null);
      };
    } else {
      history.push('/parent-profile');
    }
  }, [id]);

  const enrollChild = useCallback(
    course => {
      const enrollData = {
        userId: child.id,
        courseId: course.id,
      };

      actions.enrollChild(enrollData);
      history.push({
        pathname: '/successfully-enrolled',
        state: { courseTitle: course.title, childName: child.firstName },
      });
    },
    [actions, child]
  );

  const handleEnrollClick = useCallback(
    course => {
      enrollChild(course);
    },
    [enrollChild]
  );
  const childName = child?.firstName || 'Child';

  const coursesMapping = useMemo(() => {
    if (courses?.length > 0) {
      return (
        <>
          <div className="enroll-course-list">
            {courses.map((course, index) => (
              <CourseCard
                key={course?.id}
                index={index}
                course={course}
                handleEnrollClick={handleEnrollClick}
                childId={id}
                pathname="/parent-profile/course-overview"
              />
            ))}
          </div>
        </>
      );
    }
    return <div className="suggested-by-kutuby">{child?.firstName} is enrolled in all courses!</div>;
  }, [courses, handleEnrollClick, id]);

  return (
    <div className="background">
      <div className="enroll-course-headlines">
        <img src={enrollCourseHeadlineIcon} />
        <div>
          <div className="title">Enrol {childName} in a course</div>
          <div className="subtitle">Choose a course for {childName} to enrol in </div>
        </div>
      </div>
      <div className="enroll-courses-wrapper">{coursesMapping}</div>
    </div>
  );
};

export default EnrollChild;
