import { getAge } from './ageHelper';

const handleConfirmPasswordValidator = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Password doesn't match!"));
  },
});

const handleAddChildDatePickerValidator = ({ getFieldValue }) => ({
  validator() {
    const childAge = getAge(getFieldValue('date-picker')?.format('YYYY-MM-DD'));
    if (childAge < 5 || childAge > 12) {
      return Promise.reject(new Error('Child must be between 5 and 12 years old.'));
    } else {
      return Promise.resolve();
    }
  },
});

export const emailUsernameRules = [
  {
    required: true,
    message: 'Please input your email or username!',
  },
];

export const requiredFieldRule = [
  {
    required: true,
    message: 'Please fill out this field',
  },
];

export const emailRules = [
  {
    type: 'email',
    message: 'Email is invalid',
  },
  {
    required: true,
    message: 'Please input your E-mail!',
  },
];

export const passwordRules = [
  {
    required: true,
    message: 'Please input your Password!',
  },
];
export const passwordRegisterRules = [
  {
    required: true,
    message: 'Please input your Password!',
  },
  { min: 6, message: 'Password needs to have at least 6 characters' },
];

export const firstNameRules = [
  {
    required: true,
    message: 'Please input your First Name!',
  },
  {
    pattern: new RegExp(/^[A-Za-z ']+$/),
    message: 'Invalid character',
  },
  {
    min: 2,
    message: 'First name need to be at least 2 characters',
  },
];

export const lastNameRules = [
  {
    required: true,
    message: 'Please input your Last Name!',
  },
  {
    pattern: new RegExp(/^[A-Za-z ']+$/),
    message: 'Invalid character',
  },
  {
    min: 2,
    message: 'Last name need to be at least 2 characters',
  },
];

export const repeatPasswordRules = [
  {
    required: true,
    message: 'Please confirm your password!',
  },
  handleConfirmPasswordValidator,
];

export const addChildNameRules = [
  {
    required: true,
    message: 'Please input Child Name!',
  },
  {
    max: 25,
    message: "You can't use more than 25 characters.",
  },
  {
    pattern: new RegExp(/^[A-Za-z ']+$/),
    message: 'Invalid character',
  },
];
export const addChildUsernameRules = [
  {
    required: true,
    message: 'Please input Child Username!',
  },
  {
    pattern: new RegExp(/^[A-Za-z0-9_.]+$/),
    message: 'Invalid character',
  },
  {
    min: 5,
    message: "You can't use less than 5 characters.",
  },
  {
    max: 30,
    message: "You can't use more than 25 characters.",
  },
];
export const addChildPasswordRules = [
  {
    required: true,
    message: 'Please input Child Pin!',
  },
  {
    pattern: new RegExp(/^[0-9]{4}$/),
    message: 'Please insert 4 digit number',
  },
];
export const addChildDateRules = [
  {
    required: true,
    message: 'Please select Child Birthday!',
  },
  handleAddChildDatePickerValidator,
];
