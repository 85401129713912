import React from 'react';
import classNames from 'classnames';
import { Checkbox } from 'antd';
import { letterForAnswers } from '../../../../../utils/helpers';

const MultipleChoiceQuestionItem = ({
  item,
  confirm,
  isItemInArray,
  selectedAnswers,
  updateSelectedAnswers,
  standAlone,
  idx,
}) => {
  return (
    <Checkbox
      key={item.id}
      className={classNames({
        'answer-item-active': isItemInArray(item, selectedAnswers),
        'answer-item-active-standalone': isItemInArray(item, selectedAnswers) && standAlone,
        'answer-item-multiple-choice': true,
        'answer-item-multiple-choice-standalone': standAlone,
        'answer-item-correct-answers': confirm && item.correct,
        'answer-item-incorrect-answers': confirm && !item.correct,
      })}
      disabled={confirm}
      value={item}
      checked={isItemInArray(item, selectedAnswers)}
      onChange={updateSelectedAnswers}
    >
      <span className="answer-letter">{letterForAnswers(idx)}</span>
      {`  ${item.value}`}
    </Checkbox>
  );
};

export default MultipleChoiceQuestionItem;
