import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changePassword, deleteUser } from '../../../actions/auth';
import { getCurrentChild, setCurrentChild } from '../../Parent/ParentProfile/modules/actions';

const mapStateToProps = state => ({
  child: state.ParentReducer.currentChild,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      deleteUser,
      getCurrentChild,
      setCurrentChild,
      changePassword,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
