import React from 'react';
import NoContent from './NoContent';

const CourseSection = ({
  title,
  noContentTitle,
  noContentSubtitle,
  courses,
  totalPoints,
  collectedPoints,
  coursesLength,
}) => {
  return (
    <div>
      <div className="courses-section">
        <div className="courses-section-row">
          <div>
            <span className="courses-section-title">{title}</span>
            <span className="courses-section-value"> ({coursesLength})</span>
          </div>
          <span className="courses-section-total-points">
            (<span className="courses-section-collected-points">{collectedPoints}</span>/
            {totalPoints})
          </span>
        </div>
        {coursesLength < 1 ? (
          <NoContent title={noContentTitle} subtitle={noContentSubtitle} />
        ) : (
          <div className="enroll-course-list-section">{courses}</div>
        )}
      </div>
    </div>
  );
};

export default CourseSection;
