import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import JwtDecode from 'jwt-decode';
import Confetti from 'react-confetti';
import Logo from '../../../../images/footer/Logo.png';
import Plus from '../../../../images/footer/Plus.png';
import Artwork from '../../../../images/footer/Artwork.png';
import { Spin } from 'antd';

const PaymentStatus = ({ actions }) => {
  const history = useHistory();
  const token = localStorage.getItem('parent_profile_token');
  const decodedToken = JwtDecode(token);
  const [clickable, setClickable] = useState(false);

  useEffect(() => {
    actions.getUserPayment(decodedToken.user.id);
    actions.getPaymentLogs(decodedToken.user.id);

    const timer = setTimeout(() => {
      setClickable(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const pushToParentProfile = () => {
    history.push('/parent-profile');
  };

  return (
    <div className="background payment-status-wrapper">
      <img className="img-artwork" src={Artwork} alt="payment-success-icon" />
      <h1 className="payment-status-message">Congratulations,</h1>
      <h2 className="payment-status-message">You are now a member of</h2>
      <div>
        <img src={Logo} alt="kutuby-logo" />
        <img className="img-plus" src={Plus} alt="plus-icon" />
      </div>
      <Confetti numberOfPieces={800} recycle={false} />
      {clickable ? (
        <button
          className="payment-status-button"
          onClick={pushToParentProfile}
          disabled={!clickable}
        >
          Let's Start
        </button>
      ) : (
        <Spin />
      )}
    </div>
  );
};

export default PaymentStatus;
