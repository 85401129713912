import React from 'react';
import NoContent from '../../../ChildProfile/components/NoContent';
import CoursesList from '../lists/CoursesList';

const CourseSection = ({
  noContentSubtitle,
  noContentTitle,
  courseData,
  getCourses,
  getCurrentWeekOverview,
  childId,
  userRole,
  setPausedModalVisibility,
  title,
  collectedPoints,
  totalPoints,
}) => (
  <div className="courses-section">
    <div className="courses-section-row">
      <div>
        <span className="courses-section-title">{title}</span>
        <span className="courses-section-value"> ({courseData?.length})</span>
      </div>
      <span className="courses-section-total-points">
        <span className="courses-section-collected-points">{collectedPoints}</span>/{totalPoints}
      </span>
    </div>
    {courseData?.length < 1 && <NoContent title={noContentTitle} subtitle={noContentSubtitle} />}
    <div className="enroll-course-list-section">
      <CoursesList
        data={courseData}
        getCourses={getCourses}
        getCurrentWeekOverview={getCurrentWeekOverview}
        childId={childId}
        userRole={userRole}
        setPausedModalVisibility={setPausedModalVisibility}
      />
    </div>
  </div>
);

export default CourseSection;
