import React, { useMemo } from 'react';
import classNames from 'classnames';
import JwtDecode from 'jwt-decode';
import { letterForAnswers } from '../../../../../utils/helpers';
import StandAloneQuizButtons from './StandAloneQuizButtons';
import '../childCourseQuizzes.scss';

const QuizQuestions = ({ quiz, questionNum, actions, submittedAnswers, answer, activeAnswer }) => {
  const token = localStorage.getItem('child_profile_token');

  let decoded = JwtDecode(token);

  const handleNext = () => {
    actions.quizController({
      state: 'next',
      answer: answer,
      activeAnswerIndex: activeAnswer,
      questionNum,
      submittedAnswers,
      quiz,
    });
  };

  const handlePrevious = () => {
    actions.quizController({
      state: 'prev',
      answer: answer,
      activeAnswerIndex: activeAnswer,
      questionNum,
      submittedAnswers,
      quiz,
    });
  };

  const handleFinish = () => {
    actions.quizController({
      state: 'finish',
      answer: answer,
      activeAnswerIndex: activeAnswer,
      questionNum,
      submittedAnswers,
      quiz,
      userId: decoded.user.id,
    });
  };
  const answersMapping = useMemo(
    () =>
      quiz?.questions[questionNum]?.answers.map((item, idx) => {
        const activeAnswerSetter = (idx, item) => () => {
          if (answer?.idx === idx) {
            actions.setAnswer(null);
          } else {
            // change active to current index
            actions.setAnswer({ ...item, idx: idx });
          }
        };

        return (
          <div
            key={item.id}
            className={classNames({
              'quiz-answer-item-active': answer?.idx === idx,
              'quiz-answer-item': true,
            })}
            onClick={activeAnswerSetter(idx, item)}
          >
            <span className="answer-letter">{letterForAnswers(idx)}</span>
            <span className="answer-text">{item.value}</span>
          </div>
        );
      }),
    [questionNum, quiz?.questions, actions, answer]
  );
  const disabledNextCondition = !answer;

  return (
    <div className="quiz-questions-wrapper">
      <div className="quiz-answer-wrapper">
        {/* answers */}
        {answersMapping}
        <div className="quiz-answer-buttons">
          <StandAloneQuizButtons
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            totalQuestions={quiz?.questions?.length}
            currentQuestion={questionNum}
            handleFinish={handleFinish}
            disabledNextCondition={disabledNextCondition}
          />
        </div>
      </div>
    </div>
  );
};

export default QuizQuestions;
