import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { userTypes } from '../../../utils/constants';
import EnrolledCourseOverview from './EnrolledCourseOverview';
import ChildLessonDetails from '../../Child/ChildDashboard/ChildLessonDetails';
import './courseOverview.scss';

const userType = userTypes.parent;

const CourseOverview = props => {
  const { actions, course, child, userPaymentStatus } = props;
  const { state } = useLocation();
  const [lessonModal, setLessonModal] = useState(false);
  const [activeLesson, setActiveLesson] = useState(null);
  const history = useHistory();

  const courseId = state?.courseId;
  const childId = state?.childId;
  const isEnrolled = state?.isEnrolled;

  useEffect(() => {
    if (isEnrolled) {
      actions.getCoursesOverviewForChildById(childId, courseId);
    } else {
      actions.getCourseById(userType, courseId);
    }

    actions.getCurrentChild(childId);

    return () => {
      actions.setCourse(null);
      actions.setCurrentChild(null);
    };
  }, []);

  const closeLessonModal = () => {
    setLessonModal(false);
  };

  const handleOpenLessonClick = lesson => () => {
    history.push({
      pathname: '/parent-profile/lesson-overview',
      state: { lesson: lesson, lessonName: lesson.title, courseName: course.title },
    });
  };

  // Close modal if user clicks outside of it
  if (lessonModal) {
    window.addEventListener('click', e => {
      if (
        document.getElementById('modalContainer')?.contains(e.target) &&
        !document.getElementById('lessonModal')?.contains(e.target)
      ) {
        setLessonModal(false);
      }
    });
  }

  const handleEnrollClick = () => {
    enrollChild();
  };

  const enrollChild = () => {
    const enrollData = {
      userId: childId,
      courseId: courseId,
      from: new Date().toISOString(),
    };

    actions.enrollChild(enrollData);
    history.push({
      pathname: '/successfully-enrolled',
      state: { courseTitle: course.title, childName: child.firstName },
    });
  };

  return (
    <div className="background">
      {course && child && (
        <EnrolledCourseOverview
          handleEnrollClick={handleEnrollClick}
          child={child}
          course={course}
          handleOpenLessonClick={handleOpenLessonClick}
          isEnrolled={isEnrolled}
          actions={actions}
          userPaymentStatus={userPaymentStatus}
        />
      )}

      <ChildLessonDetails
        activeLesson={activeLesson}
        lessonModal={lessonModal}
        openWatchNowModal={false}
        watchNowModal={null}
        isParentView={true}
        closeLessonModal={closeLessonModal}
      />
    </div>
  );
};

export default CourseOverview;
