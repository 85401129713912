import { Elements } from '@stripe/react-stripe-js';
import { Spin } from 'antd';
import TrialPaymentForm from './TrialPaymentForm';
import moment from 'moment';

const TrialPaymentElements = ({
  clientSecret,
  stripePromise,
  options,
  createTrialSubscription,
  sliderValue,
}) => {
  const handlePaymentSubmit = async setupIntentId => {
    createTrialSubscription(setupIntentId);
  };
  const firstInvoiceDate = moment().add(2, 'w').format('Do MMMM YYYY');
  return (
    <div className="stripe-elements-wrapper">
      <div className="trial-subscription-info">
        <div className="horizontal-line"></div>
        <span className="title">2-Weeks Free Trial</span>
        <span className="description">Starting Today</span>
        <div className="horizontal-line"></div>
        <span className="title">{sliderValue} $ Per Month</span>
        <span className="description">Starting on {firstInvoiceDate}</span>
        <div className="horizontal-line"></div>
        <span className="description-sm">
          After the trial period ends, your subscription will automatically renew and <br /> you
          will be charged the monthly rate. You can cancel anytime during the <br /> trial period to
          avoid being charged.
        </span>
      </div>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <TrialPaymentForm clientSecret={clientSecret} onSubmit={handlePaymentSubmit} />
        </Elements>
      ) : (
        <Spin tip="Loading..." />
      )}
    </div>
  );
};

export default TrialPaymentElements;
