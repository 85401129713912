export const t = {
  GET_CHILDREN: 'GET_CHILDREN',
  SET_CHILDREN: 'SET_CHILDREN',
  GET_PARENT: 'GET_PARENT',
  SET_PARENT: 'SET_PARENT',
  GET_CURRENT_CHILD: 'GET_CURRENT_CHILD',
  SET_CURRENT_CHILD: 'SET_CURRENT_CHILD',
  GET_CHILD_AND_ACTIVITIES: 'GET_CHILD_AND_ACTIVITIES',
  SET_CHILD_AND_ACTIVITIES: 'SET_CHILD_AND_ACTIVITIES',
};

export const getChildren = () => ({
  type: t.GET_CHILDREN,
});
export const setChildren = children => ({
  type: t.SET_CHILDREN,
  children,
});

export const getParent = () => ({
  type: t.GET_PARENT,
});
export const setParent = parent => ({
  type: t.SET_PARENT,
  payload: {
    parent,
  },
});

export const getCurrentChild = id => ({
  type: t.GET_CURRENT_CHILD,
  payload: {
    id,
  },
});
export const setCurrentChild = child => ({
  type: t.SET_CURRENT_CHILD,
  payload: {
    child,
  },
});

export const getChildAndActivities = id => ({
  type: t.GET_CHILD_AND_ACTIVITIES,
  payload: {
    id,
  },
});
export const setChildAndActivities = child => ({
  type: t.SET_CHILD_AND_ACTIVITIES,
  payload: {
    child,
  },
});
