import React, { useMemo } from 'react';
import { Form, Input, Button } from 'antd';
import './editUserItem.scss';
import { requiredFieldRule } from '../../utils/inputRules';

const EditUserForm = ({ handleOnFinish, fields, icon, handleCancel, inputRules }) => {
  const onFinish = value => {
    handleOnFinish.childId
      ? handleOnFinish.action({
          dataToEdit: value,
          id: handleOnFinish.parentId,
          childId: handleOnFinish.childId,
        })
      : handleOnFinish.action({ dataToEdit: value, id: handleOnFinish.parentId });
    handleCancel();
  };

  const fieldsMapping = useMemo(
    () =>
      fields?.map(item => (
        <Form.Item rules={inputRules || requiredFieldRule} key={item.name} name={item.name}>
          <Input
            prefix={<img src={icon} />}
            className="edit-user-input"
            placeholder={item.placeholder}
          />
        </Form.Item>
      )),
    [fields, icon]
  );

  return (
    <div className="edit-first-name-wrapper">
      <Form name="edit-first-name" onFinish={onFinish}>
        {fieldsMapping}
        <Form.Item>
          <Button className="edit-user-button" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditUserForm;
