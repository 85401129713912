import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentChild } from '../Parent/ParentProfile/modules/actions';
import { getChildProfile } from '../Child/ChildDashboard/modules/actions';
import {
  setActiveLesson,
  setActiveQuiz,
  setWatchNowModal,
  setQuizModal,
} from '../../components/Child/ChildDashboard/modules/actions';
import { getUser } from '../../actions/auth';
import { resetState } from '../Child/ChildDashboard/ChildCourseQuizzes/modules/actions';

const mapStateToProps = state => ({
  currentChild: state.ParentReducer.currentChild,
  child: state.AuthReducer.user,
  watchNowModal: state.ChildLessonReducer.watchNowModal,
  quizModal: state.ChildLessonReducer.quizModal,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCurrentChild,
      getChildProfile,
      setActiveLesson,
      setActiveQuiz,
      setWatchNowModal,
      setQuizModal,
      getUser,
      resetState,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
