import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentUser } from '../../../Child/Courses/modules/actions';
import { changePassword } from '../../../../actions/auth';

const mapStateToProps = state => ({
  currentUser: state.CoursesReducer.currentUser,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCurrentUser,
      changePassword,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
