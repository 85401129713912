import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { logger } from '../../utils/logger';
import './styles.scss';

const ErrorHandler = ({ error }) => {
  logger(error);

  return (
    <div className="error-handler-wrapper">
      <ExclamationCircleOutlined className="error-handler-icon" />
      <span>Something Went Wrong</span>
    </div>
  );
};

export default ErrorHandler;
