import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import JwtDecode from 'jwt-decode';
import { Button, Slider, Modal } from 'antd';

import { appearance } from './components/CheckoutFormAppearance';
import config from '../../config';
import PaymentElements from './components/PaymentElements';
import TrialPaymentElements from './components/TrialPaymentElements';

import Logo from '../../images/footer/Logo.png';
import Plus from '../../images/footer/Plus.png';

import './payment.scss';
import FreeTrial from './components/FreeTrial';

const stripePromise = loadStripe(config.stripePublicKey);
const View = ({
  actions,
  clientSecret,
  subscriptionId,
  products,
  userPaymentStatus,
  paymentLogs,
}) => {
  const [sliderValue, setSliderValue] = useState(1);
  const [step, setStep] = useState(1);
  const [trial, setTrial] = useState(false);
  const [visible, setVisible] = useState(false);

  const token = localStorage.getItem('parent_profile_token');
  let decoded = JwtDecode(token);
  const history = useHistory();

  const nextStep = () => {
    setStep(prev => prev + 1);
  };

  const onSliderChange = newValue => {
    setSliderValue(newValue);
  };

  const content = useMemo(() => {
    const options = {
      clientSecret: clientSecret,
      subscriptionId: subscriptionId,
      appearance,
    };

    const createSubscription = priceId => {
      actions.createSubscription({
        priceId: priceId,
        customerId: decoded.user.id,
      });
    };
    const createUserPayment = () => {
      const userPaymentData = {
        userId: decoded.user.id,
        duration: 'year',
        plan: 'TRIAL',
        subscriptionId: '',
      };
      actions.createUserPayment(userPaymentData);
      history.push('/payment-status');
      toggleModal();
    };

    const createTrialSubscription = setupIntentId => {
      const product = products.find(item => item.name === sliderValue.toString());
      actions.createTrialSubscription({
        priceId: product.price.id,
        customerId: decoded.user.id,
        setupIntentId: setupIntentId,
      });
      history.push('/payment-status');
    };

    const createSetupIntents = () => {
      actions.createSetupIntents({
        customerId: decoded.user.id,
      });
    };
    const toggleModal = () => {
      setVisible(!visible);
    };
    const proceedToPayment = () => {
      if (sliderValue === 0) {
        toggleModal();
      } else {
        const product = products.find(item => item.name === sliderValue.toString());
        trial ? createSetupIntents() : createSubscription(product.price.id);
        nextStep();
      }
    };

    const prevStep = () => {
      actions.setClientSecret(null);
      setStep(prev => prev - 1);
      setSliderValue(1);
    };

    if (step === 1) {
      return <FreeTrial createUserPayment={nextStep} trial={trial} />;
    }
    if (step === 2) {
      return (
        <div className="buy-subscription-wrapper">
          <Modal visible={visible} footer={null} className="caution-modal">
            <span className="subtitle">
              Are you sure you want to select 0$ as it is for people who are eligible for the zakat
              and underprivileged people
            </span>
            <Button className="proceed-to-payment-btn" onClick={createUserPayment}>
              Submit
            </Button>
          </Modal>
          <div className="pro-logo">
            <img src={Logo} alt="plus-logo" />
            <img src={Plus} alt="plus-logo" />
          </div>
          <div className="plan-card">
            <span className="title">Choose How Much you Pay</span>
            <span className="value">
              <span className="dollar">$</span>
              {sliderValue}
              <span className="month"> /month</span>
            </span>
            <Slider min={1} max={10} onChange={onSliderChange} />
            <span className="subtitle">
              Adjust the slider to the fee that you want to pay for Kutuby Plus. $5 is the <br />
              average price that people pay for Kutuby Plus.
            </span>
            <span className="subtitle-gray">
              If you are Zakat eligible, please email support@kutuby.com for free access to Kutuby
            </span>
            <Button className="proceed-to-payment-btn" onClick={proceedToPayment}>
              Proceed to Payment
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="buy-subscription-wrapper">
        <div className="pro-logo">
          <img src={Logo} alt="plus-logo" />
          <img src={Plus} alt="plus-logo" />
        </div>
        {trial ? (
          <TrialPaymentElements
            clientSecret={clientSecret}
            stripePromise={stripePromise}
            options={options}
            products={products}
            createTrialSubscription={createTrialSubscription}
            prevStep={prevStep}
            sliderValue={sliderValue}
          />
        ) : (
          <PaymentElements
            clientSecret={clientSecret}
            stripePromise={stripePromise}
            options={options}
            products={products}
            createSubscription={createSubscription}
            prevStep={prevStep}
          />
        )}
      </div>
    );
  }, [
    clientSecret,
    products,
    sliderValue,
    step,
    visible,
    actions,
    decoded,
    subscriptionId,
    history,
    trial,
  ]);

  useEffect(() => {
    if (userPaymentStatus) {
      history.push('/parent-profile/settings');
    }
    actions.getProducts();
    if (!userPaymentStatus) {
      actions.getUserPayment(decoded.user.id);
    }
    actions.getPaymentLogs(decoded.user.id);
  }, [userPaymentStatus]);

  useEffect(() => {
    if (!paymentLogs?.length) {
      setTrial(true);
    }
  }, [paymentLogs]);

  return <div className="background payment-content-center">{content}</div>;
};

export default View;
