/* eslint-disable import/no-anonymous-default-export */
import { t } from '../actions/userPayment';
const defaultState = {
  userPaymentStatus: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_USER_PAYMENT:
      return {
        ...state,
        userPaymentStatus: action.payload.data[0],
      };
    default:
      return state;
  }
};
