import React from 'react';
import '../enrolledCourseOverview.scss';
import courseDetailsAgeIcon from '../../../../../images/courses/Age.svg';
import courseDetailsDurationIcon from '../../../../../images/courses/Duration.svg';
import NoThumbnail from '../../../../../images/child/no-thumbnail.png';

const CourseDetails = ({ course }) => {
  const ageGroupLabel = () => {
    if (!course.fromAge) {
      return "5+ yrs"
    }
      return course.toAge ? `${course.fromAge}-${course.toAge} yrs` : `${course.fromAge}+ yrs`

  }
  return (
    <div className="course-detail-wrapper">
      <div className="left-side">
        <span className="title">{course?.title}</span>
        <div className="details-row">
          <div className="details-row-item">
            <img src={courseDetailsAgeIcon} />

            <div className="title-value-column">
              <span className="details-row-title">Age</span>
              <span className="details-row-value">{ageGroupLabel()}</span>
            </div>
          </div>

          <div className="details-row-item">
            <img src={courseDetailsDurationIcon} />

            <div className="title-value-column">
              <span className="details-row-title">Duration</span>
              <span className="details-row-value">{course?.totalWeeks} Weeks</span>
            </div>
          </div>
        </div>
        <span className="description">{course?.description}</span>
      </div>
      <div className="right-side">
        <img
          src={
            course.courseImagesLandscape?.[0] ? course.courseImagesLandscape[0].url : NoThumbnail
          }
        />
      </div>
    </div>
  );
};

export default CourseDetails;
