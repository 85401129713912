export const t = {
  REGISTER_USER: 'REGISTER_USER',
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_BY_PARENT: 'LOGIN_BY_PARENT',
  DELETE_USER: 'DELETE_USER',
  GET_PROFILES: 'GET_PROFILES',
  SET_PROFILES: 'SET_PROFILES',
  LOGIN_PROFILE: 'LOGIN_PROFILE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  LOGOUT_PARENT: 'LOGOUT_PARENT',
  LOGOUT_CHILD: 'LOGOUT_CHILD',
  LOGOUT: 'LOGOUT',
  VERIFIY_EMAIL: 'VERIFIY_EMAIL',
  SET_IS_VERIFIED: 'SET_IS_VERIFIED',
  SET_IS_PROCESSED: 'SET_IS_PROCESSED',
  SEND_RESET_PASSWORD_EMAIL: 'SEND_RESET_PASSWORD_EMAIL',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SEND_VERIFICATION_EMAIL: 'SEND_VERIFICATION_EMAIL',
  SET_RESET_PASSWORD_SUCCESS: 'SET_RESET_PASSWORD_SUCCESS',
  SWITCH_ACCOUNT_LOGOUT: 'SWITCH_ACCOUNT_LOGOUT',
  SET_LAYOUT_MODE: 'SET_LAYOUT_MODE',
  SET_SAVED_ACCOUNTS: 'SET_SAVED_ACCOUNTS',
  GET_SAVED_ACCOUNTS: 'GET_SAVED_ACCOUNTS',
  REMOVE_SAVED_ACCOUNTS: 'REMOVE_SAVED_ACCOUNTS',
  GET_USER: 'GET_USER',
  SET_USER: 'SET_USER',
  UPDATE_USER: 'UPDATE_USER',
  VERIFY_USER: 'VERIFY_USER',
};

export const register = userDetails => ({
  type: t.REGISTER_USER,
  payload: {
    userDetails,
  },
});

export const login = userDetails => ({
  type: t.LOGIN_USER,
  payload: {
    userDetails,
  },
});
export const loginByParent = userDetails => ({
  type: t.LOGIN_BY_PARENT,
  payload: {
    userDetails,
  },
});

export const getProfiles = () => ({
  type: t.GET_PROFILES,
});

export const setProfiles = profiles => ({
  type: t.SET_PROFILES,
  profiles,
});

export const loginProfile = profile => ({
  type: t.LOGIN_PROFILE,
  profile,
});
export const changePassword = (oldPassword, newPassword, childId) => ({
  type: t.CHANGE_PASSWORD,
  payload: {
    oldPassword,
    newPassword,
    childId,
  },
});
export const logout = () => ({
  type: t.LOGOUT,
});
export const switchAccountLogout = () => ({
  type: t.SWITCH_ACCOUNT_LOGOUT,
});
export const logoutParent = () => ({
  type: t.LOGOUT_PARENT,
});
export const logoutChild = () => ({
  type: t.LOGOUT_CHILD,
});
export const verifyEmail = (email, token) => ({
  type: t.VERIFIY_EMAIL,
  email,
  token,
});
export const setIsVerified = isVerified => ({
  type: t.SET_IS_VERIFIED,
  payload: {
    isVerified,
  },
});
export const setIsProcessed = isProcessed => ({
  type: t.SET_IS_PROCESSED,
  payload: {
    isProcessed,
  },
});
export const sendResetPasswordEmail = email => ({
  type: t.SEND_RESET_PASSWORD_EMAIL,
  payload: {
    email,
  },
});
export const resetPassword = (email, password, token) => ({
  type: t.RESET_PASSWORD,
  payload: {
    email,
    password,
    token,
  },
});

export const sendVerificationEmail = email => ({
  type: t.SEND_VERIFICATION_EMAIL,
  payload: {
    email,
  },
});

export const setResetPasswordSuccess = isSuccessful => ({
  type: t.SET_RESET_PASSWORD_SUCCESS,
  payload: {
    isSuccessful,
  },
});

export const deleteUser = (userId, isParent, email, password) => ({
  type: t.DELETE_USER,
  payload: {
    userId,
    isParent,
    email,
    password,
  },
});

export const setLayoutMode = mode => ({
  type: t.SET_LAYOUT_MODE,
  payload: {
    mode,
  },
});

export const setSavedAccounts = accounts => ({
  type: t.SET_SAVED_ACCOUNTS,
  payload: {
    accounts,
  },
});

export const removeSavedAccount = () => ({
  type: t.REMOVE_SAVED_ACCOUNTS,
});

export const getSavedAccounts = () => ({
  type: t.GET_SAVED_ACCOUNTS,
});

export const getUser = () => ({
  type: t.GET_USER,
});

export const setUser = userData => ({
  type: t.SET_USER,
  payload: { userData },
});

export const updateUser = userData => ({
  type: t.UPDATE_USER,
  payload: { userData },
});

export const verifyUser = userData => ({
  type: t.VERIFY_USER,
  payload: { userData },
});
