export const noClassesMissedText = 'No Classes Missed!';
export const classesMissedText = 'Classes Missed!';
export const alwaysOnTimeText = 'Always On Time!';
export const cameLateText = 'x Came Late!';
export const userTypes = {
  child: 'CHILD',
  parent: 'PARENT',
};
export const timeFormat = 'hh:mm A';
export const dateFormat = 'MMMM D, YYYY';
export const activityTypes = {
  upcomingLessons: 'UPCOMING_LESSONS',
  pastLessons: 'PAST_LESSONS',
  uncompletedQuizzes: 'UNCOMPLETED_QUIZZES',
  completedQuizzes: 'COMPLETED_QUIZZES',
};
export const MAILCHIMP_URL =
  'https://kutuby.us20.list-manage.com/subscribe/post?u=bd8478769a826d86506ebd859&amp;id=caf540ed42';
export const fullColors = [
  'course-item-blue-full',
  'course-item-red-full',
  'course-item-orange-full',
];

export const singleAnswerQuestionType = 'SINGLE_ANSWER_QUESTION_TYPE';
export const multipleChoiceQuestionType = 'MULTIPLE_CHOICE_QUESTION_TYPE';
export const singleAnswerQuizType = 'SINGLE_ANSWER_QUIZ_TYPE';
export const multipleChoiceQuizType = 'MULTIPLE_CHOICE_QUIZ_TYPE';
export const dragAndDropQuizType = 'DRAG_AND_DROP_QUIZ_TYPE';
export const dragAndDropQuestionType = 'DRAG_AND_DROP_QUESTION_TYPE';
export const googlePlayAppUrl =
  'https://play.google.com/store/apps/details?id=com.antcolony.kutuby&hl=en&gl=US&pli=1';
export const appStoreAppUrl = 'https://apps.apple.com/al/app/kutuby/id1598288431';
export const generalErrorMessage = 'An error occurred';
export const webPlatform = 'WEB';
