import ReactGA from 'react-ga4';

export const events = {
  AUTH_EVENTS_CATEGORY: 'auth_event_category',
  PARENT_EVENTS_CATEGORY: 'parent_event_category',
  CHILD_EVENTS_CATEGORY: 'child_event_category',
  AUTH_EVENTS: {
    START_REGISTRATION: 'start_registration_web',
    USER_DELETED: 'user_deleted_web',
    END_REGISTRATION: 'end_registration_web',
    REGISTERED_USER_WEB: 'registered_user_web',
  },
  PARENT_EVENTS: {
    START_ADDING_CHILD: 'start_adding_child_web',
    CHILD_ENROLLED: 'child_enrolled_web',
    CHILD_DELETED: 'child_deleted_web',
    FINISH_ADDING_CHILD: 'finish_adding_child_web',
  },
  CHILD_EVENTS: {
    CORRECT_ANSWER: 'correct_answer_web',
    WRONG_ANSWER: 'wrong_answer_web',
    WATCHED_VIDEO: 'video_watched_web',
  },
};

export const eventTrack = (category, action) => {
  ReactGA.event({
    category: category,
    action: action,
  });
};
