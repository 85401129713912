import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { LeftOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Row, Modal } from 'antd';

import renderPasswordIcon from '../../../utils/renderPasswordIcon';

const ParentSettings = props => {
  const { actions, child, history } = props;
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    actions.getCurrentChild(id);
    form.resetFields();

    return () => {
      actions.setCurrentChild(null);
    };
  }, []);

  const deleteAccount = () => {
    actions.deleteUser(child.id, false);
    history.push('/parent-profile');
  };

  const changePassword = values => {
    actions.changePassword(values.oldPassword, values.password, id);
  };

  const toggleModal = () => {
    setVisible(!visible);
  };

  const passwordValidationRules = [
    {
      pattern: new RegExp(/^[0-9]{4}$/),
      message: 'Please insert 4 digit number',
    },
  ];

  return (
    <div className="background">
      <div className="container pt-4">
        <NavLink to="/parent-profile" className="overview-navigation">
          <LeftOutlined className="arrow-back-overview" />
          Back
        </NavLink>

        <Modal
          title="Are you sure you want to delete this account?"
          visible={visible}
          onOk={() => {
            toggleModal();
            deleteAccount();
          }}
          onCancel={toggleModal}
          okText="Delete"
          cancelText="Cancel"
        >
          <p>Deleting this account is permanent and will remove all of its content.</p>
        </Modal>

        <div className="courses-overview-title">Child settings</div>

        <Row className="settings-center settings-title-container">
          <Row className="settings-welcome-msg">{child?.firstName}</Row>

          <Row className="access-settings">Security comes First</Row>

          <Row className="change-password">Change Child's Password</Row>
        </Row>

        <div className="mt-5 change-password-container">
          <Form form={form} name="parent_password" onFinish={changePassword}>
            <Form.Item
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: 'Please input parent Password!',
                },
                {
                  min: 6,
                  message: 'Password needs to have at least 6 characters',
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                iconRender={renderPasswordIcon}
                className="register-form-input-half-column"
                placeholder={'Parent password'}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
                ...passwordValidationRules,
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                iconRender={renderPasswordIcon}
                className="register-form-input-half-column"
                placeholder="New pin"
              />
            </Form.Item>

            <Form.Item
              name="repeatPassword"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Password does not match!'));
                  },
                }),
                ...passwordValidationRules,
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                iconRender={renderPasswordIcon}
                className="register-form-input-half-column"
                placeholder="Repat new pin"
              />
            </Form.Item>
            <Form.Item>
              <button className="save-changes">Save Changes</button>
            </Form.Item>

            <button className="delete-account-button" onClick={toggleModal}>
              Delete Account
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ParentSettings;
