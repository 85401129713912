import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { eventTrack, events } from '../../../utils/googleAnalyticsEventTrack';
import accountVerifiedIcon from '../../../images/register/accountVerified.svg';
import '../../Login/login.scss';
import { Button } from 'antd';

const RegistrationConfirmed = () => {
  const history = useHistory();

  useEffect(() => {
    eventTrack(events.AUTH_EVENTS_CATEGORY, events.AUTH_EVENTS.REGISTERED_USER_WEB);
  }, []);
  const pushToLogin = () => {
    history.push('/login');
  };

  return (
    <div className="background">
      <div className="login-wrapper">
        <div className="login-form-box">
          <div className="login-form-header">
            <span className="login-form-title">Account Verified</span>
          </div>
          <img
            className="account-verified-img"
            src={accountVerifiedIcon}
            alt="account-verified-icon"
          />
          <div className="login-content-box">
            <Button className="login-button" onClick={pushToLogin}>
              Done
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationConfirmed;
