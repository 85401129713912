import { ParentAuthorizedService, ChildAuthorizedService } from './apiService';

const BASE_PATH = '/users';

export const deleteUserData = async userId => {
  try {
    const response = await ParentAuthorizedService.delete(`${BASE_PATH}/${userId}`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getUserData = async () => {
  try {
    if (localStorage.getItem('parent_profile_token')) {
      return await ParentAuthorizedService.get('/me');
    }
    return await ChildAuthorizedService.get('/me');
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};
