export const t = {
  GET_LESSONS: 'GET_LESSONS',
  GET_COURSE: 'GET_COURSE',
  SET_LESSONS: 'SET_LESSONS',
  SET_COURSE: 'SET_COURSE',
  ADD_LESSONS: 'ADD_LESSONS',
  SET_HAS_MORE_LESSONS: 'SET_HAS_MORE_LESSONS',
  GET_CHILD_PROFILE: 'GET_CHILD_PROFILE',
  SET_CHILD_PROFILE: 'SET_CHILD_PROFILE',
  GET_QUIZZES: 'GET_QUIZZES',
  SET_QUIZZES: 'SET_QUIZZES',
  ADD_QUIZZES: 'ADD_QUIZZES',
  SET_HAS_MORE_QUIZZES: 'SET_HAS_MORE_QUIZZES',
  GET_QUIZ_BY_ID: 'GET_QUIZ_BY_ID',
  GET_SINGLE_QUIZ: 'GET_SINGLE_QUIZ',
  SET_SINGLE_QUIZ: 'SET_SINGLE_QUIZ',
  GET_LESSON_BY_ID: 'GET_LESSON_BY_ID',
  SET_LESSON_BY_ID: 'SET_LESSON_BY_ID',
  GET_SINGLE_LESSON: 'GET_SINGLE_LESSON',
  SET_SINGLE_LESSON: 'SET_SINGLE_LESSON',
  SET_WATCH_NOW_MODAL: 'SET_WATCH_NOW_MODAL',
  SET_QUIZ_MODAL: 'SET_QUIZ_MODAL',
  SET_LESSON_MODAL: 'SET_LESSON_MODAL',
  SET_ACTIVE_LESSON: 'SET_ACTIVE_LESSON',
  SET_ACTIVE_QUIZ: 'SET_ACTIVE_QUIZ',
};

export const getCourseById = (type, courseId) => ({
  type: t.GET_COURSE,
  payload: {
    type,
    courseId,
  },
});

export const setCourse = course => ({
  type: t.SET_COURSE,
  payload: {
    course,
  },
});

export const getLessons = (type, page) => ({
  type: t.GET_LESSONS,
  payload: {
    type,
    page,
  },
});

export const setLessons = lessons => ({
  type: t.SET_LESSONS,
  payload: {
    lessons,
  },
});

export const addLessons = lessons => ({
  type: t.ADD_LESSONS,
  payload: {
    lessons,
  },
});

export const setHasMoreLessons = hasMore => ({
  type: t.SET_HAS_MORE_LESSONS,
  payload: {
    hasMore,
  },
});

export const getChildProfile = () => ({
  type: t.GET_CHILD_PROFILE,
});

export const setChildProfile = child => ({
  type: t.SET_CHILD_PROFILE,
  child,
});

export const getQuizById = id => ({
  type: t.GET_QUIZ_BY_ID,
  id,
});

export const getSingleQuiz = () => ({
  type: t.GET_SINGLE_QUIZ,
});

export const setSingleQuiz = quiz => ({
  type: t.SET_SINGLE_QUIZ,
  quiz,
});

export const getSingleLesson = () => ({
  type: t.GET_SINGLE_LESSON,
});

export const setSingleLesson = lesson => ({
  type: t.SET_SINGLE_LESSON,
  lesson,
});

export const getLessonById = id => ({
  type: t.GET_LESSON_BY_ID,
  id,
});

export const setLessonById = lesson => ({
  type: t.SET_LESSON_BY_ID,
  lesson,
});

export const getQuizzes = (type, page) => ({
  type: t.GET_QUIZZES,
  payload: {
    type,
    page,
  },
});

export const setQuizzes = quizzes => ({
  type: t.SET_QUIZZES,
  payload: {
    quizzes,
  },
});

export const addQuizzes = quizzes => ({
  type: t.ADD_QUIZZES,
  payload: {
    quizzes,
  },
});

export const setHasMoreQuizzes = hasMore => ({
  type: t.SET_HAS_MORE_QUIZZES,
  payload: {
    hasMore,
  },
});

export const setWatchNowModal = () => ({
  type: t.SET_WATCH_NOW_MODAL,
});
export const setQuizModal = () => ({
  type: t.SET_QUIZ_MODAL,
});

export const setLessonModal = status => ({
  type: t.SET_LESSON_MODAL,
  payload: {
    status,
  },
});

export const setActiveLesson = activeLesson => ({
  type: t.SET_ACTIVE_LESSON,
  payload: {
    activeLesson,
  },
});

export const setActiveQuiz = activeQuiz => ({
  type: t.SET_ACTIVE_QUIZ,
  payload: {
    activeQuiz,
  },
});
