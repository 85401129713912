import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Button, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CheckoutForm from './CheckoutForm';

const PaymentElements = ({ clientSecret, stripePromise, options, prevStep }) => {
  return (
    <div className="stripe-elements-wrapper">
      {clientSecret ? (
        <>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
          <Button className="previous-btn" size="large" onClick={prevStep}>
            <ArrowLeftOutlined /> Previous
          </Button>
        </>
      ) : (
        <Spin tip="Loading..." />
      )}
    </div>
  );
};

export default PaymentElements;
