/* eslint-disable import/no-anonymous-default-export */
import { v4 as uuidv4 } from 'uuid';
import { t } from '../actions/errorNotification';
const defaultState = {
  errorNotifications: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_NEW_ERROR_NOTIFICATION:
      return {
        ...state,
        errorNotifications: [
          ...state.errorNotifications,
          { ...action.payload.notification, id: uuidv4() },
        ],
      };
    case t.REMOVE_ALL_ERROR_NOTIFICATION:
      return {
        ...state,
        errorNotifications: [],
      };
    case t.REMOVE_SINGLE_ERROR_NOTIFICATION:
      return {
        ...state,
        errorNotifications: state.errorNotifications.filter(item => {
          return item.id !== action.payload.notification;
        }),
      };
    default:
      return state;
  }
};
