/* eslint-disable import/no-anonymous-default-export */
import { t } from './actions';
const defaultState = {
  courses: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_COURSES:
      return {
        ...state,
        courses: action.courses,
      };
    case t.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      };
    default:
      return state;
  }
};
