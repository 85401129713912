import { combineReducers } from 'redux';
import AuthReducer from './auth';
import RegisterReducer from '../components/Register/modules/reducer';
import AddChildReducer from '../components/Parent/AddChild/modules/reducer';
import ParentReducer from '../components/Parent/ParentProfile/modules/reducer';
import PaymentReducer from '../components/Payment/modules/reducer';
import CoursesReducer from '../components/Child/Courses/modules/reducer';
import ChildToDoReducer from '../components/Parent/ChildToDo/modules/reducer';
import ChildLessonReducer from '../components/Child/ChildDashboard/modules/reducer';
import QuizReducer from '../components/Child/Quiz/modules/reducer';
import EnrollChildReducer from '../components/Parent/EnrollChild/modules/reducer';
import ChildWatchNowReducer from '../components/Child/ChildDashboard/ChildLessonWatchNow/modules/reducer';
import ChildCourseQuizzesReducer from '../components/Child/ChildDashboard/ChildCourseQuizzes/modules/reducer';
import userPaymentReducer from './userPayment';
import customLoaderReducer from './customLoader';
import CourseReducer from '../components/Course/modules/reducer';
import ErrorNotification from './errorNotification';

export default combineReducers({
  AuthReducer,
  RegisterReducer,
  AddChildReducer,
  ChildCourseQuizzesReducer,
  ParentReducer,
  PaymentReducer,
  CoursesReducer,
  ChildToDoReducer,
  EnrollChildReducer,
  ChildLessonReducer,
  ChildWatchNowReducer,
  QuizReducer,
  customLoaderReducer,
  userPaymentReducer,
  CourseReducer,
  ErrorNotification,
});
