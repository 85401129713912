import React, { useState, useEffect, useMemo } from 'react';
import LessonIcon from '../../../../images/courses/video.svg';
import LessonIconLarge from '../../../../images/courses/videoLarge.svg';
import classNames from 'classnames'
import DoneIcon from '../../../../images/courses/DoneIcon.svg';
import ClockIcon from '../../../../images/courses/clock.svg';
import Padlock from '../../../../images/lesson/padlock.svg';
import TodoIcon from '../../../../images/courses/TodoIcon.svg';
import StopWatch from '../../../../images/courses/stopwatch.png';
import NoThumbnail from '../../../../images/child/no-thumbnail.png';
import PlayIcon from '../../../../images/courses/playIcon.svg';
import { formatDuration } from '../../../../utils/helpers';

const LessonItem = ({ item, expand, accountType, expandItem, startLesson, upcomingStatus }) => {
  const [lessonDuration, setLessonDuration] = useState(0);
  const doneCondition = item?.watchedLessonId?.length > 0;

  const lessonStatus = useMemo(() => {
    if (upcomingStatus) {
      return { label: 'Upcoming', icon: StopWatch }
    }
    if (doneCondition) {
      return { label: 'Done', icon: DoneIcon }
    }
    return { label: 'To-Do', icon: TodoIcon }
  }, [doneCondition, upcomingStatus])

  const informationMapping = useMemo(() => {
    const informationContent = [
      {
        id: 1,
        label: 'Material',
        value: 'Video',
        icon: LessonIconLarge,
      },
      {
        id: 2,
        label: 'Length',
        value: formatDuration(lessonDuration),
        icon: ClockIcon,
      },
      {
        id: 3,
        label: 'Status',
        value: lessonStatus.label,
        icon: lessonStatus.icon,
      },
    ];

    return informationContent.map(element => {
      return (
        <div key={element.id} className="information-item-wrapper">
          <img src={element.icon} alt="card-item-icon" className='status-icon'/>
          <div className="item-wrapper black">
            <span className="label">{element.label}</span>
            <span className="value">{element.value}</span>
          </div>
        </div>
      );
    });
  }, [lessonDuration, lessonStatus]);

  useEffect(() => {
    item.videos.forEach(video => setLessonDuration(prev => prev + video.duration));
  }, [item]);

  return (
    <div className='lesson-item-wrapper'>
    {item.lock && <div class="lesson-item-overlay">
      <img src={Padlock} alt='padlock'/>
    </div>}
      <div key={item?.id} className={classNames({
        'week-overview-lesson-item': true,
        "lesson-locked": item.lock,
      })} onClick={!item.lock && expandItem(item.id)}>
      {expand ? (
        <div className="expanded-lesson-item">
          <div className="image-part" onClick={accountType === 'CHILD' && startLesson(item)}>
            <img
              alt="lesson-thumbnail"
              className="lesson-thumbnail-expanded"
              src={item?.lessonImages?.[0]?.url || NoThumbnail} />
            {accountType === 'CHILD' && (
              <img className="play-image" src={PlayIcon} alt="play-button" />
            )}
          </div>
          <div className="content-part">
            <div className="status-part">{informationMapping}</div>
            <hr className="line" />
            <div className="description">
              <span>{item?.description}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="minimized-lesson">
          <div className="content-part">
            <div className="icon-container">
              <img alt="video-icon" src={LessonIcon} />
              <span className="title">{item?.title}</span>
            </div>
            <span className="description">{item?.description}</span>
          </div>
          <div className="content-part-status">
            <div className="icon-container">
              <img alt="status-icon" className='status-icon' src={lessonStatus.icon} />
              <span className="status">{lessonStatus.label}</span>
            </div>
            <img
              alt="lesson-thumbnail"
              className="lesson-thumbnail"
              src={item?.lessonImages?.[0]?.url || NoThumbnail} />
          </div>
        </div>
      )}
    </div>
  </div>
  );
};

export default LessonItem;
