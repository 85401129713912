/* eslint-disable import/no-anonymous-default-export */
import { t } from './actions';
const defaultState = {
  lesson_activity: null,
  activities: [],
  hasMoreActivities: true,
  activityType: '',
  courses: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_LESSON_ACTIVITY:
      return {
        ...state,
        lesson_activity: action.activity,
        loading_activity: false,
      };
    case t.GET_LESSON_ACTIVITY:
      return {
        ...state,
        lesson_activity: null,
        loading_activity: true,
      };
    case t.SET_ACTIVITIES:
      return {
        ...state,
        activities: action.payload.activities,
      };
    case t.SET_COURSES_OVERVIEW:
      return {
        ...state,
        courses: action.payload.courses,
      };
    case t.ADD_ACTIVITIES:
      return {
        ...state,
        activities: [...state.activities, ...action.payload.activities],
      };
    case t.SET_HAS_MORE_ACTIVITIES:
      return {
        ...state,
        hasMoreActivities: action.payload.hasMore,
      };
    case t.SET_ACTIVITY_TYPE:
      return {
        ...state,
        activityType: action.payload.activityType,
      };
    default:
      return state;
  }
};
