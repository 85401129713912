/* eslint-disable import/no-anonymous-default-export */
import { t } from './actions';
const defaultState = {
  coursesList: null,
  weeksList: null,
  weekOverview: null,
  childProfile: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_COURSES_LIST:
      return {
        ...state,
        coursesList: action.payload.data,
      };
    case t.SET_WEEKS_LIST:
      return {
        ...state,
        weeksList: action.payload.data,
      };
    case t.SET_WEEK_OVERVIEW:
      return {
        ...state,
        weekOverview: action.payload.data,
      };
    case t.SET_CHILD_PROFILE_ACTION:
      return {
        ...state,
        childProfile: action.payload.data,
      };
    default:
      return state;
  }
};
