import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { LeftOutlined } from '@ant-design/icons';

import CourseItem from './CourseItem';

import './childToDo.scss';

const ChildToDo = props => {
  const { actions, child, courses } = props;
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    actions.getCurrentChild(id);
    actions.getCoursesOverviewForChild(id);
    return () => {
      actions.setCurrentChild(null);
    };
  }, []);

  return (
    <div className="background">
      <div className="container pt-4 pb-4">
        <NavLink to="/parent-profile" className="navigation-text-color">
          <LeftOutlined className="arrow-back" />
          Back
        </NavLink>

        {child ? (
          <div className="courses-overview-title">Courses for {child.firstName}</div>
        ) : (
          <div className="courses-overview-title">Courses for Child</div>
        )}

        {courses && courses.length > 0 ? (
          courses.map((course, index) => <CourseItem key={index} index={index} course={course} />)
        ) : (
          <div className="suggested-by-kutuby">This child is not enroled in any courses yet.</div>
        )}
      </div>
    </div>
  );
};

export default ChildToDo;
