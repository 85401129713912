export const t = {
  SET_NEW_ERROR_NOTIFICATION: 'SET_NEW_ERROR_NOTIFICATION',
  REMOVE_ALL_ERROR_NOTIFICATION: 'REMOVE_ALL_ERROR_NOTIFICATION',
  REMOVE_SINGLE_ERROR_NOTIFICATION: 'REMOVE_SINGLE_ERROR_NOTIFICATION',
};

export const setNewErrorNotification = notification => ({
  type: t.SET_NEW_ERROR_NOTIFICATION,
  payload: {
    notification,
  },
});

export const removeAllErrorNotifications = () => ({
  type: t.REMOVE_ALL_ERROR_NOTIFICATION,
});

export const removeSingleErrorNotification = notification => ({
  type: t.REMOVE_SINGLE_ERROR_NOTIFICATION,
  payload: {
    notification,
  },
});
