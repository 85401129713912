import React from 'react';
import { Switch, withRouter, Redirect, Route } from 'react-router-dom';

import { AuthRoute, LoginDashboardRoute } from './routing';
import Layout from './components/Layout';

import Register from './components/Register';
import Login from './components/Login';
import VerifyEmail from './components/Register/VerifyEmail';
import ResetPassword from './components/ResetPassword';
import LoginDashboard from './components/Login/components/LoginDashboard/index';
import SuccessEmailSendScreen from './components/ResetPassword/components/SuccessEmailSendScreen/index';
import SuccessfullyEnrolled from './components/Parent/SuccessfullyEnrolled';
import ChildAdded from './components/Parent/AddChild/ChildAdded';
import RegistrationConfirmed from './components/Register/VerifyEmail/RegistrationConfirmed';
import DeletedUser from './components/Parent/ParentSettings/Settings/components/DeletedUser';

const AppContainer = ({ location }) => (
  <div className="app-wrapper">
    <Switch location={location}>
      <AuthRoute exact path="/">
        <Redirect to="/login" />
      </AuthRoute>
      <AuthRoute path="/verify-email" exact component={VerifyEmail} />
      <AuthRoute path="/reset-password" exact component={ResetPassword} />
      <Route
        path="/success-email-send-reset-password"
        exact
        render={() => (
          <SuccessEmailSendScreen
            title="Reset email has been sent to you"
            subtitle="Please follow the link in the email sent to you to reset your password"
          />
        )}
      />
      <Route
        path="/registration-success"
        exact
        render={() => <SuccessEmailSendScreen title="Email verification link sent on your email" />}
      />
      <AuthRoute path="/register" exact component={Register} />
      <AuthRoute path="/login" exact component={Login} />
      <Route exact path="/child-added" component={ChildAdded}></Route>
      <Route exact path="/successfully-enrolled" component={SuccessfullyEnrolled}></Route>
      <Route exact path="/registration-confirmed" component={RegistrationConfirmed}></Route>
      <Route exact path="/deleted-user" component={DeletedUser}></Route>
      <LoginDashboardRoute path="/login-dashboard" exact component={LoginDashboard} />
      <Layout location={location} />
    </Switch>
  </div>
);

export default withRouter(AppContainer);
