import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga4';

import CustomLoader from './components/CustomLoader';
import store from './store';
import history from './history';
import AppContainer from './AppContainer';
import WebSocketProvider from './utils/webSocket';
import ScrollToTop from './components/Landing/ScrollToTop';
import ErrorHandler from './components/ErrorHandler';
import MobileView from './components/MobileView';
import ErrorNotification from './components/ErrorNotifications';
import OneSignal from 'react-onesignal';
import './App.css';

function App() {

  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);


   async function runOneSignal() {
     await OneSignal.init({ appId: '18a74d65-d65b-4836-bc80-938be9429bc3', serviceWorkerPath: "../public/OneSignalSDKWorker.js", allowLocalhostAsSecureOrigin: true });
    OneSignal.Slidedown.promptPush();
  }

  useEffect(() => {
    runOneSignal()
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);

  const appContent = isMobile ? (
    <MobileView />
  ) : (
    <>
      <WebSocketProvider>
        <AppContainer />
      </WebSocketProvider>
      <CustomLoader />
      <ErrorNotification />
      <ScrollToTop />
    </>
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <Provider store={store}>
        <Router history={history}>{appContent}</Router>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
