/* eslint-disable import/no-anonymous-default-export */
import { all, put, call, spawn } from 'redux-saga/effects';
import authSagas from './auth';
import parentSaga from '../components/Parent/ParentProfile/modules/saga';
import lessonQuizSaga from '../components/Child/ChildDashboard/ChildLessonWatchNow/modules/saga';
import coursesSaga from '../components/Child/Courses/modules/saga';
import childSaga from '../components/Parent/AddChild/modules/saga';
import childToDoSaga from '../components/Parent/ChildToDo/modules/saga';
import childActivitiesSaga from '../components/Child/ChildDashboard/modules/saga';
import quizSaga from '../components/Child/Quiz/modules/saga';
import enrollChildSaga from '../components/Parent/EnrollChild/modules/saga';
import courseOverviewSaga from '../components/Parent/CourseOverview/modules/saga';
import courseQuizzesSaga from '../components/Child/ChildDashboard/ChildCourseQuizzes/modules/saga';
import paymentSaga from '../components/Payment/modules/saga';
import userPaymentSaga from './userPayment';
import { setLayoutMode, setSavedAccounts } from '../actions/auth';
import courseSaga from '../components/Course/modules/saga';

const sagas = [
  authSagas,
  parentSaga,
  lessonQuizSaga,
  courseQuizzesSaga,
  coursesSaga,
  childSaga,
  childToDoSaga,
  enrollChildSaga,
  childActivitiesSaga,
  quizSaga,
  courseOverviewSaga,
  paymentSaga,
  userPaymentSaga,
  courseSaga,
];

function* loadLayoutMode() {
  const layoutMode = localStorage.getItem('layoutMode');
  yield put(setLayoutMode(layoutMode));
}
export function* setSavedAccountsSaga() {
  if (localStorage.getItem('saved-accounts')) {
    yield put(setSavedAccounts(JSON.parse(localStorage.getItem('saved-accounts'))));
  }
}

export default function* () {
  yield call(loadLayoutMode);
  yield call(setSavedAccountsSaga);
  yield all(sagas.map(spawn));
}
