import { all, call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import { t, setChildren, setCurrentChild, setParent, setChildAndActivities } from './actions';
import {
  getParentChildren,
  getChildProfile,
  getParentData,
  getParentChildrenAndActivities,
} from '../../../../services/parentService';
import { setIsLoading } from '../../../../actions/customLoader';
import { setNewErrorNotification } from '../../../../actions/errorNotification';
import { generalErrorMessage } from '../../../../utils/constants';

function* getChildren() {
  yield put(setIsLoading(true));
  const { data, error } = yield call(getParentChildren);
  yield put(setIsLoading(false));
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setChildren(data));
  }
}

function* getChildAndActivitiesSaga(action) {
  const { id } = action.payload;
  yield put(setIsLoading(true));
  const { data, error } = yield call(getParentChildrenAndActivities, id);
  yield put(setIsLoading(false));
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setChildAndActivities(data));
  }
}

function* getParent() {
  yield put(setIsLoading(true));
  const { data, error } = yield call(getParentData);
  yield put(setIsLoading(false));

  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setParent(data));
  }
}

function* getCurrentChild(action) {
  const { id } = action.payload;
  yield put(setIsLoading(true));
  const { data, error } = yield call(getChildProfile, id);
  yield put(setIsLoading(false));
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setCurrentChild(data));
  }
}

export default function* parentSaga() {
  yield all([
    takeLatest(t.GET_CHILDREN, getChildren),
    takeLatest(t.GET_CURRENT_CHILD, getCurrentChild),
    takeLatest(t.GET_PARENT, getParent),
    takeLatest(t.GET_CHILD_AND_ACTIVITIES, getChildAndActivitiesSaga),
  ]);
}
