import { t } from './types';

export const setAnswers = answers => ({
  type: t.SET_ANSWERS,
  payload: {
    answers,
  },
});

export const setText = text => ({
  type: t.SET_TEXT,
  payload: {
    text,
  },
});

export const setCoins = coins => ({
  type: t.SET_COINS,
  payload: {
    coins,
  },
});

export const setQuestionNum = questionNum => ({
  type: t.SET_QUESTION_NUM,
  payload: {
    questionNum,
  },
});

export const setAnswerAttempts = answerAttempts => ({
  type: t.SET_ANSWERS_ATTEMPTS,
  payload: {
    answerAttempts,
  },
});

export const resetAnswerAttempts = () => ({
  type: t.RESET_ANSWERS_ATTEMPTS,
});

export const setFinalScreen = finalScreen => ({
  type: t.SET_FINAL_SCREEN,
  payload: {
    finalScreen,
  },
});

export const dragAndDropController = data => ({
  type: t.DRAG_AND_DROP_CONTROLLER,
  payload: {
    data,
  },
});

export const dragAndDropConfirmAnswer = data => ({
  type: t.DRAG_AND_DROP_CONFIRM_ANSWER,
  payload: {
    data,
  },
});

export const resetState = () => ({
  type: t.RESET_STATE,
});

export const setSubmittedAnswers = data => ({
  type: t.SET_SUBMITTED_ANSWERS,
  payload: {
    data,
  },
});

export const quizController = data => ({
  type: t.QUIZ_CONTROLLER,
  payload: {
    data,
  },
});
export const multipleChoiceQuizController = data => ({
  type: t.MULTIPLE_CHOICE_QUIZ_CONTROLLER,
  payload: {
    data,
  },
});

export const setAnswer = data => ({
  type: t.SET_ANSWER,
  payload: {
    data,
  },
});

export const setActiveAnswer = data => ({
  type: t.SET_ACTIVE_ANSWER,
  payload: {
    data,
  },
});

export const setQuizOverviewData = data => ({
  type: t.SET_QUIZ_OVERVIEW_DATA,
  payload: {
    data,
  },
});
export const getQuizOverviewData = data => ({
  type: t.GET_QUIZ_OVERVIEW_DATA,
  payload: {
    data,
  },
});
export const setSelectedAnswers = data => ({
  type: t.SET_SELECTED_ANSWERS,
  payload: {
    data,
  },
});
export const setOverview = data => ({
  type: t.SET_OVERVIEW,
  payload: {
    data,
  },
});
