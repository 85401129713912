import React from 'react';
import { Form, Input } from 'antd';
import { passwordRules } from '../../../../../utils/inputRules';
import LockIcon from '../../../../../images/register/Lock.svg';
import renderPasswordIcon from '../../../../../utils/renderPasswordIcon';
import CautionIcon from '../../../../../images/settings/Artwork.svg';
import '../settings.scss';

const DeleteParentAccount = ({ deleteAccount }) => {
  return (
    <div>
      <Form className="delete-parent-account-wrapper" onFinish={deleteAccount}>
        <span className="title">Account Verified</span>
        <img src={CautionIcon} alt="caution-icon" />
        <span className="description">
          <span className="description-bold">Are you sure you want to delete your account?</span>
          <br />
          If you delete your account,
          <span className="description-bold">
            {' '}
            all your children accounts will also be deleted
          </span>{' '}
          and they will lose all their progress
        </span>
        <Form.Item className="delete-account-content-wrapper" name="password" rules={passwordRules}>
          <Input.Password
            prefix={<img className="login-input-icon" alt="lock-icon" src={LockIcon} />}
            placeholder={'Confirm your password'}
            iconRender={renderPasswordIcon}
          />
        </Form.Item>
        <Form.Item className="delete-account-content-wrapper">
          <button className="delete-account-button">Delete</button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DeleteParentAccount;
