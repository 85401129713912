import React, { useState, useMemo } from 'react';

import { Progress, Button } from 'antd';

import { isItemInArray, calculatePercentageCompleted } from '../../../../../utils/helpers';
import MultipleChoiceQuestionItem from './MultipleChoiceQuestionItem';
import ConfirmOrNextButton from '../../ChildCourseQuizzes/components/ConfirmOrNextButton';

const MultipleChoiceQuestionsScreen = ({
  questionNum,
  activeLesson,
  videoNum,
  actions,
  isAnswerCorrectMultiple,
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [nextOrFinishState, setNextOrFinishState] = useState('Next');

  const quiz = activeLesson?.videos[videoNum - 1]?.quizzes[0];

  const handleConfirmAnswer = () => {
    setConfirm(true);
    questionNum + 1 === quiz?.questions?.length
      ? setNextOrFinishState('Finish')
      : setNextOrFinishState('Next');
  };

  const handleNext = () => {
    setSelectedAnswers([]);
    isAnswerCorrectMultiple({ questionId: quiz?.questions[questionNum].id, selectedAnswers });
    actions.incrementQuestionNum();
    setConfirm(false);
  };

  const answersMapping = useMemo(() => {
    const updateSelectedAnswers = e => {
      if (isItemInArray(e.target.value, selectedAnswers)) {
        setSelectedAnswers(selectedAnswers.filter(answer => answer !== e.target.value));
      } else {
        setSelectedAnswers([...selectedAnswers, e.target.value]);
      }
    };

    return quiz?.questions[questionNum]?.answers.map((item, idx) => {
      return (
        <MultipleChoiceQuestionItem
          key={item?.id}
          item={item}
          isItemInArray={isItemInArray}
          selectedAnswers={selectedAnswers}
          updateSelectedAnswers={updateSelectedAnswers}
          confirm={confirm}
          idx={idx}
        />
      );
    });
  }, [confirm, questionNum, quiz?.questions, selectedAnswers]);

  const percentageCompleted = calculatePercentageCompleted(quiz?.questions.length, questionNum + 1);
  const disabledCondition = selectedAnswers.length < 2;

  return (
    <div className="quiz-wrapper">
      {/* Question and answer screen  */}
      <div className="question-info">
        <span className="quiz-title">{quiz?.title}</span>
        <span className="question-number">
          Question {questionNum + 1} Of
          {' ' + quiz?.questions.length}
        </span>
        <Progress percent={percentageCompleted} showInfo={false} />
        <span className="question-title">{quiz?.questions[questionNum]?.title}</span>
      </div>
      <div className="answer">
        {/* answers */}
        {answersMapping}
        <div className="answer-buttons">
          <ConfirmOrNextButton
            confirm={confirm}
            nextOrFinishState={nextOrFinishState}
            handleNext={handleNext}
            disabledCondition={disabledCondition}
            handleConfirmAnswer={handleConfirmAnswer}
          />
        </div>
      </div>
    </div>
  );
};

export default MultipleChoiceQuestionsScreen;
