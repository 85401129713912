import { t } from './types';

export const initialState = {
  quizzes: [],
  answers: [],
  text: [],
  coins: 0,
  questionNum: 0,
  answerAttempts: [],
  finalScreen: false,
  submittedAnswers: [],
  answer: null,
  activeAnswer: null,
  quizOverviewData: null,
  selectedAnswers: [],
  overview: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.SET_ALL_QUIZZES_AND_COMPLETENESS:
      return { ...state, quizzes: action.payload.quizzes };
    case t.SET_ANSWERS:
      return { ...state, answers: action.payload.answers };
    case t.SET_TEXT:
      return { ...state, text: action.payload.text };
    case t.SET_COINS:
      return { ...state, coins: action.payload.coins };
    case t.SET_QUESTION_NUM:
      return { ...state, questionNum: action.payload.questionNum };
    case t.SET_ANSWERS_ATTEMPTS:
      return { ...state, answerAttempts: [...state.answerAttempts, action.payload.answerAttempts] };
    case t.SET_FINAL_SCREEN:
      return { ...state, finalScreen: action.payload.finalScreen };
    case t.SET_QUESTION_NUM:
      return { ...state, questionNum: action.payload.questionNum };
    case t.SET_QUESTION_NUM:
      return { ...state, questionNum: action.payload.questionNum };
    case t.RESET_ANSWERS_ATTEMPTS:
      return { ...state, answerAttempts: [] };
    case t.SET_SUBMITTED_ANSWERS:
      return { ...state, submittedAnswers: action.payload.data };
    case t.SET_ANSWER:
      return { ...state, answer: action.payload.data };
    case t.SET_ACTIVE_ANSWER:
      return { ...state, activeAnswer: action.payload.data };
    case t.SET_QUIZ_OVERVIEW_DATA:
      return { ...state, quizOverviewData: action.payload.data };
    case t.SET_SELECTED_ANSWERS:
      return { ...state, selectedAnswers: action.payload.data };
    case t.SET_OVERVIEW:
      return { ...state, overview: action.payload.data };
    case t.RESET_STATE:
      return {
        quizzes: [],
        answers: [],
        text: [],
        coins: 0,
        questionNum: 0,
        answerAttempts: [],
        finalScreen: false,
        submittedAnswers: [],
        answer: null,
        activeAnswer: null,
        quizOverviewData: null,
        selectedAnswers: [],
        overview: false,
      };
    default:
      return state;
  }
};
