import React from 'react';

const ChildInfoItem = ({ icon, title, value }) => {
  return (
    <div>
      <div className="child-info-points-row">
        <img src={icon} alt="total-points-icon" />
        <div className="child-info-points-column">
          <span className="title">{title}</span>
          <span className="value">{value}</span>
        </div>
      </div>
    </div>
  );
};

export default ChildInfoItem;
