import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import ChildInfoItem from './ChildInfoItem';
import TotalPointsIcon from '../../../images/courses/total-points.svg';
import GradeIcon from '../../../images/courses/Grade.svg';
import PencilIcon from '../../../images/child/pencil-icon.svg';
import { avgGradeToLetters } from '../../../utils/helpers';

const ChildProfileHeader = ({ userData, userRole, course }) => {
  const history = useHistory();

  const pushToEditChildPage = () => {
    history.push({
      pathname: '/parent-profile/edit-child',
      state: { id: userData?.id, username: userData?.username },
    });
  };

  const genderBackground = userData?.gender === 'M' ? 'child-profile-boy' : 'child-profile-girl';
  const genderBackgroundLight =
    userData?.gender === 'M' ? 'child-profile-boy-light' : 'child-profile-girl-light';

  const coursePoints = course?.courseAttendees[0].pointsCollected || 0;
  return (
    <div>
      <div className={`child-profile-header-wrapper ${genderBackground}`}>
        <div className={`top-part ${genderBackground}`}>
          {userRole === 'PARENT' && (
            <Button onClick={pushToEditChildPage} className="child-info-wrapper-edit-profile-btn">
              <img src={PencilIcon} alt="pencil-icon" />
              Edit Profile
            </Button>
          )}
        </div>
        <div className={`bottom-part ${genderBackgroundLight}`}>
          <img
            className={`avatar ${genderBackgroundLight}`}
            src={userData?.avatar?.url}
            alt="child-avatar"
          />
          <span className="child-name">{userData?.firstName}</span>
          <span className="child-age">
            @{userData?.username} &#183; {userData?.age} yrs
          </span>
          <div className="child-info-points-container">
            <ChildInfoItem
              icon={TotalPointsIcon}
              title={'Total Points'}
              value={`${course ? coursePoints : userData?.totalPoints} pts`}
            />
            <div className="vertical-line" />
            <ChildInfoItem
              icon={GradeIcon}
              title={'Avg grade'}
              value={avgGradeToLetters(
                course ? course.courseAttendees[0].finalGrade : userData?.averageGrade
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChildProfileHeader;
