import { t } from './types';

export const initialState = {
  clientSecret: null,
  subscriptionId: null,
  plan: null,
  products: [],
  paymentLogs: null,
  isLoadingTrial: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.SET_CLIENT_SECRET:
      return { ...state, clientSecret: action.payload.data };
    case t.SET_PLAN:
      return { ...state, plan: action.payload.data };
    case t.SET_SUBSCRIPTION_ID:
      return { ...state, subscriptionId: action.payload.data };
    case t.SET_PRODUCTS:
      return { ...state, products: action.payload.data };
    case t.SET_PAYMENT_LOGS:
      return { ...state, paymentLogs: action.payload.data };
    case t.SET_IS_LOADING_TRIAL:
      return { ...state, isLoadingTrial: action.payload.data };
    default:
      return state;
  }
};
