/* eslint-disable import/no-anonymous-default-export */
import { types } from './types';

export const initialState = {
  isQuiz: false,
  openLetsQuizScreen: false,
  coins: 0,
  questionNum: 0,
  videoNum: 0,
  finalScreen: false,
  answerValue: null,
  activeAnswer: null,
  isQuizCompleted: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.IS_QUIZ:
      return { ...state, isQuiz: true };
    case types.NOT_QUIZ:
      return { ...state, isQuiz: false };
    case types.OPEN_LETS_QUIZ_SCREEN:
      return { ...state, openLetsQuizScreen: true };
    case types.CLOSE_LETS_QUIZ_SCREEN:
      return { ...state, openLetsQuizScreen: false };
    case types.COINS:
      return { ...state, coins: state.coins + 5 };
    case types.RESTART_COINS:
      return { ...state, coins: 0 };
    case types.INCREMENT_QUESION_NUM:
      return { ...state, questionNum: state.questionNum + 1 };
    case types.DECREMENT_QUESION_NUM:
      return { ...state, questionNum: state.questionNum - 1 };
    case types.RESTART_QUESION_NUM:
      return { ...state, questionNum: 0 };
    case types.INCREMENT_VIDEO_NUM:
      return { ...state, videoNum: state.videoNum + 1 };
    case types.RESTART_VIDEO_NUM:
      return { ...state, videoNum: 0 };
    case types.FINAL_SCREEN:
      return { ...state, finalScreen: true };
    case types.NOT_FINAL_SCREEN:
      return { ...state, finalScreen: false };
    case types.SET_ANSWER_VALUE:
      return {
        ...state,
        answerValue: action.payload.answer,
      };
    case types.SET_ACTIVE_ANSWER_VALUE:
      return {
        ...state,
        activeAnswer: action.payload.idx,
      };
    case types.SET_QUIZ_BY_ID_IS_COMPLETED:
      return { ...state, isQuizCompleted: action.payload.isQuizCompleted };
    case types.RESET_STATE:
      return {
        isQuiz: false,
        openLetsQuizScreen: false,
        coins: 0,
        questionNum: 0,
        videoNum: 0,
        finalScreen: false,
        answerValue: null,
        activeAnswer: null,
        isQuizCompleted: false,
      };
    default:
      return state;
  }
};
