import React from 'react';
import { useHistory } from 'react-router-dom';
import { stringLength } from '../../utils/helpers';
import NoThumbnail from '../../images/child/no-thumbnail.png';
import { getCurrentWeek } from '../../utils/helpers';
import OnTrackIcon from '../../images/courses/on-track.svg';
import InProgressIcon from '../../images/courses/in-progress.svg';
import { avgGradeToLetters } from '../../utils/helpers';
import '../Parent/EnrollChild/enrollChild.scss';

const CourseCard = ({ course, progressStatus, courseAttendees, pathname, childId, isEnrolled }) => {
  const history = useHistory();
  const ProgressIcon = () => {
    if (progressStatus === 'On Track') {
      return <img src={OnTrackIcon} />;
    }
    return <img src={InProgressIcon} />;
  };
  const overviewRedirect = () => {
    history.push({
      pathname: pathname,
      state: {
        courseName: course.title,
        courseId: course.id,
        childId: childId,
        isEnrolled: isEnrolled,
        course: course,
      },
    });
  };
  const freeCourseIcon = course.free ? (
    <div className="free-course-icon">BASIC</div>
  ) : (
    <div className="pro-course-icon">PLUS</div>
  );
  const courseDetails = isEnrolled ? (
    <div className="enroll-course-card-row">
      <p className="enroll-course-points">Points</p>
      <p className="enroll-course-points-value">
        {courseAttendees?.pointsCollected || '0'}/{course?.coursePoints}
      </p>
      <p className="enroll-course-points">Grade</p>
      <p className="enroll-course-points-value">{avgGradeToLetters(courseAttendees?.finalGrade)}</p>
    </div>
  ) : (
    <div className="enroll-course-card-row">
      <p className="enroll-course-points">Age</p>
      <p className="enroll-course-points-value">5+</p>
      <p className="enroll-course-points">Duration</p>
      <p className="enroll-course-points-value">{course?.totalWeeks} weeks</p>
    </div>
  );

  return (
    <div role="button" onClick={overviewRedirect} className="enroll-course-card-wrapper">
      <div className="enroll-course-card-column">
        <img
          src={course.courseImagesLandscape[0] ? course.courseImagesLandscape[0].url : NoThumbnail}
          className="enroll-course-img"
          alt="course-thumbnail"
        />
        <p className="enroll-course-card-title">
          {stringLength(course?.title, 25)}
          {freeCourseIcon}
        </p>
      </div>
      <div className="enroll-course-card-column">
        {progressStatus && (
          <div className="enroll-course-card-row status-row">
            <p className="enroll-course-points-value current-week">
              Week {getCurrentWeek(courseAttendees.from)}/{course?.totalWeeks}
            </p>
            <p className="enroll-course-points-value">
              <ProgressIcon />
            </p>
          </div>
        )}
        <p className="enroll-course-desc">{stringLength(course?.description, 100)}</p>
        {courseDetails}
      </div>
    </div>
  );
};

export default CourseCard;
