import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { stringLength } from '../../../../utils/helpers';
import CheckIcon from '../../../../images/child/Check circle.svg';
import WarningIcon from '../../../../images/child/Warning.svg';
import '../parentProfile.scss';

const ChildrenListItem = props => {
  const { child, setChosenChild } = props;
  const history = useHistory();
  const noOfInProgressCourses = child?.coursesInProgress?.length;
  const noOfCompletedCourses = child?.completedCourses?.length;
  const pendingTasksCondition = child?.coursesInProgress.some(item => item.status === 'Pending');
  const completedCondition = noOfInProgressCourses === 0 && noOfCompletedCourses > 0;

  const childStatus = useMemo(() => {
    if (noOfCompletedCourses === 0 && noOfInProgressCourses === 0) {
      return { status: 'Not enrolled', icon: WarningIcon };
    }
    if (completedCondition) {
      return { status: 'Completed', icon: CheckIcon };
    }
    if (pendingTasksCondition) {
      return { status: 'Pending tasks', icon: WarningIcon };
    }
    if (!pendingTasksCondition) {
      return { status: 'On track', icon: CheckIcon };
    }
  }, [noOfInProgressCourses, noOfCompletedCourses, completedCondition, pendingTasksCondition]);

  const pushToEnroll = () => {
    setChosenChild(child.child.id);

    if (child.availableCourses.length === 1) {
      history.push({
        pathname: '/parent-profile/course-overview',
        state: {
          courseId: child.availableCourses[0].id,
          childId: child.child.id,
        },
      });
    } else {
      history.push({
        pathname: '/parent-profile/enroll-child',
        state: { childName: child.child.firstName },
      });
    }
  };

  const pushToChildProfile = () => {
    history.push({
      pathname: `/parent-profile/child-profile/${child.child.id}`,
      state: { childId: child.child.id, username: child.child.username },
    });
  };
  const firstName = stringLength(child?.child?.firstName, 13);

  return (
    <div
      className={classNames({
        'children-list-item-wrapper': true,
        'children-list-item-wrapper-blue': childStatus.status === 'Pending tasks',
        'children-list-item-wrapper-purple': childStatus.status === 'Completed',
        'children-list-item-wrapper-gray': childStatus.status === 'Not enrolled',
        'children-list-item-wrapper-green': childStatus.status === 'On track',
      })}
    >
      <div onClick={pushToChildProfile} className="children-list-item-avatar-wrapper">
        <img alt="avatar" className="avatar-child-profile" src={child?.child?.avatar?.url} />
      </div>
      <p className="children-list-item-name">{firstName}</p>
      <p className="children-list-item-status">
        <img src={childStatus.icon} />
        {childStatus.status}
      </p>
      <span className="children-list-item-age">{child?.child?.age}</span>
      <div className="children-list-item-navlink">
        <button onClick={pushToEnroll} type="button" className="enrol-button">
          Enrol
        </button>
      </div>
    </div>
  );
};

export default ChildrenListItem;
