import React from 'react';
import { UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import BorderIcons from './BorderIcons';

const WeekItem = ({ item, currentWeek, onWeekClick }) => {
  let weekItemColor = {
    class: 'black',
    hex: '#0B3A49',
  };

  if (item.status === 'Pending' && !(item?.week?.toString() > currentWeek?.toString())) {
    weekItemColor = {
      class: 'orange',
      hex: '#fbb628',
    };
  }
  if (item.status === 'On Track') {
    weekItemColor = {
      class: 'blue',
      hex: '#1E9AC2',
    };
  }
  if (item?.week?.toString() > currentWeek?.toString()) {
    weekItemColor = {
      class: 'black',
      hex: '#0B3A49',
    };
  }

  const courseStatus = item => {
    if (item.week > currentWeek) {
      return item.availableOnDate;
    }
    if (item.status === 'Pending') {
      return 'To-do !';
    }
    return 'Done';
  };

  return (
    <div className="week-item-wrapper">
      <div className="week-info">
        <div className={`week-status ${weekItemColor.class}-status`}>
          <BorderIcons color={weekItemColor?.hex} iconClass={'border-left'} />
          <span>{courseStatus(item)}</span>
          <BorderIcons color={weekItemColor?.hex} iconClass={'border-right'} />
        </div>
      </div>
      <Button
        onClick={onWeekClick(item?.week)}
        className={`week-title-wrapper ${weekItemColor.class}`}
      >
        <span>Week {item?.week}</span>
        <UpOutlined />
      </Button>
    </div>
  );
};

export default WeekItem;
