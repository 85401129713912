import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  createSubscription,
  getProducts,
  cancelSubscription,
  setClientSecret,
  getPaymentLogs,
  createTrialSubscription,
  createSetupIntents,
} from './modules/actions';
import { getUserPayment, createUserPayment } from '../../actions/userPayment';

const mapStateToProps = state => ({
  clientSecret: state.PaymentReducer.clientSecret,
  subscriptionId: state.PaymentReducer.subscriptionId,
  plan: state.PaymentReducer.plan,
  products: state.PaymentReducer.products,
  userPaymentStatus: state.userPaymentReducer.userPaymentStatus,
  paymentLogs: state.PaymentReducer.paymentLogs,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createSubscription,
      getProducts,
      getUserPayment,
      cancelSubscription,
      setClientSecret,
      getPaymentLogs,
      createUserPayment,
      createTrialSubscription,
      createSetupIntents,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
