import { all, call, takeLeading, put } from 'redux-saga/effects';
import { notification } from 'antd';
import { types } from './types';

import { updateChildPoints } from '../../../../../services/childService';
import { getQuizById, postAnswersQuizService } from '../../../../../services/quizService';
import { lessonWatched } from '../../../../../services/lessonService';
import {
  setQuizById,
  setActiveAnswer,
  setAnswerValue,
  notQuiz,
  finalScreenAction,
  restartQuestionNum,
  restartVideonNum,
  isQuizAction,
  openLetsQuizScreen,
  closeLetsQuizScreen,
  setCoins,
  updatePoints,
  postAnswerQuiz,
  restartCoins,
  notFinalScreen,
  resetState,
} from './actions';
import { setLessonModal, setWatchNowModal } from '../../modules/actions';
import { eventTrack, events } from '../../../../../utils/googleAnalyticsEventTrack';
import { setNewErrorNotification } from '../../../../../actions/errorNotification';
import { generalErrorMessage } from '../../../../../utils/constants';

function* updateChildPointsSaga(action) {
  try {
    yield call(updateChildPoints, action.payload.data);

    notification.success({
      message: 'Course points collected successfully!',
    });
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    yield put(
      setNewErrorNotification({
        title: 'Course points collected occurred error!',
        description: message,
      })
    );
  }
}

function* getQuizByIdSaga(action) {
  try {
    const { type, id } = action.payload;
    const res = yield call(getQuizById, type, id);

    const { data, error } = res;

    if (data) {
      yield put(setQuizById(data?.isCompleted));
    }
  } catch (error) {
    yield put(setQuizById(false));
    const {
      response: {
        data: { message },
      },
    } = error;
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: message,
      })
    );
  }
}

function* postAnswersQuizServiceSaga(action) {
  try {
    yield call(postAnswersQuizService, action.payload.data);
    const res = yield call(postAnswersQuizService, action.payload.data);
    const { error } = res;
    if (error) {
      const {
        response: {
          data: { message },
        },
      } = error;
      yield put(
        setNewErrorNotification({
          title: generalErrorMessage,
          description: message,
        })
      );
    }
  } catch (error) {
    return error;
  }
}

function* lessonWatchedSaga(action) {
  try {
    yield call(lessonWatched, action.payload.data);
    eventTrack(events.CHILD_EVENTS_CATEGORY, events.CHILD_EVENTS.WATCHED_VIDEO);
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: message,
      })
    );
  }
}
function* updatePointsAndSaveAnswers(data) {
  const { videoNum, activeLesson, answerAttempts, decoded, coins, setAnswersAttemptsOnEmpty } =
    data;
  if (activeLesson?.videos[videoNum - 1]?.quizzes[0]) {
    yield put(
      postAnswerQuiz({
        quizId: activeLesson?.videos[videoNum - 1]?.quizzes[0]?.id,
        answerAttempts: answerAttempts,
      })
    );

    setAnswersAttemptsOnEmpty();

    yield put(
      updatePoints({
        userId: decoded.user.id,
        courseId: activeLesson.courseId,
        points: coins,
      })
    );

    yield put(restartCoins());
  }
}

function* watchNowControllerSaga(action) {
  const { questionNum, videoNum, activeLesson, finalScreen, isQuizCompleted } = action.payload.data;

  const currentVideo = activeLesson?.videos[videoNum];
  const currentQuestion = activeLesson?.videos[videoNum - 1]?.quizzes[0]?.questions[questionNum];

  const lessonCompleted = [
    !currentVideo && !currentQuestion,
    isQuizCompleted &&
      !activeLesson?.videos[videoNum]?.quizzes[0]?.questions[questionNum] &&
      !currentVideo,
  ].some(Boolean);

  if (!!activeLesson && !finalScreen) {
    // If we have no more videos and no more questions
    if (lessonCompleted) {
      if (isQuizCompleted) {
        yield put(resetState());
        yield put(setWatchNowModal());
      } else {
        yield put(finalScreenAction());
        yield put(notQuiz());
        yield put(restartQuestionNum());
        yield put(restartVideonNum());
        yield put(setLessonModal());

        yield* updatePointsAndSaveAnswers(action.payload.data);
      }
      // If we have no more questions for that quiz
    } else if (!currentQuestion) {
      yield put(restartQuestionNum());
      yield put(notQuiz());

      yield* updatePointsAndSaveAnswers(action.payload.data);
    }
  }
}

function* setActiveAnswerSaga(action) {
  yield put(setActiveAnswer(action.payload.idx));
}
function* notFinalScreenSaga() {
  yield put(notFinalScreen());
}
function* setAnswerValueSaga(action) {
  yield put(setAnswerValue(action.payload.answer));
}
function* incrementQuestionNumSaga() {
  yield put(setActiveAnswer());
}
function* decrementQuestionNumSaga() {
  yield put(setAnswerValue());
}
function* isQuizSaga() {
  yield put(isQuizAction());
}
function* openLetsQuizScreenSaga() {
  yield put(openLetsQuizScreen());
}
function* closeLetsQuizScreenSaga() {
  yield put(closeLetsQuizScreen());
}
function* setCoinsSaga() {
  yield put(setCoins());
}

export default function* lessonQuizSaga() {
  yield all([
    takeLeading(types.UPDATE_POINTS, updateChildPointsSaga),
    takeLeading(types.GET_QUIZ_BY_ID_IS_COMPLETED, getQuizByIdSaga),
    takeLeading(types.POST_ANSWER_QUIZ, postAnswersQuizServiceSaga),
    takeLeading(types.POST_LESSON_WATCHED, lessonWatchedSaga),
    takeLeading(types.SET_ACTIVE_ANSWER_VALUE, setActiveAnswerSaga),
    takeLeading(types.SET_ANSWER_VALUE, setAnswerValueSaga),
    takeLeading(types.INCREMENT_QUESION_NUM, incrementQuestionNumSaga),
    takeLeading(types.DECREMENT_QUESION_NUM, decrementQuestionNumSaga),
    takeLeading(types.WATCH_NOW_CONTROLLER, watchNowControllerSaga),
    takeLeading(types.IS_QUIZ, isQuizSaga),
    takeLeading(types.OPEN_LETS_QUIZ_SCREEN, openLetsQuizScreenSaga),
    takeLeading(types.CLOSE_LETS_QUIZ_SCREEN, closeLetsQuizScreenSaga),
    takeLeading(types.COINS, setCoinsSaga),
    takeLeading(types.NOT_FINAL_SCREEN, notFinalScreenSaga),
  ]);
}
