import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, removeSavedAccount, getSavedAccounts } from '../../actions/auth';
import { getUserPayment } from '../../actions/userPayment';
import { getChildren } from '../Parent/ParentProfile/modules/actions';

const mapStateToProps = state => ({
  children: state.ParentReducer.children,
  layoutMode: state.AuthReducer.layoutMode,
  savedAccounts: state.AuthReducer.savedAccounts,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      login,
      getUserPayment,
      getChildren,
      removeSavedAccount,
      getSavedAccounts,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
