import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { stringLength } from '../../../../utils/helpers';
import Lightbulb from '../../../../images/courses/lightbulbsm.svg';
import QuizPoints from '../../../Parent/CourseOverview/CourseLessonsItem/QuizPoints';
import ProgressStatusTitle from '../../../Parent/CourseOverview/CourseLessonsItem/ProgressStatusTitle';
import NoThumbnail from '../../../../images/child/no-thumbnail.png';
import CheckMark from '../../../../images/courses/Checkmark.svg';
import Warning from '../../../../images/courses/Warning.svg';

const WeekBlockItem = ({
  weekItem,
  isEnrolled,
  course,
  openWatchNowModal,
  openQuizModal,
  actions,
}) => {
  const courseWeekCondition = course?.currentWeek >= weekItem.week;
  const quizOverview = quiz => {
    openQuizModal(quiz)();
    actions.setFinalScreen(true);
    actions.setOverview(true);
  };
  const quizButtonHandler = quiz => () => {
    if (quiz.isCompleted && courseWeekCondition) {
      quizOverview(quiz);
    }
    if (!quiz.isCompleted && courseWeekCondition) {
      openQuizModal(quiz)();
    }
  };
  const lessonMapping = useMemo(
    () =>
      weekItem.lessons.map(lesson => (
        <div
          onClick={courseWeekCondition && openWatchNowModal(lesson)}
          className="lesson-item-wrapper"
          key={lesson.id}
        >
          <img
            className="lesson-image"
            src={lesson.lessonImages[0] ? lesson.lessonImages[0].url : NoThumbnail}
            alt="no-thumbnail"
          />
          <div>
            <p className="title">{lesson.title}</p>
            <p className="description">{stringLength(lesson.description, 60)}</p>
          </div>
        </div>
      )),
    [weekItem.lessons, openWatchNowModal, courseWeekCondition]
  );

  const quizzesMapping = useMemo(
    () =>
      weekItem.quizzes.map(quiz => (
        <Button onClick={quizButtonHandler(quiz)} className="quiz-item-button">
          <img src={Lightbulb} />
          <div className="points-part">
            <div>
              <p className="title">{quiz.title}</p>
              <QuizPoints quiz={quiz} />
            </div>
          </div>
          <div className="quiz-status">
            <img src={quiz.userQuizzes?.length ? CheckMark : Warning} />
            <span>{quiz.userQuizzes?.length ? 'Completed' : 'Pending'}</span>
          </div>
        </Button>
      )),
    [weekItem.quizzes, quizButtonHandler]
  );

  const weekWithoutContent = weekItem.lessons.length > 0 || weekItem.quizzes.length > 0;

  const weekWithoutContentCondition = weekWithoutContent ? (
    <div
      className={classNames({
        'course-overview-lessons-column': true,
        'course-overview-lessons-column-disabled':
          course?.currentWeek > weekItem.week || course?.currentWeek < weekItem.week,
      })}
    >
      {lessonMapping}
      {quizzesMapping}
    </div>
  ) : (
    <span className="course-overview-no-content">This week doesn't have content</span>
  );

  return (
    <div>
      <ProgressStatusTitle lessonItem={weekItem} course={course} />
      <div className="course-overview-lessons">
        <div className="week-headline" key={weekItem.week}>
          <span className="week-title">WEEK</span>
          <span className="week-value">{weekItem.week}</span>
        </div>
        {weekWithoutContentCondition}
      </div>
      <br />
    </div>
  );
};

export default WeekBlockItem;
