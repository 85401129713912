import React, { createContext } from 'react';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';
import { getLessonById } from '../components/Child/ChildDashboard/modules/actions';
import { setQuizById } from '../components/Child/Quiz/modules/actions';
import config from '../config';

const WebSocketContext = createContext(null);

export { WebSocketContext };

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  let socket;
  const dispatch = useDispatch();

  const disconnect = () => {
    socket.removeAllListeners();
    socket.disconnect();
  };

  const connect = lessonId => {
    if (socket) {
      socket.removeAllListeners();
      if (socket.connected) {
        disconnect();
      }
    }
    socket = io(config.socketUrl, {
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem('child_profile_token')}`,
      },
      autoConnect: false,
    });
    socket.auth = { lessonId };
    socket.connect();

    socket.on('connect_error', err => {
      // eslint-disable-next-line no-console
      console.log('Connection error', err);
    });

    socket.on('lesson start', data => {
      const {
        lesson: { id },
      } = data;
      dispatch(getLessonById(id));
    });

    socket.on('quiz start', data => {
      const { quiz } = data;
      dispatch(setQuizById(quiz));
    });

    socket.on('quiz end', data => {
      console.log(data);
    });
  };

  const ws = {
    socket,
    connect,
    disconnect,
  };

  return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>;
};
