import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPaymentLogs } from '../../modules/actions';

const mapStateToProps = state => ({
  paymentLogs: state.PaymentReducer.paymentLogs,
  isLoading: state.PaymentReducer.isLoadingTrial,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPaymentLogs,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
