import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import classNames from 'classnames';
import ChildProfileHeader from '../ChildProfile/components/ChildProfileHeader';
import CourseSection from './components/items/CourseSection';
import PausedCourseModal from './components/items/PausedCourseModal';
import './style.scss';

const View = ({ actions, coursesList, childProfile, userRole, watchNowModal, quizModal }) => {
  const [uncompletedCondition, setUncompletedCondition] = useState(false);
  const [pausedModalVisibility, setPausedModalVisibility] = useState(false);
  const { childId } = useParams();

  useEffect(() => {
    actions.getChildProfileAction(childId, userRole);
  }, []);

  useEffect(() => {
    if (coursesList?.inProgressCourses?.some(item => item?.status === 'Pending')) {
      setUncompletedCondition(true);
    } else {
      setUncompletedCondition(false);
    }
  }, [coursesList]);

  useEffect(() => {
    actions.setActiveLesson(null);
    actions.setActiveQuiz(null);
    actions.resetState();
    if (watchNowModal) {
      actions.setWatchNowModal();
    }
    if (quizModal) {
      actions.setQuizModal();
    }
  }, []);

  const correctChildId = userRole === 'PARENT' ? childId : childProfile?.id;

  return (
    <div
      className={classNames({
        background: true,
        'male-background': childProfile?.gender === 'M',
        'female-background': childProfile?.gender === 'F',
      })}
    >
      <ChildProfileHeader userData={childProfile} userRole={userRole} />

      <div className="course-wrapper">
        <CourseSection
          title="Courses In Progress"
          noContentTitle={'Not enrolled in any courses'}
          noContentSubtitle={`No Available Courses`}
          courseData={coursesList?.coursesInProgress}
          getCourses={actions.getCoursesList}
          getCurrentWeekOverview={actions.getWeekOverview}
          childId={correctChildId}
          userRole={userRole}
          setPausedModalVisibility={setPausedModalVisibility}
          collectedPoints={coursesList?.pointsInProgressCollected}
          totalPoints={coursesList?.totalPointsInProgress}
        />
        <CourseSection
          title="Completed Course"
          noContentTitle={'No completed courses'}
          noContentSubtitle={`Enrol ${childProfile?.firstName} in courses to start tracking his progress`}
          courseData={coursesList?.completedCourses}
          getCourses={actions.getCoursesList}
          getCurrentWeekOverview={actions.getWeekOverview}
          childId={correctChildId}
          userRole={userRole}
          setPausedModalVisibility={setPausedModalVisibility}
          totalPoints={coursesList?.totalPointsCompleted}
          collectedPoints={coursesList?.completedPointsCollected}
        />
      </div>
      <PausedCourseModal
        pausedModalVisibility={pausedModalVisibility}
        setPausedModalVisibility={setPausedModalVisibility}
        userRole={userRole}
        childGender={childProfile?.gender}
        user={childProfile}
        switchAccountLogout={actions.switchAccountLogout}
      />
    </div>
  );
};

export default View;
