import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  removeAllErrorNotifications,
  removeSingleErrorNotification,
} from '../../actions/errorNotification';

const mapStateToProps = state => ({
  errorNotifications: state.ErrorNotification.errorNotifications,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      removeAllErrorNotifications,
      removeSingleErrorNotification,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
