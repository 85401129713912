import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import history from '../../../history';
import RegisterHeading from '../Heading/index';
import { emailRules } from '../../../utils/inputRules';
import EmailIcon from '../../../images/register/Email.svg';
import './styles.scss';

const VerifyEmail = ({ actions, isVerified, isProcessed, location }) => {
  const queryParams = queryString.parse(location.search, {
    decode: false,
  });

  const [requestSent, setRequestSent] = useState(false);
  const isProcessedAndNotVerified = [requestSent && isProcessed && !isVerified].some(Boolean);
  useEffect(() => {
    const { email, token } = queryParams;
    if (token && email) {
      actions.verifyEmail(email, token);
      setRequestSent(true);
    }
  }, []);

  useEffect(() => {
    if (requestSent && isProcessed && isVerified) {
      history.push('/registration-confirmed');
    }
  }, [requestSent, isProcessed, isVerified]);

  const sendVerificationEmail = values => {
    const { email } = values;
    actions.sendVerificationEmail(email);
  };

  const handlePushToLogin = () => history.push('/login');

  return (
    <div className="background">
      <div className="verify-email-wrapper">
        {isProcessedAndNotVerified ? (
          <div className="login-form-box login-dashboard-wrapper">
            <div className="login-form-header">
              <p className="login-form-title">Send verify account email</p>
              <p className="login-form-subtitle">Follow steps from email to verify your account</p>
            </div>
            <Form onFinish={sendVerificationEmail}>
              <div className="login-content-box">
                <div className="login-input-wrapper">
                  <Form.Item name="email" rules={emailRules}>
                    <Input
                      placeholder="Email"
                      prefix={<img className="login-input-icon" alt="email-icon" src={EmailIcon} />}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="login-content-box">
                <Form.Item>
                  <button className="login-button">Send Reset Link</button>
                </Form.Item>
                <div onClick={handlePushToLogin}>
                  <p className="back-to-login">Cancel</p>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <RegisterHeading
              heading="We are processing your registration"
              subheading="Please wait a few minutes"
            />
          </div>
        )}
      </div>
    </div>
  );
};

VerifyEmail.propTypes = {
  actions: PropTypes.shape({
    verifyEmail: PropTypes.func,
    sendVerificationEmail: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  isVerified: PropTypes.bool.isRequired,
  isProcessed: PropTypes.bool.isRequired,
};

export default VerifyEmail;
