import React from 'react';
import { totalQuizPoints } from '../../../../utils/helpers';

const QuizPoints = ({ quiz }) => {
  const quizPoints = quizItem =>
    quizItem.quizType === 'MULTIPLE_CHOICE_QUIZ_TYPE'
      ? totalQuizPoints(quizItem.questions)
      : quizItem.questionsCount * 10;
  const collectedPoints = quizItem =>
    quizItem.quizType === 'MULTIPLE_CHOICE_QUIZ_TYPE'
      ? quizItem.userQuizzes[0].correctAnswersCount * 5
      : quizItem.userQuizzes[0].correctAnswersCount * 10;

  if (!!quiz.userQuizzes.length) {
    return <p className="description">{`${collectedPoints(quiz)}/${quizPoints(quiz)} pts`}</p>;
  }
  return (
    <p className="description">{`${quiz.questionsCount} Questions - ${quizPoints(quiz)} pts`}</p>
  );
};

export default QuizPoints;
