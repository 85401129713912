import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getQuizById,
  postAnswerQuiz,
  postLessonWatched,
  setActiveAnswer,
  setAnswerValue,
  incrementQuestionNum,
  decrementQuestionNum,
  watchNowController,
  updatePoints,
  isQuizAction,
  openLetsQuizScreen,
  incrementVideoNum,
  closeLetsQuizScreen,
  setCoins,
  notFinalScreen,
  resetState,
} from './modules/actions';
import { setActiveLesson, setActiveQuiz } from '../modules/actions';

const mapStateToProps = state => ({
  isQuizCompleted: state.ChildWatchNowReducer.isQuizCompleted,
  answerValue: state.ChildWatchNowReducer.answerValue,
  activeAnswer: state.ChildWatchNowReducer.activeAnswer,
  questionNum: state.ChildWatchNowReducer.questionNum,
  videoNum: state.ChildWatchNowReducer.videoNum,
  finalScreen: state.ChildWatchNowReducer.finalScreen,
  isQuiz: state.ChildWatchNowReducer.isQuiz,
  openLetsQuizScreen: state.ChildWatchNowReducer.openLetsQuizScreen,
  coins: state.ChildWatchNowReducer.coins,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updatePoints,
      getQuizById,
      postAnswerQuiz,
      postLessonWatched,
      setActiveAnswer,
      setAnswerValue,
      incrementQuestionNum,
      decrementQuestionNum,
      watchNowController,
      isQuizAction,
      incrementVideoNum,
      openLetsQuizScreen,
      closeLetsQuizScreen,
      setCoins,
      notFinalScreen,
      resetState,
      setActiveLesson,
      setActiveQuiz,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
