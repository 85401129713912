import React, { useState } from 'react';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import calendarIcon from '../../../images/child/Calendar.svg';

const EditChildAge = ({ onFinish, defaultDate, setVisible }) => {
  const [newDate, setNewDate] = useState(null);
  const before5Years = moment().add(-5, 'years');
  const before12Years = moment().add(-12, 'years');

  const disabledDate = current => {
    return before5Years <= current || before12Years >= current;
  };
  const onSubmit = () => {
    onFinish(newDate || defaultDate);
    setVisible(false);
  };
  const handleDateChange = (_, dateString) => {
    setNewDate(dateString);
  };
  return (
    <>
      <span className="edit-child-age-title">Select Date of Birth</span>
      <div className="edit-child-age-wrapper">
        <DatePicker
          disabledDate={disabledDate}
          defaultValue={moment(defaultDate)}
          placeholder="Choose age"
          className="edit-child-age-input"
          suffixIcon={<img src={calendarIcon} alt="date-icon" />}
          allowClear={false}
          defaultPickerValue={before5Years}
          onChange={handleDateChange}
          placement={'bottomLeft'}
        />
        <Button onClick={onSubmit}>Confirm</Button>
      </div>
    </>
  );
};

export default EditChildAge;
