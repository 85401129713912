import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { verifyEmail, sendVerificationEmail } from '../../../actions/auth';

const mapStateToProps = state => ({
  isVerified: state.AuthReducer.isVerified,
  isProcessed: state.AuthReducer.isProcessed,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      verifyEmail,
      sendVerificationEmail,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
