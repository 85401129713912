import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Form, Input } from 'antd';

import RegisterHeading from './Heading';
import renderPasswordIcon from '../../utils/renderPasswordIcon';
import { englishAlphabet } from '../../utils/helpers';
import { eventTrack, events } from '../../utils/googleAnalyticsEventTrack';
import '../Login/login.scss';
import {
  emailRules,
  firstNameRules,
  lastNameRules,
  passwordRegisterRules,
  repeatPasswordRules,
} from '../../utils/inputRules';
import { useForm } from 'antd/lib/form/Form';

const Register = props => {
  const history = useHistory();
  const [form] = useForm();
  const [firstNameValidationError, setFirstNameValidationError] = useState(false);
  const [lastNameValidationError, setLastNameValidationError] = useState(false);
  const [disabledRegisterForm, setDisabledRegisterForm] = useState(true);

  const handleFormChange = () => {
    const hasErrors =
      englishAlphabetValidation ||
      !form.isFieldsTouched(true) ||
      form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledRegisterForm(hasErrors);
  };

  const { actions, layoutMode } = props;
  const onFinish = values => {
    actions.register({ ...values });
  };

  const handleFirstNameValidator = e => {
    setFirstNameValidationError(englishAlphabet(e.target.value));
  };

  const handleLastNameValidator = e => {
    setLastNameValidationError(englishAlphabet(e.target.value));
  };

  const handlePushToLogin = () => history.push('/login');

  useEffect(() => {
    eventTrack(events.AUTH_EVENTS_CATEGORY, events.AUTH_EVENTS.START_REGISTRATION);
  }, []);

  const englishAlphabetValidation = firstNameValidationError || lastNameValidationError;

  return (
    <div
      className={classNames({
        background: true,
        'background-black': layoutMode === 'dark',
      })}
    >
      <div className="login-wrapper">
        <RegisterHeading />

        <div
          className={classNames({
            'login-form-box': true,
            'login-form-box-black': layoutMode === 'dark',
          })}
        >
          <div className="login-form-header">
            <p className="login-form-title">Register With Kutuby</p>
            <p className="login-form-subtitle">Please complete the fields below.</p>
          </div>
          <Form
            onFieldsChange={handleFormChange}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
            form={form}
          >
            <div className="login-content-box">
              <p className="login-form-input-label">First Name</p>
              <div className="login-input-wrapper">
                <Form.Item name="name" rules={firstNameRules}>
                  <Input
                    onChange={handleFirstNameValidator}
                    placeholder="First Name"
                    className="capitalized login-input"
                  />
                </Form.Item>
              </div>

              <p className="login-form-input-label">Last Name</p>
              <div className="login-input-wrapper">
                <Form.Item name="surname" rules={lastNameRules}>
                  <Input
                    onChange={handleLastNameValidator}
                    placeholder="Last Name"
                    className="capitalized login-input"
                  />
                </Form.Item>
              </div>

              <p className="login-form-input-label">Email</p>
              <div className="login-input-wrapper">
                <Form.Item name="email" rules={emailRules}>
                  <Input placeholder="Email" className="login-input" />
                </Form.Item>
              </div>
              <p className="login-form-input-label">Password</p>
              <div className="login-input-wrapper">
                <Form.Item name="password" rules={passwordRegisterRules}>
                  <Input.Password placeholder="Password" iconRender={renderPasswordIcon} />
                </Form.Item>
              </div>

              <p className="login-form-input-label">Confirm Password</p>
              <div className="login-input-wrapper">
                <Form.Item name="repeat_password" rules={repeatPasswordRules}>
                  <Input.Password placeholder="Confirm Password" iconRender={renderPasswordIcon} />
                </Form.Item>
              </div>
            </div>

            <div className="login-content-box">
              <Form.Item>
                {englishAlphabetValidation && (
                  <p className="name-validation-error">Only english alphabet is allowed!</p>
                )}
                <div className="center-content">
                  <button
                    disabled={disabledRegisterForm}
                    className={disabledRegisterForm ? 'login-button-disabled' : 'login-button'}
                  >
                    Register
                  </button>
                </div>
              </Form.Item>
              <div onClick={handlePushToLogin}>
                <p className="back-to-login">Return To Login Page</p>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default Register;
