import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CourseSection from './components/CourseSection';
import CourseCard from '../CourseCard';

import './childProfile.scss';
import ChildProfileHeader from './components/ChildProfileHeader';

const ChildProfile = props => {
  const { actions, currentChild, userRole, child, watchNowModal, quizModal } = props;
  const { state } = useLocation();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    actions.setActiveLesson(null);
    actions.setActiveQuiz(null);
    actions.resetState();
    if (watchNowModal) {
      actions.setWatchNowModal();
    }
    if (quizModal) {
      actions.setQuizModal();
    }
  }, []);

  useEffect(() => {
    if (userRole === 'PARENT') {
      actions.getCurrentChild(state?.childId);
    } else {
      actions.getUser();
    }
  }, []);

  useEffect(() => {
    if (userRole === 'PARENT') {
      setUserData(currentChild);
    } else {
      setUserData(child);
    }
  }, [child, currentChild]);

  const redirectPathname =
    userRole === 'PARENT' ? '/parent-profile/course-overview' : '/child-profile';
  const coursesInProgressMapping = useMemo(
    () =>
      userData?.coursesInProgress?.map(item => (
        <CourseCard
          course={item?.course}
          childId={userData?.id}
          pathname={redirectPathname}
          progressStatus={item?.status}
          courseAttendees={item}
          isEnrolled
        />
      )),
    [userData?.coursesInProgress, userData?.id, redirectPathname]
  );
  const completedCoursesMapping = useMemo(
    () =>
      userData?.completedCourses?.map(item => (
        <CourseCard
          course={item?.course}
          childId={userData?.id}
          pathname={redirectPathname}
          isEnrolled
          courseAttendees={item}
        />
      )),
    [userData?.completedCourses, userData?.id, redirectPathname]
  );

  return (
    <div className="background">
      <ChildProfileHeader userData={userData} userRole={userRole} />
      <div className="child-profile-course-information">
        <CourseSection
          title="Courses In Progress"
          noContentTitle={'Not enrolled in any courses'}
          noContentSubtitle={`No Available Courses`}
          courses={coursesInProgressMapping}
          collectedPoints={userData?.pointsInProgressCollected}
          totalPoints={userData?.totalPointsInProgress}
        />
        <CourseSection
          title="Completed Course"
          noContentTitle={'No completed courses'}
          noContentSubtitle={`Enrol ${userData?.firstName} in courses to start tracking his progress`}
          courses={completedCoursesMapping}
          totalPoints={userData?.totalPointsCompleted}
          collectedPoints={userData?.completedPointsCollected}
        />
      </div>
    </div>
  );
};

export default ChildProfile;
