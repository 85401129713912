import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import { CloseOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import {
  multipleChoiceQuizType,
  singleAnswerQuizType,
  dragAndDropQuizType,
} from '../../../../utils/constants.js';
import MultipleChoiceQuizQuestions from './components/MultipleChoiceQuizQuestions.js';
import QuizQuestions from './components/QuizQuestions.js';
import './childCourseQuizzes.scss';
import DragAndDropQuiz from './components/DragAndDropQuiz.js';
import { calculatePercentageCompleted } from '../../../../utils/helpers.js';
import QuizOverviewScreen from './components/QuizOverviewScreen.js';
import LetsQuizScreen from './components/LetsQuizScreen.js';

const ChildCourseQuizzes = ({
  actions,
  answers,
  text,
  coins,
  questionNum,
  answerAttempts,
  finalScreen,
  layoutMode,
  activeQuiz,
  quizModal,
  courseId,
  closeQuizModal,
  getCourseDetails,
  submittedAnswers,
  answer,
  activeAnswer,
  quizOverviewData,
  selectedAnswers,
  overview,
  child
}) => {
  const dragAndDropData = {
    answers,
    text,
    coins,
    questionNum,
    answerAttempts,
    finalScreen,
  };

  const [letsQuiz, setLetsQuiz] = useState(true);

  const QuizType = useMemo(() => {
    if (activeQuiz?.quizType === singleAnswerQuizType) {
      return (
        <QuizQuestions
          quiz={activeQuiz}
          updatePoints={actions.updatePoints}
          postAnswerQuiz={actions.postAnswerQuiz}
          courseId={courseId}
          notFinalScreen={actions.notFinalScreen}
          setFinalScreen={actions.setFinalScreen}
          setCoins={actions.setCoins}
          coins={coins}
          setQuestionNum={actions.setQuestionNum}
          questionNum={questionNum}
          actions={actions}
          submittedAnswers={submittedAnswers}
          answer={answer}
          activeAnswer={activeAnswer}
        />
      );
    }
    if (activeQuiz?.quizType === multipleChoiceQuizType) {
      return (
        <MultipleChoiceQuizQuestions
          quiz={activeQuiz}
          updatePoints={actions.updatePoints}
          postAnswerQuiz={actions.postAnswerQuiz}
          courseId={courseId}
          notFinalScreen={actions.notFinalScreen}
          setFinalScreen={actions.setFinalScreen}
          setCoins={actions.setCoins}
          coins={coins}
          setQuestionNum={actions.setQuestionNum}
          questionNum={questionNum}
          actions={actions}
          submittedAnswers={submittedAnswers}
          selectedAnswers={selectedAnswers}
        />
      );
    }
    if (activeQuiz?.quizType === dragAndDropQuizType) {
      return (
        <DragAndDropQuiz
          quiz={activeQuiz}
          updatePoints={actions.updatePoints}
          postAnswerQuiz={actions.postAnswerQuiz}
          courseId={courseId}
          notFinalScreen={actions.notFinalScreen}
          dragAndDropData={dragAndDropData}
          actions={actions}
        />
      );
    }
  }, [activeQuiz, courseId, dragAndDropData, actions, questionNum]);

  const closeAndReset = useCallback(() => {
    closeQuizModal();
    actions.resetState();
    setLetsQuiz(true);
  }, [actions, closeQuizModal]);

  const CloseIcon = useMemo(() => {
    if (!finalScreen) return <CloseOutlined className="close-icon" onClick={closeAndReset} />;
  }, [finalScreen, closeAndReset]);

  const percentageCompleted = calculatePercentageCompleted(
    activeQuiz?.questions.length,
    questionNum + 1
  );
  const letsQuizHandler = () => {
    setLetsQuiz(false);
  };

  const finalScreenOrQuiz = useMemo(() => {
    if (finalScreen) {
      return (
        <QuizOverviewScreen
          coins={coins}
          closeWatchNowModal={closeAndReset}
          notFinalScreen={actions.notFinalScreen}
          actions={actions}
          getCourseDetails={getCourseDetails}
          quizOverviewData={quizOverviewData}
          quizId={activeQuiz?.id}
          overview={overview}
          child={child}
        />
      );
    }
    if (letsQuiz) {
      return <LetsQuizScreen handleSubmit={letsQuizHandler} />;
    }
    return (
      <>
        <div className="quiz-wrapper-top">
          {CloseIcon}
          <div className="question-info">
            <span className="quiz-title">{activeQuiz?.title}</span>
            <span className="question-number">
              Question {questionNum + 1} Of
              {' ' + activeQuiz?.questions.length}
            </span>
            <Progress percent={percentageCompleted} showInfo={false} />
            <span className="question-title">{activeQuiz?.questions[questionNum]?.title}</span>
          </div>
        </div>
        <div className="quiz-wrapper-bottom">{QuizType}</div>
      </>
    );
  }, [
    CloseIcon,
    QuizType,
    actions,
    activeQuiz?.id,
    activeQuiz?.questions,
    activeQuiz?.title,
    closeAndReset,
    coins,
    finalScreen,
    getCourseDetails,
    overview,
    percentageCompleted,
    questionNum,
    quizOverviewData,
    letsQuiz,
  ]);

  if (!quizModal) return null;
  return (
    <div className="modal" id="modalContainer">
      <div className="overlay">
        <div
          scroll="no"
          className={classNames({
            'quiz-modal-wrapper': true,
            'quiz-modal-wrapper-dark': layoutMode === 'dark',
          })}
        >
          {finalScreenOrQuiz}
        </div>
      </div>
    </div>
  );
};

export default ChildCourseQuizzes;
