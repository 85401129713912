import React from 'react';
import NoThumbnail from '../../../../images/child/no-thumbnail.png';
import {
  avgGradeToLetters,
  stringLength,
} from '../../../../utils/helpers';
import OnTrackIcon from '../../../../images/courses/on-track.svg';
import InProgressIcon from '../../../../images/courses/in-progress.svg';
import Tag from '../../../../images/courses/Tag.svg';

import '../../../Parent/EnrollChild/enrollChild.scss';

const CourseCard = ({ course, progressStatus, courseAttendees, onCourseClick }) => {
  const ProgressIcon = () => {
    if (progressStatus === 'On Track') {
      return <img src={OnTrackIcon} />;
    } else if (progressStatus === 'Paused') return <img src={Tag} />;
    return <img src={InProgressIcon} />;
  };
  const freeCourseIcon = course.free ? (
    <div className="free-course-icon">BASIC</div>
  ) : (
    <div className="pro-course-icon">PLUS</div>
  );
  const courseDetails = (
    <div className="enroll-course-card-row">
      <p className="enroll-course-points">Points</p>
      <p className="enroll-course-points-value">
        {courseAttendees?.[0].pointsCollected || '0'}/{course?.courseCurrentTotalPoints}
      </p>
      <p className="enroll-course-points">Grade</p>
      <p className="enroll-course-points-value">{avgGradeToLetters(course?.averageGrade)}</p>
    </div>
  );

  return (
    <div
      onClick={onCourseClick(course.id, course.currentWeek)}
      className="enroll-course-card-wrapper"
    >
      <div className="enroll-course-card-column">
        <img
          src={course.courseImagesLandscape[0] ? course.courseImagesLandscape[0].url : NoThumbnail}
          className="enroll-course-img"
          alt="course-thumbnail"
        />
        <p className="enroll-course-card-title">
          {stringLength(course?.title, 25)}
          {freeCourseIcon}
        </p>
      </div>
      <div className="enroll-course-card-column">
        {progressStatus && (
          <div className="enroll-course-card-row status-row">
            <p className="enroll-course-points-value current-week">
              Week {course.currentWeek}/{course.totalWeeks}
            </p>
            <p className="enroll-course-points-value">
              <ProgressIcon />
            </p>
          </div>
        )}
        <p className="enroll-course-desc">{stringLength(course?.description, 100)}</p>
        {courseDetails}
      </div>
    </div>
  );
};

export default CourseCard;
