import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CourseCard from '../items/CourseCard';
import ParentCourseCard from '../items/ParentCourseCard';

const CoursesList = ({
  data,
  getCourses,
  getCurrentWeekOverview,
  childId,
  userRole,
  setPausedModalVisibility,
}) => {
  const history = useHistory();

  useEffect(() => {
    getCourses(childId, userRole);
  }, []);
  const coursesMapping = useMemo(
    () =>
      data?.map(item => {
        const onCourseClick = (id, week) => () => {
          if (item.status !== 'Paused' || userRole === 'PARENT' || week === 1) {
            getCurrentWeekOverview(id, week, childId);
            history.push({
              pathname: `/${userRole.toLowerCase()}-week-overview/${week}/${id}/${childId}?paused=${
                item.status === 'Paused'
              }`,
              state: { courseTitle: item?.title },
            });
          } else {
            setPausedModalVisibility(true);
          }
        };
        return userRole === 'CHILD' ? (
          <CourseCard key={item.id} item={item} onCourseClick={onCourseClick} />
        ) : (
          <ParentCourseCard
            key={item?.id}
            progressStatus={item.status}
            course={item}
            onCourseClick={onCourseClick}
            courseAttendees={item.courseAttendees}
            childId={childId}
            isEnrolled
          />
        );
      }),
    [data, childId, history, getCurrentWeekOverview, userRole, setPausedModalVisibility]
  );

  return <div className="courses-list-wrapper">{coursesMapping}</div>;
};

export default CoursesList;
