import React from 'react';
import { useLocation } from 'react-router-dom';
import Duration from '../../../../images/lesson/Duration.png';
import NoThumbnail from '../../../../images/child/no-thumbnail.png';
import './lessonOverview.scss';
import { formatDuration } from '../../../../utils/helpers';

const LessonOverview = () => {
  const {
    state: { lesson },
  } = useLocation();

  let durationCount = 0;
  lesson?.videos?.map(item => {
    durationCount += item?.duration;
  });

  return (
    <div className="background">
      <div className="lesson-overview-wrapper">
        <div className="lesson-overview-row">
          <div className="image-row">
            <img
              className="lesson-overview-image"
              src={lesson.lessonImages[0] ? lesson.lessonImages[0].url : NoThumbnail}
              alt="no-thumbnail"
            />
            <div className="badge-wrapper">
              <img src={Duration} alt="duration" />
              <div className="duration-wrapper">
                <span className="label">Length</span>
                <span className="value">{formatDuration(durationCount)}</span>
              </div>
            </div>
          </div>

          <div className="lesson-overview-content">
            <p className="title">{lesson.title}</p>
            <p className="description">{lesson.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonOverview;
