/* eslint-disable import/no-anonymous-default-export */
import { t } from './actions';
const defaultState = {
  quizToDo: null,
  loading: false,
  correctAnswers: null,
  lesson_quiz: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_QUIZ_BY_ID:
      return {
        ...state,
        quizToDo: action.quiz,
      };
    case t.SUBMIT_QUIZ:
      return {
        ...state,
        loading: true,
      };
    case t.CORRECT_ANSWERS:
      return {
        ...state,
        loading: false,
        correctAnswers: action.points,
      };
    case t.SET_LESSON_QUIZ:
      return {
        ...state,
        lesson_quiz: action.quiz,
      };
    default:
      return state;
  }
};
