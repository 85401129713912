import { createLog } from '../services/loggerService';
import JwtDecode from 'jwt-decode';

export const logger = async error => {
  const accountToken = localStorage.getItem('account_token');
  const childToken = localStorage.getItem('child_profile_token');

  let decoded;
  if (accountToken || childToken) {
    decoded = JwtDecode(accountToken || childToken);
  }

  const postData = {
    title: error.message,
    user: decoded?.user,
    app: 'Kutuby Web',
    time: new Date(),
    description: error.stack,
  };

  await createLog(postData);
};
