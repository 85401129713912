import { all, call, takeLeading, put, select } from 'redux-saga/effects';
import { setChildProfileAction, setCoursesList, setWeekOverview, setWeeksList, t } from './actions';
import { notification } from 'antd';
import { getCoursesList, getWeeksList, getWeekOverview } from '../../../services/coursesService';
import { getUserData } from '../../../services/usersService';
import { getChildProfile } from '../../../services/parentService';
import { getLessonByIdService } from '../../../services/lessonService';
import {
  setActiveLesson,
  setWatchNowModal,
  setActiveQuiz,
  setQuizModal,
} from '../../Child/ChildDashboard/modules/actions';
import { getQuizById } from '../../../services/quizService';
import {
  setFinalScreen,
  setOverview,
} from '../../Child/ChildDashboard/ChildCourseQuizzes/modules/actions';
import { setIsLoading } from '../../../actions/customLoader';
import { setNewErrorNotification } from '../../../actions/errorNotification';
import { generalErrorMessage } from '../../../utils/constants';

const getChildFromState = state => state.CourseReducer.childProfile;

function* getChildProfileSaga(action) {
  yield put(setIsLoading(true));
  const postData = action.payload;
  let res;
  if (postData.role === 'PARENT') {
    res = yield call(getChildProfile, postData.childId);
  } else {
    res = yield call(getUserData);
  }
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: 'Unable to fetch child profile!',
      })
    );
  } else {
    yield put(setChildProfileAction(data));
  }
  yield put(setIsLoading(false));
}

function* getCoursesListSaga(action) {
  yield put(setIsLoading(true));
  yield call(getChildProfileSaga, action);
  const user = yield select(getChildFromState);
  const res = yield call(getCoursesList, user?.id);
  const { data, error } = res;

  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: 'Unable to fetch courses list!',
      })
    );
  } else {
    yield put(setCoursesList(data));
  }
  yield put(setIsLoading(false));
}
function* getWeeksListSaga(action) {
  yield put(setIsLoading(true));
  const postData = action.payload;
  const res = yield call(getWeeksList, postData);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: 'Unable to fetch weeks list!',
      })
    );
  } else {
    yield put(setWeeksList(data));
  }
  yield put(setIsLoading(false));
}

function* getWeekOverviewSaga(action) {
  yield put(setIsLoading(true));
  const postData = action.payload;
  const res = yield call(getWeekOverview, postData);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: 'Unable to fetch weeks overview!',
      })
    );
  } else {
    yield put(setWeekOverview(data));
  }
  yield put(setIsLoading(false));
}
function* startLessonSaga(action) {
  yield put(setIsLoading(true));
  const postData = action.payload;
  const { data } = yield call(getLessonByIdService, postData.id);
  if (data) {
    yield put(setActiveLesson(data));
    yield put(setWatchNowModal());
  } else {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: 'Unable to fetch lesson details!',
      })
    );
  }
  yield put(setIsLoading(false));
}
function* startQuizSaga(action) {
  yield put(setIsLoading(true));
  const postData = action.payload;
  const { data } = yield call(getQuizById, 'CHILD', postData.id);
  if (data) {
    yield put(setActiveQuiz(data));
    yield put(setQuizModal(true));
    if (data.isCompleted) {
      yield put(setFinalScreen(true));
      yield put(setOverview(true));
    }
  } else {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: 'Unable to fetch quiz details!',
      })
    );
  }
  yield put(setIsLoading(false));
}

export default function* courseSaga() {
  yield all([
    takeLeading(t.GET_COURSES_LIST, getCoursesListSaga),
    takeLeading(t.GET_WEEKS_LIST, getWeeksListSaga),
    takeLeading(t.GET_WEEK_OVERVIEW, getWeekOverviewSaga),
    takeLeading(t.GET_CHILD_PROFILE_ACTION, getChildProfileSaga),
    takeLeading(t.START_LESSON, startLessonSaga),
    takeLeading(t.START_QUIZ, startQuizSaga),
  ]);
}
