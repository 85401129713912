import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { Row, Col, Button } from 'antd';

import { setTopBorderColor, formatDate } from '../../../../utils/helpers';

const CourseItem = ({ index, course }) => {
  const courseStartDate = useMemo(() => {
    return formatDate(course.from);
  }, [course.from]);

  return (
    <Row className={setTopBorderColor(index)}>
      <Row className="course-row-container course-title">Course name: {course.course.title}</Row>

      <Row className="course-start-date">Start Date: {courseStartDate}</Row>
      <Row className="enroll-overview-container">
        <Col className="col"></Col>

        <Col className="col">
          <NavLink
            to={`/parent-profile/course-overview/${course.courseId}/${course.userId}/isEnrolled`}
          >
            <Button className="overview-button">Overview</Button>
          </NavLink>
        </Col>
      </Row>
    </Row>
  );
};

export default CourseItem;
