export const t = {
  SET_IS_LOADING: 'SET_IS_LOADING',
};

export const setIsLoading = isLoading => ({
  type: t.SET_IS_LOADING,
  payload: {
    isLoading,
  },
});
