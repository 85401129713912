import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RegisterHeading from '../Register/Heading';
import renderPasswordIcon from '../../utils/renderPasswordIcon';
import { trackPixelEvent } from '../../utils/facebookPixelEvents';
import SavedAccount from './components/SavedAccount';
import LoginForm from './components/LoginForm';
import './login.scss';
import UseSubmitLogin from '../../utils/hooks/useSubmitLogin';

const Login = props => {
  const [keepUserLoggedIn, setkeepUserLoggedIn] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const { actions, layoutMode, savedAccounts } = props;

  const setEmailErrorHandler = value => {
    setEmailError(value);
  };

  const onFinish = values => {
    UseSubmitLogin(values, actions, keepUserLoggedIn, setEmailErrorHandler);
  };

  useEffect(() => {
    actions.getSavedAccounts();
  }, []);

  const removeSavedAccount = () => {
    actions.removeSavedAccount();
  };

  const keepUserLoggedInHandler = e => {
    setkeepUserLoggedIn(e.target.checked);
  };
  return (
    <div
      className={classNames({
        background: true,
        'background-black': layoutMode === 'dark',
        'child-girl-background': savedAccounts?.type === 'CHILD' && savedAccounts?.gender === 'F',
        'child-boy-background': savedAccounts?.type === 'CHILD' && savedAccounts?.gender === 'M',
      })}
    >
      <div className="login-wrapper">
        <RegisterHeading />
        {savedAccounts ? (
          <SavedAccount
            account={savedAccounts}
            removeSavedAccount={removeSavedAccount}
            onFinish={onFinish}
            trackPixelEvent={trackPixelEvent}
            renderPasswordIcon={renderPasswordIcon}
            keepUserLoggedInHandler={keepUserLoggedInHandler}
            layoutMode={layoutMode}
          />
        ) : (
          <LoginForm
            onFinish={onFinish}
            emailError={emailError}
            renderPasswordIcon={renderPasswordIcon}
            trackPixelEvent={trackPixelEvent}
            keepUserLoggedInHandler={keepUserLoggedInHandler}
            layoutMode={layoutMode}
          />
        )}
      </div>
    </div>
  );
};

Login.propTypes = {
  actions: PropTypes.shape({
    login: PropTypes.func.isRequired,
  }).isRequired,
};

export default Login;
