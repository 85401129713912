import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getChild, getAvatars } from '../modules/actions';
const mapStateToProps = state => ({
  avatars: state.AddChildReducer.avatars,
  layoutMode: state.AuthReducer.layoutMode,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getChild,
      getAvatars,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
