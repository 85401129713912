import { types } from './types';

export const setAnswerValue = answer => ({
  type: types.SET_ANSWER_VALUE,
  payload: {
    answer,
  },
});
export const setActiveAnswer = idx => ({
  type: types.SET_ACTIVE_ANSWER_VALUE,
  payload: {
    idx,
  },
});

export const incrementQuestionNum = () => ({
  type: types.INCREMENT_QUESION_NUM,
});

export const decrementQuestionNum = () => ({
  type: types.DECREMENT_QUESION_NUM,
});

export const getQuizById = (type, id) => ({
  type: types.GET_QUIZ_BY_ID_IS_COMPLETED,
  payload: {
    type,
    id,
  },
});
export const postAnswerQuiz = data => ({
  type: types.POST_ANSWER_QUIZ,
  payload: {
    data,
  },
});

export const setQuizById = isQuizCompleted => ({
  type: types.SET_QUIZ_BY_ID_IS_COMPLETED,
  payload: {
    isQuizCompleted,
  },
});

export const updatePoints = data => ({
  type: types.UPDATE_POINTS,
  payload: {
    data,
  },
});

export const postLessonWatched = data => ({
  type: types.POST_LESSON_WATCHED,
  payload: {
    data,
  },
});

export const watchNowController = data => ({
  type: types.WATCH_NOW_CONTROLLER,
  payload: {
    data,
  },
});

export const restartQuestionNum = () => ({
  type: types.RESTART_QUESION_NUM,
});

export const restartCoins = () => ({
  type: types.RESTART_COINS,
});

export const restartVideonNum = () => ({
  type: types.RESTART_VIDEO_NUM,
});

export const notFinalScreen = () => ({
  type: types.NOT_FINAL_SCREEN,
});

export const finalScreenAction = () => ({
  type: types.FINAL_SCREEN,
});

export const incrementVideoNum = () => ({
  type: types.INCREMENT_VIDEO_NUM,
});

export const notQuiz = () => ({
  type: types.NOT_QUIZ,
});
export const resetState = () => ({
  type: types.RESET_STATE,
});

export const isQuizAction = () => ({
  type: types.IS_QUIZ,
});

export const openLetsQuizScreen = () => ({
  type: types.OPEN_LETS_QUIZ_SCREEN,
});

export const closeLetsQuizScreen = () => ({
  type: types.CLOSE_LETS_QUIZ_SCREEN,
});

export const setCoins = () => ({
  type: types.COINS,
});
