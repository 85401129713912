import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import EditUserItem from '../../../EditUser/EditUserItem';
import DiscountIcon from '../../../../images/settings/Discount.svg';
import LogoutIcon from '../../../../images/settings/logoutWhite.svg';
import MessageIcon from '../../../../images/settings/Message.svg';
import PlanIcon from '../../../../images/settings/Plan.svg';
import ProfileIcon from '../../../../images/settings/Profile.svg';
import SwitchAccountIcon from '../../../../images/settings/Switch Account.svg';
import HelpCentreIcon from '../../../../images/settings/Help Centre.svg';
import LockIcon from '../../../../images/child/Lock.svg';
import usernameIcon from '../../../../images/child/Username.png';
import ChangeParentPassword from '../ChangeParentPassword';
import CancelSubscription from '../../../Payment/components/CancelSubscription';
import deleteAccountIcon from '../../../../images/settings/DeleteAccount.svg';

import { userTypes } from '../../../../utils/constants';

import './settings.scss';
import { firstNameRules } from '../../../../utils/inputRules';
import DeleteParentAccount from './components/DeleteParentAccount';
import RedeemCode from './components/RedeemCode';
import CautionModal from './components/CautionModal';

const userType = userTypes.parent;

const Settings = props => {
  const { actions, currentUser, userPaymentStatus, paymentLogs, children } = props;

  const history = useHistory();
  useEffect(() => {
    actions.getCurrentUser(userType);
    actions.getChildren();
  }, []);
  useEffect(() => {
    if (currentUser) {
      actions.getPaymentLogs(currentUser.id);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!userPaymentStatus && currentUser) {
      actions.getUserPayment(currentUser?.id);
    }
  }, [userPaymentStatus, currentUser]);

  const deleteAccount = password => {
    actions.deleteUser(currentUser.id, true, currentUser.email, password);
  };
  const contactUsHandler = () => {
    window.open('https://wa.me/447379780318');
  };
  const redirectPlanScreen = () => {
    history.push('/plans');
  };
  const redirectFaqScreen = () => {
    history.push('/parent-profile/faq');
  };

  const redeemCode = code => {
    actions.redeemCode({ code });
  };

  const switchAccountCondition =
    children && children.length > 0 ? actions.switchAccountLogout : actions.logout;

  const subscriptionPlan = userPaymentStatus ? (
    <EditUserItem
      item="Plus plan"
      ModalContent={CancelSubscription}
      modalContentProps={{
        actions: actions,
        user: currentUser,
        userPaymentStatus: userPaymentStatus,
        paymentLogs: paymentLogs,
      }}
      modalContentShow={true}
      icon={PlanIcon}
      modalTitle="Adjust Your Plan"
    />
  ) : (
    <EditUserItem
      item="Basic Plan"
      redirect={redirectPlanScreen}
      icon={PlanIcon}
      modalTitle="Adjust Your Plan"
    />
  );

  return (
    <div className="background">
      <div className="settings-wrapper">
        <div className="content-wrapper">
          <span className="title">Settings</span>
          <span className="section-title">Account Information</span>

          <EditUserItem
            item={currentUser?.firstName}
            icon={ProfileIcon}
            modalTitle="Change Profile Name"
            formFields={[{ name: 'firstName', placeholder: 'Change First Name' }]}
            handleOnFinish={{ action: actions.updateUser, parentId: currentUser?.id }}
            userId={currentUser?.id}
            inputRules={firstNameRules}
          />
          <EditUserItem item={currentUser?.email} disabled icon={usernameIcon} />
          <EditUserItem
            item="*******"
            icon={LockIcon}
            modalTitle="Change Your Password"
            ModalContent={ChangeParentPassword}
            modalContentShow={true}
          />
          {subscriptionPlan}
          <EditUserItem
            item="Redeem Code"
            icon={DiscountIcon}
            ModalContent={RedeemCode}
            modalContentProps={{
              redeemCode: redeemCode,
            }}
            modalContentShow={true}
          />
          <span className="section-title">Support</span>

          <EditUserItem item="FAQ" redirect={redirectFaqScreen} icon={HelpCentreIcon} />
          <EditUserItem item="Contact Us" redirect={contactUsHandler} icon={MessageIcon} />
          <span className="section-title">More Settings</span>

          <EditUserItem
            item="Switch Account"
            redirect={switchAccountCondition}
            icon={SwitchAccountIcon}
          />
          <EditUserItem
            item="Delete Account"
            icon={deleteAccountIcon}
            ModalContent={DeleteParentAccount}
            modalContentProps={{
              deleteAccount: deleteAccount,
            }}
            modalContentShow={true}
          />
          <EditUserItem
            item="Logout"
            icon={LogoutIcon}
            redBackground={true}
            ModalContent={CautionModal}
            modalContentProps={{
              title: 'Log out?',
              onSubmit: actions.logout,
              description: 'Are you sure you want to logout from your account?',
              descriptionSm: 'You can log in back any time',
              buttonText: 'Log Me Out',
            }}
            modalContentShow={true}
          />
        </div>
      </div>
    </div>
  );
};

Settings.propTypes = {
  actions: PropTypes.shape({
    getCurrentUser: PropTypes.func.isRequired,
  }).isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default Settings;
