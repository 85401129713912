import React from 'react';
import { Form, Input } from 'antd';
import LockIcon from '../../../images/register/Lock.svg';
import { passwordRules } from '../../../utils/inputRules';
import renderPasswordIcon from '../../../utils/renderPasswordIcon';

import '../../Login/login.scss';

const CancelSubscription = ({ actions, userPaymentStatus, user, paymentLogs }) => {
  const onFinish = formData => {
    actions.cancelSubscription({
      subscriptionId: userPaymentStatus?.subscriptionId,
      id: userPaymentStatus?.id,
      email: user.email,
      password: formData.password,
      plan: userPaymentStatus?.plan,
    });
  };
  const disableDowngrade = paymentLogs?.[0]?.platform !== 'WEB';
  const description = disableDowngrade
    ? `Please downgrade your subscription over ${paymentLogs[0].platform}`
    : `You are now on Plus plan. You can downgrade to Basic plan.If you downgraded all your
        children won’t be able to continue watching their courses.`;
  return (
    <div className="cancel-subscription-wrapper">
      <span className="title">Adjust Your Plan</span>
      <span className="description">{description}</span>
      {!disableDowngrade && (
        <Form className="cancel-subscription-form" onFinish={onFinish}>
          <div className="cancel-form-content-box">
            <div className="cancel-input-wrapper">
              <Form.Item name="password" rules={passwordRules}>
                <Input.Password
                  prefix={<img className="login-input-icon" alt="lock-icon" src={LockIcon} />}
                  placeholder={'Password'}
                  iconRender={renderPasswordIcon}
                />
              </Form.Item>
            </div>
          </div>
          <div className="login-content-box">
            <Form.Item className="center-content">
              <button className="cancel-subscription-button">Downgrade</button>
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default CancelSubscription;
