export const appearance = {
  theme: 'night',
  variables: {
    fontFamily: 'Sohne, system-ui, sans-serif',
    fontWeightNormal: '500',
    borderRadius: '8px',
    colorBackground: '#2a3336',
    colorPrimary: '#fbb628',
    colorPrimaryText: '#2a3336',
    colorText: 'white',
    colorTextSecondary: 'white',
    colorTextPlaceholder: '#727F96',
    colorIconTab: 'white',
    colorLogo: 'white',
  },
  rules: {
    '.Input, .Block': {
      backgroundColor: 'transparent',
      border: '1.5px solid var(--colorPrimary)',
    },
  },
};

export const cardElementStyle = {
  style: {
    base: {
      iconColor: '#fbb628',
      color: '#fbb628',
      borderRadius: '8px',
      fontWeight: '500',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      padding: '20px',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#96a0a3',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
  rules: {
    '.Input, .Block': {
      backgroundColor: 'transparent',
      border: '1.5px solid var(--colorPrimary)',
    },
  },
  hidePostalCode: true,
  iconStyle: 'solid',
};
