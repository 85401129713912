import { all, call, takeLeading, put, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  t,
  setChildProfile,
  setSingleQuiz,
  setLessonById,
  setSingleLesson,
  setQuizzes,
  addQuizzes,
  setHasMoreQuizzes,
  setLessons,
  addLessons,
  setHasMoreLessons,
  setCourse,
} from './actions';
import { setQuizById } from '../../Quiz/modules/actions';
import {
  getChildProfile,
  getUpcomingQuizzes,
  getQuizService,
  getChildSingleQuizService,
  getActivities,
} from '../../../../services/childService';
import { getLessonByIdService } from '../../../../services/lessonService';
import { getCourseByIdData, getCourseByIdAndWeekData } from '../../../../services/coursesService';
import { setIsLoading } from '../../../../actions/customLoader';
import { setNewErrorNotification } from '../../../../actions/errorNotification';
import { generalErrorMessage } from '../../../../utils/constants';

function* getLessons(action) {
  const { type, page } = action.payload;
  let response;
  if (type === 'upcoming') {
    response = yield call(getActivities, {
      page,
      type: 'upcoming',
    });
  } else {
    response = yield call(getActivities, {
      type: 'past',
      page,
      activityType: 'lesson',
    });
  }
  const { data, error } = response;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    const hasMore = data.length > 0;
    yield all([
      put(page === 1 ? setLessons(data) : addLessons(data)),
      put(setHasMoreLessons(hasMore)),
    ]);
  }
}

function* getCurrentChildProfile() {
  const { data, error } = yield call(getChildProfile);
  if (data) {
    yield put(setChildProfile(data));
  }
}

function* getQuiz(action) {
  const {
    payload: { id },
  } = action;
  const { data, error } = yield call(getQuizService, id);
  if (data) {
    yield put(setQuizById(data));
  }
}
function* getSingleQuiz() {
  const { data, error } = yield call(getChildSingleQuizService, '1');
  if (data) {
    yield put(setSingleQuiz(data));
  }
}

function* getCourseById(action) {
  const { type, courseId } = action.payload;
  yield put(setIsLoading(true));
  const { data, error } = yield call(getCourseByIdData, type, courseId);
  yield put(setIsLoading(false));

  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    const courseData = { ...data };
    const newLessonsArr = [];
    const newQuizzesArr = [];
    courseData.lessons.push.apply(newLessonsArr, courseData.lessons);
    courseData.quizzes.push.apply(newQuizzesArr, courseData.quizzes);
    yield put(setCourse({ ...courseData, lessons: newLessonsArr, quizzes: newQuizzesArr }));
  }
}

function* getLessonById(action) {
  const { id } = action;
  const { data, error } = yield call(getLessonByIdService, id);
  if (data) {
    yield put(setLessonById(data));
    const { chapters } = data;
    const chapterInProgress = chapters.filter(chapter => chapter.status === 'IN_PROGRESS');
    if (
      chapterInProgress &&
      chapterInProgress.length === 1 &&
      chapterInProgress[0].quizzes.length > 0
    ) {
      const quizId = chapterInProgress[0].quizzes[0].id;
      const res = yield call(getQuizService, quizId);
      if (res.data && res.data.startedAt) {
        yield put(setQuizById(chapterInProgress[0].quizzes[0]));
      }
    }
  }
}

function* getSingleLesson() {
  const { data, error } = yield call(getActivities, {
    limit: 1,
    type: 'upcoming',
  });
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setSingleLesson(data));
  }
}

function* getQuizzes(action) {
  const { type, page } = action.payload;
  let response;
  if (type === 'uncompleted') {
    response = yield call(getUpcomingQuizzes, { page });
  } else {
    response = yield call(getActivities, {
      type: 'past',
      page,
      activityType: 'quiz',
    });
  }
  const { data, error } = response;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    const hasMore = data.length > 0;
    yield all([
      put(page === 1 ? setQuizzes(data) : addQuizzes(data)),
      put(setHasMoreQuizzes(hasMore)),
    ]);
  }
}

export default function* parentSaga() {
  yield all([
    takeLeading(t.GET_LESSONS, getLessons),
    takeEvery(t.GET_CHILD_PROFILE, getCurrentChildProfile),
    takeLeading(t.GET_QUIZ_BY_ID, getQuiz),
    takeLeading(t.GET_SINGLE_QUIZ, getSingleQuiz),
    takeLeading(t.GET_LESSON_BY_ID, getLessonById),
    takeLeading(t.GET_SINGLE_LESSON, getSingleLesson),
    takeLeading(t.GET_QUIZZES, getQuizzes),
    takeLeading(t.GET_COURSE, getCourseById),
  ]);
}
