import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentUser } from '../../../Child/Courses/modules/actions';
import {
  logout,
  deleteUser,
  switchAccountLogout,
  updateUser,
  changePassword,
} from '../../../../actions/auth';
import { getUserPayment } from '../../../../actions/userPayment';
import { cancelSubscription, redeemCode, getPaymentLogs } from '../../../Payment/modules/actions';
import { getChildren } from '../../ParentProfile/modules/actions';

const mapStateToProps = state => ({
  children: state.ParentReducer.children,
  currentUser: state.CoursesReducer.currentUser,
  userPaymentStatus: state.userPaymentReducer.userPaymentStatus,
  paymentLogs: state.PaymentReducer.paymentLogs,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCurrentUser,
      logout,
      deleteUser,
      switchAccountLogout,
      updateUser,
      changePassword,
      getUserPayment,
      cancelSubscription,
      getPaymentLogs,
      redeemCode,
      getChildren,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
