import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getChildren, getParent } from '../../../Parent/ParentProfile/modules/actions';
import { loginByParent, logout } from '../../../../actions/auth';

const mapStateToProps = state => ({
  children: state.ParentReducer.children,
  parent: state.ParentReducer.parent,
  layoutMode: state.AuthReducer.layoutMode,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getChildren,
      getParent,
      loginByParent,
      logout,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
