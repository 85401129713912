export const t = {
  GET_AVAILABLE_COURSES: 'GET_AVAILABLE_COURSES_OVERVIEW',
  SET_AVAILABLE_COURSES: 'SET_AVAILABLE_COURSES',
  ENROLL_CHILD: 'ENROLL_CHILD',
  SET_CHOSEN_CHILD: 'SET_CHOSEN_CHILD',
};

export const getAvailableCoursesForChild = childId => ({
  type: t.GET_AVAILABLE_COURSES,
  payload: {
    childId,
  },
});

export const setAvailableCoursesForChild = courses => ({
  type: t.SET_AVAILABLE_COURSES,
  payload: {
    courses,
  },
});

export const enrollChild = enrollData => ({
  type: t.ENROLL_CHILD,
  enrollData,
});

export const setChosenChild = chosenChild => ({
  type: t.SET_CHOSEN_CHILD,
  payload: {
    chosenChild,
  },
});
