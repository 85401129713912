import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import Confetti from 'react-confetti';
import { useHistory, useLocation } from 'react-router-dom';

const ChildAdded = props => {
  const { layoutMode } = props;
  const location = useLocation();
  const [child, setchild] = useState({});
  const history = useHistory();
  const pushToParentProfile = () => {
    history.push('/parent-profile');
  };
  useEffect(() => {
    if (location.state) {
      setchild(location.state);
    }
  }, []);

  return (
    <div
      className={classNames({
        background: true,
        'background-black': layoutMode === 'dark',
      })}
    >
      <div className="login-wrapper">
        <Confetti numberOfPieces={800} recycle={false} />
        <div className="successfully-enrolled-wrapper">
          <p className="successfully-enrolled-child-name">
            You have successfully created a new child profile
          </p>
          <p className="successfully-enrolled-course-title">{child?.firstName}</p>
          <Button onClick={pushToParentProfile} className="successfully-enrolled-button">
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChildAdded;
