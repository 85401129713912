/* eslint-disable import/no-anonymous-default-export */
import { t } from './actions';
const defaultState = {
  courses: [],
  chosenChild: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_AVAILABLE_COURSES:
      return {
        ...state,
        courses: action.payload.courses,
      };
    case t.ENROLL_CHILD:
      return {
        ...state,
        courses: state.courses.filter(course => course.id !== action.enrollData.courseId),
      };
    case t.SET_CHOSEN_CHILD:
      return {
        ...state,
        chosenChild: action.payload.chosenChild,
      };
    default:
      return state;
  }
};
