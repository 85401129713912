import { ChildAuthorizedService, ParentAuthorizedService } from './apiService';
import { userTypes } from '../utils/constants';

const BASE_PATH = '/me';

const getService = userType => {
  if (userType === userTypes.child) {
    return ChildAuthorizedService;
  }
  return ParentAuthorizedService;
};

export const getCurrentUserData = async userType => {
  try {
    const response = await getService(userType.userType).get(`${BASE_PATH}`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getNotifications = async (userType, params) => {
  try {
    const response = await getService(userType).get(`${BASE_PATH}/notifications`, { params });
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const deleteNotifications = async userType => {
  try {
    const response = await getService(userType).delete(`${BASE_PATH}/notifications`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};
