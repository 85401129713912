import {
  AuthorizedApiService,
  ChildAuthorizedService,
  ParentAuthorizedService,
} from './apiService';

const BASE_PATH = '/me';

export const addChildService = async (path, data) =>
  ParentAuthorizedService.post(`me/${path}`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getChildService = async (path, data) =>
  AuthorizedApiService.get(`me/${path}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getChildUpcomingLessons = async id =>
  ParentAuthorizedService.get(`/me/children/${id}/activities`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getChildPastLessons = async (id, type, limit) =>
  ParentAuthorizedService.get(`me/children/${id}/activities?type=${type}&limit=${limit}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getChildLessonActivity = async (childId, activityId) =>
  ParentAuthorizedService.get(`/me/children/${childId}/activities/${activityId}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getChildServiceWithFilter = async (path, filter) =>
  ChildAuthorizedService.get(`me/${path}?type=${filter}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getActivities = async params => {
  try {
    const response = await ChildAuthorizedService.get(`${BASE_PATH}/activities`, { params });
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getChildActivities = async (childId, params) => {
  try {
    const response = await ParentAuthorizedService.get(
      `${BASE_PATH}/children/${childId}/activities`,
      { params }
    );
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getChildUpcomingQuizzes = async (childId, params) => {
  try {
    const response = await ParentAuthorizedService.get(`${BASE_PATH}/children/${childId}/quizzes`, {
      params,
    });
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getChildProfile = async path =>
  ChildAuthorizedService.get(`me`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getUpcomingQuizzes = async params => {
  try {
    const response = await ChildAuthorizedService.get(`${BASE_PATH}/quizzes`, { params });
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getChildPastActivities = async type =>
  ChildAuthorizedService.get(`activities?type=${type}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getQuizService = async id =>
  ChildAuthorizedService.get(`quizzes/${id}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getChildSingleQuizService = async limit =>
  ChildAuthorizedService.get(`/me/quizzes?limit=${limit}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const updateChildPoints = async data =>
  ChildAuthorizedService.put(`/courses/update-points/${data.userId}/${data.courseId}`, {
    pointsCollected: data.points,
  })
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
