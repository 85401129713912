import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentChild } from '../ParentProfile/modules/actions';
import { getAvatars } from '../AddChild/modules/actions';
import { updateUser, deleteUser } from '../../../actions/auth';

const mapStateToProps = state => ({
  child: state.ParentReducer.currentChild,
  avatars: state.AddChildReducer.avatars,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCurrentChild,
      getAvatars,
      updateUser,
      deleteUser,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
