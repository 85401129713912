import { ChildAuthorizedService, ParentAuthorizedService } from './apiService';
import { userTypes } from '../utils/constants';

const BASE_PATH = '/courses';

const getService = userType => {
  if (userType === userTypes.child) {
    return ChildAuthorizedService;
  }
  return ParentAuthorizedService;
};

export const getCoursesData = async () => {
  try {
    const response = await ChildAuthorizedService.get(`${BASE_PATH}`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getCourseByIdData = async (userType, courseId) => {
  try {
    const response = await getService(userType).get(`${BASE_PATH}/${courseId}`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getCoursesOverviewForChildData = async childId => {
  try {
    const response = await ParentAuthorizedService.get(`${BASE_PATH}/${childId}/overview`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getCourseByIdAndWeekData = async (courseId, week) => {
  try {
    const response = await ChildAuthorizedService.get(`${BASE_PATH}/${courseId}/${week}`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getCoursesOverviewForChildByIdData = async (childId, courseId) => {
  try {
    const response = await ParentAuthorizedService.get(
      `${BASE_PATH}/${childId}/overview/${courseId}`
    );
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getAvailableCoursesForChilddData = async childId => {
  try {
    const response = await ParentAuthorizedService.get(`${BASE_PATH}/${childId}/available`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const enrollChildToCourse = async data => {
  try {
    const response = await ParentAuthorizedService.post(`${BASE_PATH}/enrol-child`, data);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getCoursesList = async childId => {
  try {
    const response = await ParentAuthorizedService.get(`${BASE_PATH}/get/${childId}`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};
export const getWeeksList = async data => {
  try {
    const response = await ParentAuthorizedService.get(
      `${BASE_PATH}/weeks/${data.courseId}/${data.childId}`
    );
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};
export const getWeekOverview = async data => {
  try {
    const response = await ParentAuthorizedService.get(
      `${BASE_PATH}/week/overview/${data.courseId}/${data.week}/${data.childId}`
    );
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};
