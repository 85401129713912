import { t } from './types';

export const createSubscription = data => ({
  type: t.CREATE_SUBSCRIPTION,
  payload: {
    data,
  },
});
export const createSetupIntents = data => ({
  type: t.CREATE_SETUP_INTENTS,
  payload: {
    data,
  },
});
export const createTrialSubscription = data => ({
  type: t.CREATE_TRIAL_SUBSCRIPTION,
  payload: {
    data,
  },
});
export const setClientSecret = data => ({
  type: t.SET_CLIENT_SECRET,
  payload: {
    data,
  },
});
export const setSubscriptionId = data => ({
  type: t.SET_SUBSCRIPTION_ID,
  payload: {
    data,
  },
});
export const getProducts = data => ({
  type: t.GET_PRODUCTS,
  payload: {
    data,
  },
});
export const setPlan = data => ({
  type: t.SET_PLAN,
  payload: {
    data,
  },
});
export const setAllProducts = data => ({
  type: t.SET_PRODUCTS,
  payload: {
    data,
  },
});
export const cancelSubscription = data => ({
  type: t.CANCEL_SUBSCRIPTION,
  payload: {
    data,
  },
});
export const setPaymentLogs = data => ({
  type: t.SET_PAYMENT_LOGS,
  payload: {
    data,
  },
});
export const getPaymentLogs = id => ({
  type: t.GET_PAYMENT_LOGS,
  payload: {
    id,
  },
});
export const setIsLoading = data => ({
  type: t.SET_IS_LOADING_TRIAL,
  payload: {
    data,
  },
});
export const redeemCode = data => ({
  type: t.REDEEM_CODE,
  payload: { data },
});
