export const t = {
  GET_COURSES: 'GET_COURSES',
  SET_COURSES: 'SET_COURSES',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
};

export const getCourses = () => ({
  type: t.GET_COURSES,
});
export const setCourses = courses => ({
  type: t.SET_COURSES,
  courses,
});
export const getCurrentUser = userType => ({
  type: t.GET_CURRENT_USER,
  userType,
});
export const setCurrentUser = currentUser => ({
  type: t.SET_CURRENT_USER,
  currentUser,
});
