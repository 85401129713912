import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { cardElementStyle } from './CheckoutFormAppearance';
import '../payment.scss';

const TrialPaymentForm = ({ clientSecret, onSubmit }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);

    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      onSubmit(setupIntent.id);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement options={cardElementStyle} />
      <button disabled={!stripe || !elements} id="submit" className="buy-free-trial">
        <span id="button-text">Submit</span>
      </button>
      {errorMessage && <div className="payment-message">{errorMessage}</div>}
    </form>
  );
};

export default TrialPaymentForm;
