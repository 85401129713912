import React, { useState } from 'react';
import { Button } from 'antd';
import PausedCourseModal from './PausedCourseModal';

const ReactivateCourse = ({ paused, userRole, childGender, user }) => {
  const [pausedModalVisibility, setPausedModalVisibility] = useState(false);
  const openModal = () => {
    setPausedModalVisibility(true);
  };
  return (
    <>
      {paused && (
        <div className="reactivate-course-wrapper">
          <Button onClick={openModal}>Reactivate Course</Button>
        </div>
      )}
      <PausedCourseModal
        pausedModalVisibility={pausedModalVisibility}
        setPausedModalVisibility={setPausedModalVisibility}
        userRole={userRole}
        childGender={childGender}
        user={user}
      />
    </>
  );
};

export default ReactivateCourse;
