import React from 'react';
import DragAndDropContent from './DragAndDropContent';

const DragAndDropQuestions = ({ state, text }) => {
  return (
    <div>
      <div className="questions-wrapper">
        <div className="questions-content">
          {text?.map(textItem =>
            !textItem.text.includes('BLANK') ? (
              <p className="question-items">{textItem.text}</p>
            ) : (
              <div>
                {Object.keys(state).map(itemKey => {
                  if (state[itemKey].title !== 'Answers' && itemKey === textItem.id) {
                    return (
                      <div key={itemKey} className="column">
                        <h3>{state[itemKey].title}</h3>
                        <DragAndDropContent state={state} itemKey={itemKey} />
                      </div>
                    );
                  }
                })}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default DragAndDropQuestions;
