import React from 'react';
import { Button } from 'antd';
import '../childCourseQuizzes.scss';

const StandAloneQuizButtons = ({
  handleNext,
  handlePrevious,
  handleFinish,
  totalQuestions,
  currentQuestion,
  disabledNextCondition,
}) => {
  const nextButtonClickHandler = currentQuestion + 1 === totalQuestions ? handleFinish : handleNext;
  const previousButtonDisable = currentQuestion === 0;
  const nextButtonText = currentQuestion + 1 === totalQuestions ? 'Submit' : 'Next';
  return (
    <div className="standalone-question-buttons">
      {!previousButtonDisable && (
        <Button onClick={handlePrevious} className="standalone-previous">
          Previous
        </Button>
      )}
      <Button
        disabled={disabledNextCondition}
        onClick={nextButtonClickHandler}
        className="standalone-next"
      >
        {nextButtonText}
      </Button>
    </div>
  );
};

export default StandAloneQuizButtons;
