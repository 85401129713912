import React from 'react';
import logo from '../../images/footer/footer_logo_image.png';
import './customLoader.scss';
const CustomLoader = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="loader-wrapper">
          <div className="loader-border" />
          <img src={logo} className="loader" alt="loader-icon" />
        </div>
      )}
    </>
  );
};

export default CustomLoader;
