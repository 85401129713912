import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import classNames from 'classnames';
import { Layout as AntdLayout } from 'antd';

import { ParentRoute, ChildRoute } from '../../routing/index';
import ParentProfile from '../Parent/ParentProfile';
import AddChild from '../Parent/AddChild';
import ChildProfile from '../ChildProfile';
import CourseOverview from '../Parent/CourseOverview';
import ChildToDo from '../Parent/ChildToDo';
import EnrollChild from '../Parent/EnrollChild';
import ParentSettings from '../Parent/ParentSettings';
import Settings from '../Parent/ParentSettings/Settings';
import ChildDashboard from '../Child/ChildDashboard';
import ChangeParentPassword from '../Parent/ParentSettings/ChangeParentPassword';
import Payment from '../Payment';
import PaymentStatus from '../Payment/components/PaymentStatus';
import Logo from '../../images/footer/Logo.png';
import AccountInfo from './components/AccountInfo';
import Menu from './components/Menu';
import './layout.scss';
import LessonOverview from '../Parent/CourseOverview/LessonOverview';
import Breadcrumb from './components/Breadcrumb';
import Faq from '../Faq';
import EditChildProfile from '../Parent/EditChildProfile';
import TrialBanner from '../Payment/components/TrialBanner/index';
import Course from '../Course';
import WeekOverview from '../Course/components/items/WeekOverview/index';
import WeekList from '../Course/components/lists/WeekList/index';

const { Header, Content, Sider } = AntdLayout;

const Layout = ({ location, layoutMode, actions, user, children }) => {
  useEffect(() => {
    actions.getUser();
  }, [actions]);
  useEffect(() => {
    if (user && user.type === 'PARENT') {
      actions.getChildren();
    }
  }, [actions]);

  return (
    <AntdLayout className="layout-wrapper">
      <Sider width="300" className="layout-sider">
        <div className="layout-sider-content-wrapper">
          <div>
            <img className="layout-logo" src={Logo} />
            <Menu user={user} />
          </div>
          <TrialBanner />
        </div>
      </Sider>
      <AntdLayout>
        <Header
          className={classNames({
            'layout-header': true,
            'layout-header-black': layoutMode === 'dark',
          })}
        >
          <Breadcrumb />
          <AccountInfo actions={actions} user={user} layoutMode={layoutMode} children={children} />
        </Header>
        <Content className="layout-content">
          <Switch location={location}>
            <ParentRoute
              type="parent-route"
              path="/parent-profile"
              exact
              component={ParentProfile}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-profile/edit-child"
              exact
              component={EditChildProfile}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-profile/child-to-do/:id"
              exact
              component={ChildToDo}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-profile/enroll-child"
              exact
              component={EnrollChild}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-profile/lesson-overview"
              exact
              component={LessonOverview}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-profile/course-overview"
              exact
              component={CourseOverview}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-profile/settings"
              exact
              component={Settings}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-profile/settings/change-password"
              exact
              component={ChangeParentPassword}
            />
            <ParentRoute type="parent-route" path="/plans" exact component={Payment} />
            <ParentRoute
              type="parent-route"
              path="/payment-status"
              exact
              component={PaymentStatus}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-profile/child-profile/:childId"
              exact
              component={Course}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-profile/settings/:id"
              exact
              component={ParentSettings}
            />
            <ParentRoute type="parent-route" path="/parent-profile/faq" exact component={Faq} />
            <ParentRoute type="parent-route" path="/add-child" exact component={AddChild} />
            <ParentRoute
              type="parent-route"
              path="/parent-week-overview/:week/:courseId/:childId"
              exact
              component={WeekOverview}
            />
            <ParentRoute
              type="parent-route"
              path="/parent-week-list/:courseId/:childId"
              exact
              component={WeekList}
            />
            <ChildRoute type="child-route" path="/courses" exact component={Course} />
            <ChildRoute
              type="child-route"
              path="/child-week-overview/:week/:courseId/:childId"
              exact
              component={WeekOverview}
            />
            <ChildRoute
              type="child-route"
              path="/child-week-list/:courseId/:childId"
              exact
              component={WeekList}
            />
            <ChildRoute type="child-route" path="/child-profile" exact component={ChildDashboard} />
            <ChildRoute type="child-route" path="/profile" exact component={ChildProfile} />
          </Switch>
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
};
export default Layout;
