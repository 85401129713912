import React from 'react';
import { Collapse } from 'antd';
import { FAQContent } from './FAQContent';
import './faq.scss';

const { Panel } = Collapse;

const Faq = () => {
  const contentMapping = FAQContent.map(item => {
    if (item.subtitle) {
      return (
        <span key={item.key} className="faq-subtitle">
          {item.subtitle}
        </span>
      );
    }
    return (
      <Panel key={item.key} header={item.header}>
        <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
      </Panel>
    );
  });

  return (
    <div className="background">
      <div className="collapse-wrapper">
        <Collapse className="panel-items-wrapper">
          <span className="faq-title">FAQ</span>
          {contentMapping}
        </Collapse>
      </div>
    </div>
  );
};

export default Faq;
