/* eslint-disable import/no-anonymous-default-export */
import { t } from '../actions/auth';
const defaultState = {
  profiles: [],
  parent: null,
  isVerified: false,
  isProcessed: false,
  resetPasswordSuccessful: false,
  layoutMode: 'light',
  savedAccounts: null,
  user: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_PROFILES:
      return {
        ...state,
        profiles: action.profiles.filter(profile => profile.type === 'CHILD'),
        parent: action.profiles.find(profile => profile.type === 'PARENT'),
      };
    case t.SET_IS_VERIFIED:
      return {
        ...state,
        isVerified: action.payload.isVerified,
      };
    case t.SET_IS_PROCESSED:
      return {
        ...state,
        isProcessed: action.payload.isProcessed,
      };
    case t.SET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccessful: action.payload.isSuccessful,
      };
    case t.SET_LAYOUT_MODE:
      return {
        ...state,
        layoutMode: action.payload.mode,
      };
    case t.SET_SAVED_ACCOUNTS:
      return {
        ...state,
        savedAccounts: action.payload.accounts,
      };
    case t.REMOVE_SAVED_ACCOUNTS:
      return {
        ...state,
        savedAccounts: null,
      };
    case t.SET_USER:
      return {
        ...state,
        user: action.payload.userData,
      };
    default:
      return state;
  }
};
