import { all, call, takeLeading, put } from 'redux-saga/effects';
import { notification } from 'antd';
import { t } from '../actions/userPayment';

import {
  userSubscriptionStatus,
  createUserPayment,
  createPaymentLogs,
} from '../services/paymentService';
import { setUserPayment } from '../actions/userPayment';
import { setLayoutMode } from '../actions/auth';
import { setNewErrorNotification } from '../actions/errorNotification';
import { generalErrorMessage } from '../utils/constants';

function* getUserPaymentSaga(action) {
  const postData = action.payload.data;
  const res = yield call(userSubscriptionStatus, postData);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: 'Error getting user payment details',
      })
    );
  } else {
    yield put(setUserPayment(data));
  }
}
function* createUserPaymentSaga(action) {
  const postData = action.payload.data;
  const res = yield call(createUserPayment, postData);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: 'Error creating subscription',
      })
    );
  } else {
    notification.success({
      message: 'Subscription created successfully',
    });
    const paymentLogsData = {
      userId: postData.userId,
      userPaymentId: data.id,
      creatorId: postData.userId,
      platform: 'WEB',
      amount: 0,
      cancel: false,
    };
    yield call(createPaymentLogs, paymentLogsData);
  }
}

export default function* userPaymentSaga() {
  yield all([
    takeLeading(t.GET_USER_PAYMENT, getUserPaymentSaga),
    takeLeading(t.CREATE_USER_PAYMENT, createUserPaymentSaga),
  ]);
}
