import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { Form, Input, Checkbox } from 'antd';
import { passwordRules } from '../../../utils/inputRules';
import LockIcon from '../../../images/register/Lock.svg';
import { displayAvatar } from '../../../utils/helpers';

import '../login.scss';

const SavedAccount = ({
  account,
  removeSavedAccount,
  renderPasswordIcon,
  trackPixelEvent,
  keepUserLoggedInHandler,
  onFinish,
  layoutMode,
}) => {
  const loginWithSavedAccount = values => {
    onFinish({ ...values, emailOrUsername: account?.email || account?.username });
  };

  const handleTrackPixelEvent = () => {
    trackPixelEvent('ForgotPassword');
  };
  return (
    <div
      className={classNames({
        'login-form-box': true,
        'login-form-box-black': layoutMode === 'dark',
      })}
    >
      <Form name="login" onFinish={loginWithSavedAccount}>
        <div className="login-content-box">
          <div className="saved-account-wrapper">
            <div className="saved-account-avatar">
              <img alt="user-account" src={displayAvatar(account)} />
            </div>
            <div className="saved-account-name">
              <h5>{account?.firstName}</h5>
              <p>{account?.type?.toLowerCase()} Account</p>
            </div>
          </div>

          <div className="login-input-wrapper">
            <Form.Item name="password" rules={[passwordRules]}>
              <Input.Password
                prefix={<img className="login-input-icon" alt="lock-icon" src={LockIcon} />}
                placeholder={account?.type === 'CHILD' ? 'Pin' : 'Password'}
                iconRender={renderPasswordIcon}
              />
            </Form.Item>
          </div>
          <div className="login-checkbox-box">
            <Form.Item name="keep_loggedin">
              <Checkbox className="login-checkbox" onChange={keepUserLoggedInHandler}>
                Remember Me
              </Checkbox>
            </Form.Item>
            {account?.type === 'PARENT' && (
              <div>
                <NavLink
                  to="/reset-password"
                  className="login-forgot-password"
                  onClick={handleTrackPixelEvent}
                >
                  Forgot password?
                </NavLink>
              </div>
            )}
          </div>
        </div>
        <div className="login-content-box">
          <Form.Item className="center-content">
            <button className="login-button">Login</button>
          </Form.Item>
          <div className="center-content" onClick={removeSavedAccount}>
            <p className="back-to-login">Login to another account</p>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SavedAccount;
