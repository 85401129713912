export const t = {
  GET_USER_PAYMENT: 'GET_USER_PAYMENT',
  SET_USER_PAYMENT: 'SET_USER_PAYMENT',
  CREATE_USER_PAYMENT: 'CREATE_USER_PAYMENT',
};

export const createUserPayment = data => ({
  type: t.CREATE_USER_PAYMENT,
  payload: {
    data,
  },
});
export const getUserPayment = data => ({
  type: t.GET_USER_PAYMENT,
  payload: {
    data,
  },
});
export const setUserPayment = data => ({
  type: t.SET_USER_PAYMENT,
  payload: {
    data,
  },
});
