import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { letterForAnswers } from '../../../../../utils/helpers';
import CorrectAnswerOverview from '../../../../../images/quiz/correctAnswerOverview.svg';
import CorrectAnswerOverviewBlue from '../../../../../images/quiz/correctAnswerOverviewBlue.svg';
import IncorrectAnswerOverview from '../../../../../images/quiz/IncorrectAnswerOverview.svg';

import '../childCourseQuizzes.scss';

const OverviewScreen = ({ quizOverviewData, getOverviewData, quizId, userId, handleFinish }) => {
  useEffect(() => {
    if (!quizOverviewData) {
      getOverviewData({ quizId, userId });
    }
  }, [quizOverviewData]);

  const rightIcon = answer => {
    if (answer.submit && answer.correct) {
      return <img src={CorrectAnswerOverview} />;
    }
    if (answer.submit && !answer.correct) {
      return <img src={IncorrectAnswerOverview} />;
    }
    if (!answer.submit && answer.correct) {
      return <img src={CorrectAnswerOverviewBlue} />;
    }
  };
  const AnswerMapping = ({ answers }) => {
    const answer = useMemo(
      () =>
        answers?.map((answerItem, idx) => (
          <div
            key={answerItem?.id}
            className={classNames({
              'answer-wrapper': true,
              'answer-wrapper-light': !answerItem.submit && answerItem.correct,
              'answer-wrapper-opacity': !answerItem.correct && !answerItem.submit,
            })}
            answerItem
          >
            <div>
              <span className="answer-letter">{letterForAnswers(idx)}</span>
              <span className="answer-title">{answerItem?.value}</span>
            </div>
            {rightIcon(answerItem)}
          </div>
        )),
      [answers]
    );
    return answer;
  };

  const questionMapping = useMemo(
    () =>
      quizOverviewData?.quiz?.questions?.map((questionItem, index) => (
        <div key={questionItem?.id} className="question-wrapper">
          <span className="questions-title">{`${index + 1}. ${questionItem?.title}`}</span>
          <AnswerMapping answers={questionItem?.answers} />
        </div>
      )),
    [quizOverviewData?.quiz?.questions]
  );

  return (
    <div className="quiz-overview-wrapper">
      <div className="header-part">
        <span className="title">Quiz Review</span>
        <span className="description">{`You answered ${quizOverviewData?.quiz?.correctAnsweredQuestions}/${quizOverviewData?.quiz?.questionsCount} questions correctly, scoring ${quizOverviewData?.collectedPoints} points`}</span>
      </div>
      <div className="body-part">{questionMapping}</div>
      <div className="button-part">
        <Button className="quiz-overview-button" onClick={handleFinish}>
          Done
        </Button>
      </div>
    </div>
  );
};

export default OverviewScreen;
