import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { DragDropContext } from 'react-beautiful-dnd';
import JwtDecode from 'jwt-decode';

import { Button, Row } from 'antd';

import FinalScreen from '../../ChildLessonWatchNow/FinalScreen';
import DragAndDropAnswers from './DragAndDropAnswers';
import DragAndDropQuestions from './DragAndDropQuestions';
import '../childCourseQuizzes.scss';

const DragAndDropQuiz = ({ quiz, close, courseId, notFinalScreen, dragAndDropData, actions }) => {
  const [correctText, setCorrectText] = useState('');
  const [state, setState] = useState({});
  const [answersCopyState, setAnswersCopyState] = useState([]);
  const [nextOrFinishState, setNextOrFinishState] = useState('Next');
  const [confirm, setConfirm] = useState(false);

  const token = localStorage.getItem('child_profile_token');
  let decoded = JwtDecode(token);
  let answersCopy = [];

  const confirmAnswerHandler = () => {
    let newAnswersArray = [];
    let idx = 0;
    let copyText = [...dragAndDropData.text];
    answersCopyState.map(item => {
      if (item.correct) {
        newAnswersArray.push({
          value: item.value,
          order: item.order,
        });
      }
    });
    newAnswersArray.sort((a, b) => {
      return a.order - b.order;
    });
    const finishTitle = copyText.map(item => {
      let newItem = '';
      if (item.text === 'BLANK') {
        newItem = newAnswersArray[idx].value.toUpperCase();
        idx = idx + 1;
        return newItem;
      }
      return item.text;
    });
    setCorrectText(finishTitle.join(' '));
    setConfirm(true);

    dragAndDropData.questionNum + 1 === quiz?.questions?.length
      ? setNextOrFinishState('Finish')
      : setNextOrFinishState('Next');
  };

  const handleNext = () => {
    actions.dragAndDropConfirmAnswer({ state, dragAndDropData });
    setCorrectText('');
    setConfirm(false);
  };

  const handleDragEnd = ({ destination, source }) => {
    //checks if drag and drop is valid (if destination is invalid or destination already has item than function will stop executing)
    if (!destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }
    if (state[destination.droppableId].items.length > 0 && destination.droppableId !== 'Answers') {
      return;
    }

    // Creating a copy of item before removing it from state
    const itemCopy = { ...state[source.droppableId].items[source.index] };
    setState(prev => {
      prev = { ...prev };
      // Remove from previous items array
      prev[source.droppableId].items.splice(source.index, 1);

      // Adding to new items array location
      prev[destination.droppableId].items.splice(destination.index, 0, itemCopy);

      return prev;
    });
  };

  useEffect(() => {
    actions.dragAndDropController({
      dragAndDropData,
      quiz,
      quizId: quiz?.id,
      userId: decoded.user.id,
      courseId,
    });
  }, [dragAndDropData.questionNum]);

  useEffect(() => {
    if (answersCopy.length < 1) {
      answersCopy = [...dragAndDropData.answers];
      setAnswersCopyState(answersCopy);
    }
    //setup for answers
    setState({ Answers: { title: 'Answers', items: dragAndDropData.answers } });
    dragAndDropData.text.forEach(item => {
      if (item.text.includes('BLANK')) {
        setState(prevState => {
          return { ...prevState, [item.id]: { title: '', items: [] } };
        });
      }
    });
  }, [dragAndDropData.text]);

  return (
    <div
      className={classNames({
        'quiz-questions-wrapper-final': dragAndDropData.finalScreen,
        'quiz-questions-wrapper': !dragAndDropData.finalScreen,
      })}
    >
      {dragAndDropData.finalScreen ? (
        <div>
          <FinalScreen
            coins={dragAndDropData.coins}
            closeWatchNowModal={close}
            notFinalScreen={notFinalScreen}
            dragAndDrop={true}
          />
        </div>
      ) : (
        <div className="dragAndDrop-quizzes">
          <DragDropContext onDragEnd={handleDragEnd}>
            <DragAndDropQuestions text={dragAndDropData.text} state={state} />
            {correctText !== '' && (
              <div className="correct-text-wrapper">
                <p className="correct-text">Correct sentence:</p>
                <p className="correct-text">{correctText}</p>
              </div>
            )}
            <DragAndDropAnswers state={state} />
          </DragDropContext>
          <Row className="answer-buttons">
            {confirm ? (
              <Button onClick={handleNext}>{nextOrFinishState}</Button>
            ) : (
              <Button onClick={confirmAnswerHandler}>Confirm answer</Button>
            )}
          </Row>
        </div>
      )}
    </div>
  );
};

export default DragAndDropQuiz;
