import React from 'react';
import classNames from 'classnames';
import history from '../../../../history';
import RegisterHeading from '../../../Register/Heading';
import Artwork from '../../../../images/layout/Artwork.svg';
import '../../../Login/login.scss';
import '../../resetPassword.scss';

const SuccessEmailSendScreen = ({ layoutMode, title, subtitle }) => {
  const handlePushToLogin = () => history.push('/login');

  return (
    <div
      className={classNames({
        background: true,
        'background-black': layoutMode === 'dark',
      })}
    >
      <div className="login-wrapper">
        <RegisterHeading />
        <div
          className={classNames({
            'login-form-box': true,
            'login-form-box-black': layoutMode === 'dark',
          })}
        >
          <div className="login-dashboard-wrapper center-content">
            <p className="login-dashboard-subtitle-large">{title}</p>
            <p className="login-dashboard-subtitle-small">{subtitle}</p>
            <img className="envelop-img" src={Artwork} alt="envelop" />
          </div>
          <div className="login-content-box">
            <button onClick={handlePushToLogin} className="login-button">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessEmailSendScreen;
