import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { displayAvatar, stringLength } from '../../../utils/helpers';
import AccountIconDropdown from '../../../images/layout/AccountIconDropdown.svg';
import LogoutIcon from '../../../images/settings/logout.svg';
import '../layout.scss';

const AccountInfo = ({ user, layoutMode, actions, children }) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    if (user.type === 'PARENT' && children && children?.length < 1) {
      actions.logout();
    } else {
      actions.switchAccountLogout();
    }
    setOpen(false);
  };

  const toggleOpenChange = () => {
    setOpen(!open);
  };

  return (
    <div className="account-info-wrapper">
      <div className="account-info-left-wrapper">
        <div className="account-info-avatar">
          {user?.type === 'CHILD' && (
            <img className="account-info-avatar-img" src={displayAvatar(user)} />
          )}
        </div>

        <div className="account-info-text">
          <p className="heading">Assalamu Alaykum</p>
          <p className="first-name">{stringLength(user?.firstName, 10)}</p>
        </div>
      </div>
      {
        user?.type === 'CHILD' ? (
          <Button className="child-logout-button" onClick={hide}>
            <img src={LogoutIcon}/>
            Log out
          </Button>
        ) : (
      <Popover
        className="popover-wrapper"
        content={
          <Button className="popover-switch-account" onClick={hide}>
            Switch Accounts
          </Button>
        }
        trigger="click"
        open={open}
        onOpenChange={toggleOpenChange}
      >
        <img
          src={AccountIconDropdown}
          alt="dropdown-icon"
          onClick={toggleOpenChange}
          className="account-info-dropdown-icon"
        />
      </Popover>
        )
      }
    </div>
  );
};

export default AccountInfo;
