import history from '../history';

export const routeController = type => {
  if (localStorage.getItem('child_profile_token')) {
    if (window.location.pathname === '/courses' || type !== 'child-route') {
      history.push('/courses');
    }
  } else if (localStorage.getItem('parent_profile_token')) {
    if (window.location.pathname === '/parent-profile' || type !== 'parent-route') {
      history.push('/parent-profile');
    }
  } else if (localStorage.getItem('account_token')) {
    history.push('/login-dashboard');
  }
};
