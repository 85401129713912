import React from 'react';
import moment from 'moment';
import GradeIcon from '../../../../images/courses/grade-icon.svg';
import CheckIcon from '../../../../images/courses/Check.svg';
import CalendarIcon from '../../../../images/courses/calendar.svg';
import DoneIcon from '../../../../images/courses/doneIconLarge.svg';
import { getCourseWeeksLabelValue, stringLength } from '../../../../utils/helpers';
import { avgGradeToLetters } from '../../../../utils/helpers';

const CourseCard = ({ item, onCourseClick }) => {
  const courseStatus = status => {
    switch (status) {
      case 'Pending':
        return 'To-do';
      case 'On Track':
        return 'Done';
      default:
        return 'Paused';
    }
  };

  const informationContent = [
    {
      id: 1,
      label: 'Week',
      value: `${item.currentWeek}/${item.totalWeeks}`,
      icon: CalendarIcon,
    },
    {
      id: 2,
      label: 'Grade',
      value: avgGradeToLetters(item?.averageGrade),
      icon: GradeIcon,
    },
    {
      id: 3,
      label: 'Status',
      value: courseStatus(item.status),
      icon: courseStatus(item.status) === 'Done' ? DoneIcon : CheckIcon,
    },
  ];

  const informationItemMapping = informationContent.map(item => {
    return (
      <div key={item?.id} className="information-item-wrapper">
        <img src={item?.icon} alt="card-item-icon" />
        <div className="item-wrapper">
          <span className="label">{item.label}</span>
          <span className="value">{item.value}</span>
        </div>
      </div>
    );
  });
  return (
    <div onClick={onCourseClick(item.id, item.currentWeek)} className="course-card-wrapper">
      <img className="img" src={item?.courseImagesLandscape[0]?.url} alt='course-thumbnail' />
      <div className="content-container">
        <span className="title">{stringLength(item.title, 22)}</span>
        <div className="information">{informationItemMapping}</div>
      </div>
    </div>
  );
};

export default CourseCard;
