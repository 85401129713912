import React, { useMemo } from 'react';

import JwtDecode from 'jwt-decode';

import MultipleChoiceQuestionItem from '../../ChildLessonWatchNow/components/MultipleChoiceQuestionItem';
import { hasAtLeastTwo, isItemInArray } from '../../../../../utils/helpers';
import StandAloneQuizButtons from './StandAloneQuizButtons';
import '../childCourseQuizzes.scss';

const MultipleChoiceQuizQuestions = ({ quiz, questionNum, selectedAnswers, actions }) => {
  const token = localStorage.getItem('child_profile_token');
  let decoded = JwtDecode(token);

  const answersMapping = useMemo(() => {
    const updateSelectedAnswers = e => {
      if (isItemInArray(e.target.value, selectedAnswers)) {
        actions.setSelectedAnswers(selectedAnswers.filter(answer => answer !== e.target.value));
      } else {
        actions.setSelectedAnswers([...selectedAnswers, e.target.value]);
      }
    };

    return quiz?.questions[questionNum]?.answers.map((item, idx) => {
      return (
        <MultipleChoiceQuestionItem
          key={item?.id}
          item={item}
          isItemInArray={isItemInArray}
          selectedAnswers={selectedAnswers}
          updateSelectedAnswers={updateSelectedAnswers}
          standAlone
          idx={idx}
        />
      );
    });
  }, [questionNum, quiz?.questions, selectedAnswers, actions]);

  const handleNext = () => {
    actions.setQuestionNum(questionNum + 1);
  };

  const handlePrevious = () => {
    actions.setQuestionNum(questionNum - 1);
  };
  const handleFinish = () => {
    actions.multipleChoiceQuizController({
      answer: selectedAnswers,
      quiz,
      userId: decoded.user.id,
    });
  };

  const disabledNextCondition = hasAtLeastTwo(
    quiz?.questions[questionNum]?.id,
    selectedAnswers,
    'questionId'
  );

  return (
    <div className="quiz-questions-wrapper">
      <div className="quiz-answer-wrapper">
        {/* answers */}
        {answersMapping}
        <div className="quiz-answer-buttons">
          <StandAloneQuizButtons
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            totalQuestions={quiz?.questions?.length}
            currentQuestion={questionNum}
            handleFinish={handleFinish}
            disabledNextCondition={disabledNextCondition}
          />
        </div>
      </div>
    </div>
  );
};

export default MultipleChoiceQuizQuestions;
