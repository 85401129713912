import React, { useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import './editUserItem.scss';
import { Modal } from 'antd';
import EditUserForm from './EditUserForm';
import classNames from 'classnames';

const EditUserItem = ({
  icon,
  item,
  formFields,
  modalTitle,
  redirect,
  handleOnFinish,
  userId,
  ModalContent,
  disabled,
  inputRules,
  redBackground,
  modalContentProps,
  modalContentShow,
}) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    if (redirect) {
      redirect();
    } else {
      setVisible(true);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const ModalContentCondition = () => {
    return modalContentShow ? (
      <ModalContent {...modalContentProps} setVisible={setVisible} />
    ) : (
      <>
        <span className="edit-user-modal-title">{modalTitle}</span>
        <EditUserForm
          fields={formFields}
          icon={icon}
          handleOnFinish={handleOnFinish}
          userId={userId}
          handleCancel={handleCancel}
          inputRules={inputRules}
        />
      </>
    );
  };
  return (
    <>
      <div
        className={classNames({
          'edit-user-item-wrapper': true,
          'edit-user-item-wrapper-disabled': disabled,
          'edit-user-item-wrapper-red': redBackground,
        })}
        onClick={!disabled && showModal}
      >
        <div className="edit-user-item-section">
          <img className="edit-user-prefix-icon" src={icon} alt="edit-user-icon" />
          <span className="edit-user-item">{item}</span>
        </div>
        {!disabled && <RightOutlined />}
      </div>
      <Modal
        title={modalTitle}
        visible={visible}
        onCancel={handleCancel}
        footer
        destroyOnClose
        className="edit-user-modal"
      >
        <ModalContentCondition />
      </Modal>
    </>
  );
};

export default EditUserItem;
