import { all, call, takeLeading, put } from 'redux-saga/effects';

import { notification } from 'antd';

import {
  t,
  setLessonActivity,
  setActivities,
  addActivities,
  setHasMoreActivities,
  setActivityType,
  setCoursesOverviewForChild,
} from './actions';
import {
  getChildUpcomingQuizzes,
  getChildLessonActivity,
  getChildActivities,
} from '../../../../services/childService';
import history from '../../../../history';
import { activityTypes, generalErrorMessage } from '../../../../utils/constants';
import { getCoursesOverviewForChildData } from '../../../../services/coursesService';
import { setNewErrorNotification } from '../../../../actions/errorNotification';

function* getLessonActivity(action) {
  const lessonId = action.lesson;
  const id = history.location.pathname.split('/')[3];
  const res = yield call(getChildLessonActivity, id, lessonId);
  const { data, error } = res;
  if (data) {
    yield put(setLessonActivity(data));
  }
}

function* getCoursesOverviewForChild(action) {
  const { childId } = action.payload;
  const res = yield call(getCoursesOverviewForChildData, childId);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setCoursesOverviewForChild(data));
  }
}

function* getActivities(action) {
  const { childId, type, page, activityType } = action.payload;
  let activity = '';
  let response;
  if (type === 'upcoming' && activityType === 'lesson') {
    activity = activityTypes.upcomingLessons;
    response = yield call(getChildActivities, childId, {
      page,
      type,
    });
  } else if (type === 'upcoming' && activityType === 'quiz') {
    activity = activityTypes.uncompletedQuizzes;
    response = yield call(getChildUpcomingQuizzes, childId, {
      page,
    });
  } else if (type === 'past' && (activityType === 'lesson' || activityType === 'quiz')) {
    response = yield call(getChildActivities, childId, {
      page,
      type,
      activityType,
    });
    activity =
      activityType === 'lesson' ? activityTypes.pastLessons : activityTypes.completedQuizzes;
  }
  const { data, error } = response;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    const hasMore = data.length > 0;
    yield all([
      put(page === 1 ? setActivities(data) : addActivities(data)),
      put(setHasMoreActivities(hasMore)),
    ]);
    yield put(setActivityType(activity));
  }
}

export default function* childToDoSaga() {
  yield all([
    takeLeading(t.GET_LESSON_ACTIVITY, getLessonActivity),
    takeLeading(t.GET_ACTIVITIES, getActivities),
    takeLeading(t.GET_COURSES_OVERVIEW, getCoursesOverviewForChild),
  ]);
}
