import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { CloseOutlined } from '@ant-design/icons';
import JwtDecode from 'jwt-decode';

import { multipleChoiceQuizType, userTypes } from '../../../../utils/constants';
import FinalScreen from './FinalScreen';
import VideoPlayer from './components/VideoPlayer';
import LetsQuiz from './components/LetsQuiz';
import QuestionsScreen from './components/QuestionsScreen';
import MultipleChoiceQuestionsScreen from './components/MultipleChoiceQuestionsScreen';
import '../childDashboard.scss';
import { eventTrack, events } from '../../../../utils/googleAnalyticsEventTrack';

const userType = userTypes.child;

const ChildLessonWatchNow = ({
  activeLesson,
  isQuizCompleted,
  watchNowModal,
  closeWatchNowModal,
  actions,
  activeAnswer,
  answerValue,
  questionNum,
  videoNum,
  finalScreen,
  isQuiz,
  openLetsQuizScreen,
  coins,
  layoutMode,
  getCourseDetails,
}) => {
  const [answerAttempts, setAnswersAttempts] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);

  const token = localStorage.getItem('child_profile_token');
  const isMultipleChoiceQuiz = [
    activeLesson?.videos[videoNum - 1]?.quizzes[0]?.quizType === multipleChoiceQuizType,
  ].some(Boolean);

  let decoded;
  if (token) {
    decoded = JwtDecode(token);
  }

  // Check if the quiz for that video has already been done
  const onStartVideo = () => {
    if (activeLesson?.videos[videoNum]?.quizzes[0]) {
      actions.getQuizById(userType, activeLesson?.videos[videoNum]?.quizzes[0]?.id);
    }
    eventTrack(events.CHILD_EVENTS_CATEGORY, events.CHILD_EVENTS.WATCHED_VIDEO);
  };

  const setAnswersAttemptsOnEmpty = () => {
    setAnswersAttempts([]);
  };

  const afterVideoFinish = () => {
    if (!activeLesson?.videos[videoNum + 1]) {
      actions.postLessonWatched({
        lessonId: activeLesson?.id,
        userId: decoded.user.id,
      });
    }
    if (!activeLesson?.videos[videoNum]?.quizzes[0] || isQuizCompleted) {
      actions.incrementVideoNum();
    } else {
      actions.openLetsQuizScreen();
    }
  };

  // if answer is correct add 10 coin
  const isAnswerCorrect = item => {
    const attempt = {
      questionId: item.questionId,
      answerId: item.id,
    };
    setAnswersAttempts(answerAttempts => [...answerAttempts, attempt]);

    if (item.correct === true) {
      eventTrack(events.CHILD_EVENTS_CATEGORY, events.CHILD_EVENTS.CORRECT_ANSWER);
      actions.setCoins();
      setTotalPoints(totalPoints + 10);
    } else {
      eventTrack(events.CHILD_EVENTS_CATEGORY, events.CHILD_EVENTS.WRONG_ANSWER);
    }
  };

  // For each correct answer add 5 coin
  const isAnswerCorrectMultiple = item => {
    const answerIds = item.selectedAnswers.map(val => {
      return val.id;
    });

    const attempt = {
      questionId: item.questionId,
      answerIds,
    };
    setAnswersAttempts(answerAttempts => [...answerAttempts, attempt]);

    const correctAnswers = item.selectedAnswers.filter(answer => answer.correct);
    const incorrectAnswers = item.selectedAnswers.filter(answer => !answer.correct);

    if (incorrectAnswers.length === 0) {
      actions.setCoins();
      setTotalPoints(totalPoints + correctAnswers.length * 5);
    }
  };

  useEffect(() => {
    actions.watchNowController({
      questionNum,
      videoNum,
      activeLesson,
      finalScreen,
      answerAttempts,
      decoded,
      coins,
      isQuizCompleted,
      setAnswersAttemptsOnEmpty,
    });
  }, [answerValue, videoNum, questionNum, isQuiz]);

  const Content = useMemo(() => {
    // Let's quiz screen
    if (!isQuiz && !finalScreen && openLetsQuizScreen)
      return (
        <LetsQuiz
          activeLesson={activeLesson}
          closeLetsQuizScreen={actions.closeLetsQuizScreen}
          isQuiz={actions.isQuizAction}
          videoNum={videoNum}
          incrementVideoNum={actions.incrementVideoNum}
        />
      );
    // Video screen
    if (!isQuiz && !finalScreen && !openLetsQuizScreen)
      return (
        <VideoPlayer
          activeLesson={activeLesson}
          onStartVideo={onStartVideo}
          videoNum={videoNum}
          afterVideoFinish={afterVideoFinish}
        />
      );
    // Final screen
    if (finalScreen)
      return (
        <FinalScreen
          coins={totalPoints}
          setTotalPoints={setTotalPoints}
          closeWatchNowModal={closeWatchNowModal}
          notFinalScreen={actions.notFinalScreen}
          actions={actions}
          getCourseDetails={getCourseDetails}
        />
      );
    // Single answer Questions screen
    if (isQuiz && !finalScreen && !isMultipleChoiceQuiz)
      return (
        <QuestionsScreen
          questionNum={questionNum}
          activeLesson={activeLesson}
          videoNum={videoNum}
          actions={actions}
          answerValue={answerValue}
          activeAnswer={activeAnswer}
          isAnswerCorrect={isAnswerCorrect}
          answerAttempts={answerAttempts}
        />
      );
    // Multiple choice Questions screen
    if (isQuiz && !finalScreen && isMultipleChoiceQuiz)
      return (
        <MultipleChoiceQuestionsScreen
          questionNum={questionNum}
          activeLesson={activeLesson}
          videoNum={videoNum}
          actions={actions}
          isAnswerCorrectMultiple={isAnswerCorrectMultiple}
        />
      );
  }, [
    actions,
    activeAnswer,
    activeLesson,
    afterVideoFinish,
    answerValue,
    closeWatchNowModal,
    isQuiz,
    finalScreen,
    isMultipleChoiceQuiz,
    openLetsQuizScreen,
    questionNum,
    videoNum,
    answerAttempts,
    isAnswerCorrect,
    isAnswerCorrectMultiple,
    onStartVideo,
    totalPoints,
  ]);

  const CloseIcon = useMemo(() => {
    if (!finalScreen && !openLetsQuizScreen)
      return (
        <CloseOutlined
          className="close-icon"
          onClick={() => {
            closeWatchNowModal();
            actions.resetState();
          }}
        />
      );
  }, [finalScreen, openLetsQuizScreen]);

  if (!watchNowModal) return null;
  return (
    <div className="modal" id="modalContainer">
      <div className="overlay">
        <div
          id="watchNowModal"
          className={classNames({
            'watch-now-modal-content': !finalScreen || !openLetsQuizScreen,
            'watch-now-modal-background': true,
            'watch-now-modal-background-black': layoutMode === 'dark',
          })}
        >
          {CloseIcon}
          {Content}
        </div>
      </div>
    </div>
  );
};

export default ChildLessonWatchNow;
