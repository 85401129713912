import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import RegisterHeading from '../../../Register/Heading';
import ProfileCard from './component/ProfileCard';
import '../../login.scss';

const LoginDashboard = ({ actions, children, parent, layoutMode }) => {
  const accessToken = localStorage.getItem('account_token');
  const [selectedProfile, setSelectedProfile] = useState(null);
  const history = useHistory();

  useEffect(() => {
    actions.getChildren();
    actions.getParent();
  }, [actions]);

  const selectedProfileHandler = data => {
    setSelectedProfile(data);
  };

  const loginAsParent = () => {
    localStorage.setItem('parent_profile_token', accessToken);
    localStorage.setItem('parent_profile_refresh_token', accessToken);
    history.push('/parent-profile');
  };

  const childLoginByParent = data => {
    actions.loginByParent(data);
  };

  const onSubmit = () => {
    if (selectedProfile.email) {
      return loginAsParent();
    }
    childLoginByParent(selectedProfile);
  };
  useEffect(() => {
    if (children && children.length < 1) {
      loginAsParent();
    }
  }, [children]);
  return (
    <div
      className={classNames({
        background: true,
        'background-black': layoutMode === 'dark',
      })}
    >
      <div className="login-wrapper">
        <RegisterHeading />
        <div
          className={classNames({
            'login-form-box': true,
            'login-dashboard-wrapper': true,
            'login-form-box-black': layoutMode === 'dark',
          })}
        >
          <p className="login-dashboard-subtitle">
            Please select the account that you wish to enter with
          </p>
          <div className="login-content-box login-dashboard-content">
            <ProfileCard
              selectedProfile={selectedProfile}
              data={parent}
              selectedProfileHandler={selectedProfileHandler}
            />

            {children?.map(child => {
              return (
                <ProfileCard
                  selectedProfile={selectedProfile}
                  data={child}
                  selectedProfileHandler={selectedProfileHandler}
                />
              );
            })}
          </div>
          <div className="login-content-box">
            <div className="center-content">
              <button
                onClick={onSubmit}
                disabled={!selectedProfile}
                className={classNames({
                  'login-button': selectedProfile,
                  'login-button-disabled': !selectedProfile,
                })}
              >
                {selectedProfile
                  ? `Enter with ${selectedProfile.firstName}`
                  : 'Please Select An Account'}
              </button>
            </div>
            <div className="center-content" onClick={actions.logout}>
              <p className="back-to-login">Login with another account</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginDashboard;
