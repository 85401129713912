import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { LockFilled } from '@ant-design/icons';
import CourseDetails from './components/CourseDetails';
import ChildProfileHeader from '../../../ChildProfile/components/ChildProfileHeader';
import { useHistory } from 'react-router-dom';

const EnrolledCourseOverview = ({
  handleEnrollClick,
  child,
  course,
  isEnrolled,
  userPaymentStatus,
  actions,
}) => {
  const [disableProCourseEnrollment, setDisableProCourseEnrollment] = useState(false);
  const history = useHistory();

  useEffect(() => {
    actions.getUserPayment(child.parent.id);
  }, []);

  useEffect(() => {
    if (userPaymentStatus) {
      setDisableProCourseEnrollment(false);
    }
    if (!userPaymentStatus?.id && !course.free) {
      setDisableProCourseEnrollment(true);
    }
  }, [userPaymentStatus, course.free]);

  const redirectOnPayment = () => {
    history.push('/plans');
  };

  return (
    <div
      className={classNames({
        'not-enrolled-wrapper': !isEnrolled,
      })}
    >
      {isEnrolled ? (
        <ChildProfileHeader userData={child} userRole={'CHILD'} course={course} />
      ) : (
        <CourseDetails course={course} />
      )}

      {!isEnrolled && (
        <div className="enroll-button-wrapper">
          <Button
            onClick={handleEnrollClick}
            className="enrolled-course-enroll-button"
          >
            Enrol {child?.firstName}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EnrolledCourseOverview;
