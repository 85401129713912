import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import Confetti from 'react-confetti';
import Crown from '../../../../../images/quiz/Crown.svg';
import '../../childDashboard.scss';

const FinalScreen = ({ getCourseDetails, actions, coins, closeWatchNowModal, notFinalScreen, setTotalPoints }) => {
  const history = useHistory();

  const handleFinish = () => {
    closeWatchNowModal();
    notFinalScreen();
    actions.setActiveLesson(null);
    actions.setActiveQuiz(null);
    getCourseDetails();
    setTotalPoints(0);
  };
  const correctAnswersCount = Math.floor(coins / 10);

  return (
    <div className="lets-quiz-wrapper">
      <Confetti numberOfPieces={800} recycle={false} />
      <img src={Crown} alt="lets-quiz-img" />
      {coins > 0 ? (
        <>
          <span className="lets-quiz-text">You collected</span>
          <span className="lets-quiz-text-orange">{coins} Points</span>
          <span className="lets-quiz-text-gray">
            You answered {correctAnswersCount} questions correct
          </span>
        </>
      ) : (
        <span className="lets-quiz-text">Lesson completed</span>
      )}
      <Button className="lets-quiz-button" onClick={handleFinish}>
        Done
      </Button>
    </div>
  );
};

export default FinalScreen;
