import { all, call, takeLeading, put } from 'redux-saga/effects';
import { eventTrack, events } from '../../../../utils/googleAnalyticsEventTrack';
import { notification } from 'antd';
import { t, setAvatars, setUsernameTaken } from './actions';
import { avatarService } from '../../../../services/avatarService';
import { addChildService, getChildService } from '../../../../services/childService';
import { checkUsernameService } from '../../../../services/authService';
import { setNewErrorNotification } from '../../../../actions/errorNotification';
import history from '../../../../history';

function* getAvailableAvatars(action) {
  const { data } = yield call(avatarService);
  if (data) {
    yield put(setAvatars(data));
  }
}

function* addChild(action) {
  const postData = action.child;
  const res = yield call(addChildService, 'children', postData);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: 'Child Registration Error',
        description: 'This username is already taken!',
      })
    );
  } else {
    eventTrack(events.PARENT_EVENTS_CATEGORY, events.PARENT_EVENTS.FINISH_ADDING_CHILD);
    history.push({
      pathname: '/child-added',
      state: data,
    });
  }
}

function* checkUsername(action) {
  const username = action.username;
  const res = yield call(checkUsernameService, username);
  const { error } = res;
  if (error) {
    yield put(setUsernameTaken(true));
  } else {
    yield put(setUsernameTaken(false));
  }
}

function* getAddedChild(action) {
  const postData = action.id;
  const { data, error } = yield call(getChildService, 'children', postData);
  yield put(setAvatars(data));
}

export default function* childSaga() {
  yield all([
    takeLeading(t.GET_AVATARS, getAvailableAvatars),
    takeLeading(t.ADD_CHILD, addChild),
    takeLeading(t.GET_CHILD, getAddedChild),
    takeLeading(t.CHECK_USERNAME, checkUsername),
  ]);
}
