export const t = {
  GET_COURSE_OVERVIEW_BY_ID: 'GET_COURSE_OVERVIEW_BY_ID',
};

export const getCoursesOverviewForChildById = (childId, courseId) => ({
  type: t.GET_COURSE_OVERVIEW_BY_ID,
  payload: {
    childId,
    courseId,
  },
});
