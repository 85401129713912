import { all, call, takeLeading, put } from 'redux-saga/effects';
import { eventTrack, events } from '../utils/googleAnalyticsEventTrack';
import JwtDecode from 'jwt-decode';
import { notification } from 'antd';
import {
  t,
  setProfiles,
  setIsVerified,
  setResetPasswordSuccess,
  setIsProcessed,
  setUser,
  getUser,
} from '../actions/auth';
import { getUserPayment } from '../actions/userPayment';
import {
  authService,
  getProfiles,
  changePasswordService,
  loginByParentService,
  updateUserService,
  verifyUser,
} from '../services/authService';
import { deleteUserData, getUserData } from '../services/usersService';
import history from '../history';
import { setSavedAccountsSaga } from './index';
import { setIsLoading } from '../actions/customLoader';
import { getCurrentUser } from '../components/Child/Courses/modules/actions';
import { getCurrentChild, setChildren } from '../components/Parent/ParentProfile/modules/actions';
import { getPaymentLogs } from '../components/Payment/modules/actions';
import { setNewErrorNotification } from '../actions/errorNotification';
import { generalErrorMessage, webPlatform } from '../utils/constants';

function* registerUser(action) {
  const {
    payload: { userDetails },
  } = action;
  const postData = {
    email: userDetails.email.toLowerCase(),
    firstName: userDetails.name,
    lastName: userDetails.surname,
    password: userDetails.password,
    platform: webPlatform,
  };
  const res = yield call(authService, 'register', postData);
  const { error } = res;

  if (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    yield put(
      setNewErrorNotification({
        title: 'Invalid Registration',
        description: message,
      })
    );
  } else {
    notification.success({
      message: 'Registration success!',
      description: 'Email verification link sent on your email',
    });
    eventTrack(events.AUTH_EVENTS_CATEGORY, events.AUTH_EVENTS.END_REGISTRATION);
    history.push('/registration-success');
  }
}

function* verifyEmail(action) {
  const { token, email } = action;
  const postData = {
    email,
    token,
  };
  const res = yield call(authService, 'verify-email', postData);
  const { error } = res;

  if (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: message,
      })
    );
    yield put(setIsVerified(false));
  } else {
    notification.success({
      message: 'Congratulations your email has been verified',
      description: 'Login to your account!',
    });
    yield put(setIsVerified(true));
  }

  yield put(setIsProcessed(true));
}

function* sendResetPasswordEmail(action) {
  const { email } = action.payload;
  const postData = {
    email,
  };
  const res = yield call(authService, 'send-reset-password-email', postData);
  const { error } = res;

  if (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: message,
      })
    );
  } else {
    notification.success({
      message: 'Email sent!',
      description: 'Please check your email!',
    });
    history.push('/success-email-send-reset-password');
  }
}

function* resetPassword(action) {
  const res = yield call(authService, 'reset-password', action.payload);
  const { error } = res;

  if (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: message,
      })
    );
    yield put(setResetPasswordSuccess(false));
  } else {
    notification.success({
      message: 'Reset password success!',
      description: 'Login to your account!',
    });
    yield put(setResetPasswordSuccess(true));
  }
}

function* loginUser(action) {
  yield put(setIsLoading(true));
  const {
    payload: { userDetails },
  } = action;
  let postData = {};

  if (userDetails.username) {
    postData = {
      username: userDetails.username,
      password: userDetails.password,
      rememberMe: userDetails.keepUserLoggedIn,
    };
  } else {
    postData = {
      email: userDetails.email,
      password: userDetails.password,
      rememberMe: userDetails.keepUserLoggedIn,
    };
  }
  const res = yield call(authService, 'login', postData);
  yield put(setIsLoading(false));
  const notificationDescription = userDetails.username
    ? 'Incorrect Username or PIN'
    : 'Incorrect Email or Password';

  if (res.error) {
    yield put(
      setNewErrorNotification({
        title: 'Invalid Login',
        description: notificationDescription,
      })
    );
  } else {
    const decodedToken = JwtDecode(res.data.accessToken);

    const { user } = decodedToken;
    if (userDetails?.keepUserLoggedIn) {
      const userData = res.data.user;
      yield localStorage.setItem('saved-accounts', JSON.stringify({ ...userData }));
    }
    if (user.scope === 'PARENT') {
      localStorage.setItem('account_token', res.data.accessToken);
      localStorage.setItem('account_refresh_token', res.data.refreshToken);

      yield put(getUserPayment(user?.id));
      history.push('login-dashboard');
    } else if (user.scope === 'CHILD') {
      localStorage.setItem('child_profile_token', res.data.accessToken);
      localStorage.setItem('child_profile_refresh_token', res.data.refreshToken);

      history.push('courses');
    }
  }
}

function* loginByParentSaga(action) {
  const {
    payload: { userDetails },
  } = action;

  const postData = {
    username: userDetails.username,
    parentId: userDetails.parentId,
  };
  const res = yield call(loginByParentService, postData);

  if (res.error) {
    yield put(
      setNewErrorNotification({
        title: 'Invalid Login',
        description: 'Username and id does not match',
      })
    );
  } else {
    localStorage.setItem('child_profile_token', res.data.accessToken);
    localStorage.setItem('child_profile_refresh_token', res.data.refreshToken);
    history.push('courses');
  }
}

function* getAccountProfiles() {
  const { data } = yield call(getProfiles, 'profiles');
  if (data) {
    yield put(setProfiles(data));
  }
}

function* changePassword(action) {
  const { childId, oldPassword, newPassword } = action.payload;
  const postData = {
    oldPassword,
    newPassword,
  };
  if (childId) {
    postData.childId = childId;
  }
  const res = yield call(changePasswordService, 'change-password', postData);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: '',
        description: error.data.message,
      })
    );
  } else {
    notification.success({
      message: 'Password changed successfully!',
    });
    if (!childId) {
      localStorage.setItem('parent_profile_token', data.parent.accessToken);
      localStorage.setItem('parent_profile_refresh_token', data.parent.refreshToken);
      localStorage.setItem('account_token', data.accessToken);
      localStorage.setItem('account_refresh_token', data.refreshToken);
    }
  }
}
function* logoutUser() {
  let tempSavedAccounts;
  if (localStorage.getItem('saved-accounts')) {
    tempSavedAccounts = localStorage.getItem('saved-accounts');
  }
  yield put(setChildren(null));
  yield localStorage.clear();
  yield localStorage.removeItem('parent_profile_token');
  yield localStorage.removeItem('parent_profile_refresh_token');
  yield localStorage.removeItem('child_profile_token');
  yield localStorage.removeItem('child_profile_refresh_token');
  if (tempSavedAccounts) {
    yield localStorage.setItem('saved-accounts', tempSavedAccounts);
  }
  yield put(setProfiles([]));
  yield put(setUser(null));

  history.push('/login');
}

function* switchAccountLogoutSaga() {
  if (localStorage.getItem('account_token')) {
    yield localStorage.removeItem('parent_profile_token');
    yield localStorage.removeItem('parent_profile_refresh_token');
    yield localStorage.removeItem('child_profile_token');
    yield localStorage.removeItem('child_profile_refresh_token');
    history.push('/login-dashboard');
  } else {
    yield localStorage.removeItem('child_profile_token');
    yield localStorage.removeItem('child_profile_refresh_token');
    history.push('/login');
  }
}

function* deleteUser(action) {
  const { userId, isParent, email = '', password = '' } = action.payload;
  if (isParent) {
    const { error } = yield call(verifyUser, {
      email: email,
      password: password.password,
    });
    if (error) {
      yield put(
        setNewErrorNotification({
          title: '',
          description: 'Incorrect password',
        })
      );
    } else {
      yield call(deleteUserData, userId);
      eventTrack(events.AUTH_EVENTS_CATEGORY, events.AUTH_EVENTS.USER_DELETED);
      yield localStorage.clear();
      yield put(setProfiles([]));
      history.push('/deleted-user');
    }
  } else {
    const { error } = yield call(deleteUserData, userId);
    if (error) {
      yield put(
        setNewErrorNotification({
          title: '',
          description: 'Can not delete this user.',
        })
      );
    } else {
      notification.success({
        message: 'Successfully deleted!',
      });
      eventTrack(events.PARENT_EVENTS_CATEGORY, events.PARENT_EVENTS.CHILD_DELETED);
    }
  }
}

function* sendVerificationEmail(action) {
  const { email } = action.payload;
  const postData = {
    email,
  };
  const res = yield call(authService, 'send-verification-email', postData);
  const { error } = res;

  if (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: message,
      })
    );
  } else {
    notification.success({
      message: 'Email sent!',
      description: 'Please check your email!',
    });
  }
}

function* removeSavedAccountSaga() {
  yield localStorage.removeItem('saved-accounts');
}

export function* getUserSaga() {
  yield put(setIsLoading(true));
  const { data, error } = yield call(getUserData);
  yield put(setIsLoading(false));
  if (error) {
    yield put(
      setNewErrorNotification({
        title: 'Unable to retrieve user information.',
        description: error?.data?.message,
      })
    );
  } else {
    yield put(setUser(data));
    yield put(getUserPayment(data.parentId ? data.parentId : data.id));
    yield put(getPaymentLogs(data.parentId ? data.parentId : data.id));
  }
}

function* updateUserSaga(action) {
  yield put(setIsLoading(true));

  const { dataToEdit, id, childId } = action.payload.userData;
  const { error } = yield call(updateUserService, dataToEdit, id, childId);
  yield put(setIsLoading(false));

  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error?.data?.message,
      })
    );
  } else {
    if (childId) {
      yield put(getCurrentChild(childId));
    } else {
      yield put(getCurrentUser());
      yield put(getUser());
    }
  }
}

function* verifyUserSaga(action) {
  const userData = action.payload.userData;
  const { error } = yield call(verifyUser, userData);
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error?.data?.message,
      })
    );
  } else {
    notification.success({
      message: 'The user has been successfully verified',
    });
  }
}

export default function* authSaga() {
  yield all([
    takeLeading(t.REGISTER_USER, registerUser),
    takeLeading(t.LOGIN_USER, loginUser),
    takeLeading(t.GET_PROFILES, getAccountProfiles),
    takeLeading(t.CHANGE_PASSWORD, changePassword),
    takeLeading(t.LOGOUT, logoutUser),
    takeLeading(t.DELETE_USER, deleteUser),
    takeLeading(t.VERIFIY_EMAIL, verifyEmail),
    takeLeading(t.SEND_RESET_PASSWORD_EMAIL, sendResetPasswordEmail),
    takeLeading(t.RESET_PASSWORD, resetPassword),
    takeLeading(t.SEND_VERIFICATION_EMAIL, sendVerificationEmail),
    takeLeading(t.LOGIN_BY_PARENT, loginByParentSaga),
    takeLeading(t.SWITCH_ACCOUNT_LOGOUT, switchAccountLogoutSaga),
    takeLeading(t.REMOVE_SAVED_ACCOUNTS, removeSavedAccountSaga),
    takeLeading(t.GET_SAVED_ACCOUNTS, setSavedAccountsSaga),
    takeLeading(t.GET_USER, getUserSaga),
    takeLeading(t.UPDATE_USER, updateUserSaga),
    takeLeading(t.VERIFY_USER, verifyUserSaga),
  ]);
}
