import React from 'react';
import DragAndDropContent from './DragAndDropContent';

const DragAndDropAnswers = ({ state }) => {
  return (
    <div className="answers-wrapper">
      {Object.keys(state).map(itemKey => {
        if (state[itemKey].title === 'Answers') {
          return (
            <div key={itemKey} className={`answer-col`}>
              <DragAndDropContent state={state} itemKey={itemKey} answersColumn />
            </div>
          );
        }
      })}
    </div>
  );
};

export default DragAndDropAnswers;
