import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updatePoints, postAnswerQuiz } from '../ChildLessonWatchNow/modules/actions';
import {
  setAnswers,
  setText,
  setCoins,
  setQuestionNum,
  setAnswerAttempts,
  setFinalScreen,
  dragAndDropController,
  dragAndDropConfirmAnswer,
  resetState,
  quizController,
  setSubmittedAnswers,
  setAnswer,
  setActiveAnswer,
  getQuizOverviewData,
  setSelectedAnswers,
  multipleChoiceQuizController,
  setOverview,
} from './modules/actions';
import { notFinalScreen } from '../../ChildDashboard/ChildLessonWatchNow/modules/actions';
import { setActiveLesson, setActiveQuiz } from '../modules/actions';

const mapStateToProps = state => ({
  quizzes: state.ChildCourseQuizzesReducer.quizzes,
  answers: state.ChildCourseQuizzesReducer.answers,
  text: state.ChildCourseQuizzesReducer.text,
  coins: state.ChildCourseQuizzesReducer.coins,
  questionNum: state.ChildCourseQuizzesReducer.questionNum,
  answerAttempts: state.ChildCourseQuizzesReducer.answerAttempts,
  correctText: state.ChildCourseQuizzesReducer.correctText,
  finalScreen: state.ChildCourseQuizzesReducer.finalScreen,
  submittedAnswers: state.ChildCourseQuizzesReducer.submittedAnswers,
  layoutMode: state.AuthReducer.layoutMode,
  answer: state.ChildCourseQuizzesReducer.answer,
  activeAnswer: state.ChildCourseQuizzesReducer.activeAnswer,
  quizOverviewData: state.ChildCourseQuizzesReducer.quizOverviewData,
  selectedAnswers: state.ChildCourseQuizzesReducer.selectedAnswers,
  overview: state.ChildCourseQuizzesReducer.overview,
  child: state.AuthReducer.user
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updatePoints,
      postAnswerQuiz,
      notFinalScreen,
      setAnswers,
      setText,
      setCoins,
      setQuestionNum,
      setAnswerAttempts,
      setFinalScreen,
      dragAndDropController,
      dragAndDropConfirmAnswer,
      resetState,
      setActiveLesson,
      setActiveQuiz,
      quizController,
      setSubmittedAnswers,
      setAnswer,
      setActiveAnswer,
      getQuizOverviewData,
      setSelectedAnswers,
      multipleChoiceQuizController,
      setOverview,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
