import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUser } from '../../actions/auth';
import { switchAccountLogout, logout } from '../../actions/auth';
import { getUserPayment } from '../../actions/userPayment';
import { getChildren } from '../Parent/ParentProfile/modules/actions';

const mapStateToProps = state => ({
  layoutMode: state.AuthReducer.layoutMode,
  user: state.AuthReducer.user,
  userPaymentStatus: state.userPaymentReducer.userPaymentStatus,
  children: state.ParentReducer.children,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUser,
      switchAccountLogout,
      logout,
      getUserPayment,
      getChildren,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
