import React from 'react';
import FreeTrialTopImage from '../../../images/payment/BG.svg';
import Yusuf from '../../../images/payment/Yusuf.svg';
import Check from '../../../images/payment/Check.svg';
import Calendar from '../../../images/payment/Week.svg';
import Video from '../../../images/payment/Video.svg';
import Age from '../../../images/payment/Age.svg';
import Logo from '../../../images/footer/Logo.png';

import '../payment.scss';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const FeaturesList = ({ title, icon }) => (
  <div className="free-trial-features-wrapper">
    <div>
      <img className="free-trial-prefix-icon" src={icon} alt="prefix-icon" />
      <span>{title}</span>
    </div>
    <img className="free-trial-sufix-icon" src={Check} alt="suffix-icon" />
  </div>
);
const FreeTrial = ({ createUserPayment, trial }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <div className="free-trial-wrapper">
      <img className="free-trial-top-image" src={FreeTrialTopImage} alt="free-trial-top-img" />
      <img className="free-trial-yusuf" src={Yusuf} alt="yusuf-img" />
      <div className="free-trial-logo">
        <div className="pro-logo">
          <img src={Logo} alt="plus-logo" />
          <span className="pro-logo-plus-mark">PLUS</span>
        </div>
        <span className="description">Join now to get full access to:</span>
      </div>
      <div className="features-list">
        <FeaturesList title="Access to exclusive courses" icon={Video} />
        <FeaturesList title="Add unlimited children" icon={Age} />
        <FeaturesList title="Flexible learning" icon={Calendar} />
        <Button onClick={createUserPayment} className="free-trial-button">
          {trial ? 'Get My 2 Weeks Free Trial' : 'Next'}
        </Button>
        <span className="cancel-free-trial" onClick={goBack}>
          No thanks
        </span>
      </div>
    </div>
  );
};

export default FreeTrial;
