import { all, call, takeLeading, put } from 'redux-saga/effects';
import { notification } from 'antd';
import { t } from './types';

import {
  createSubscriptionService,
  getProducts,
  cancelSubscriptionService,
  getPaymentLogsByUserId,
  createTrialSubscriptionService,
  createSetupIntentsService,
  cancelFreeSubscription,
  redeemCode,
} from '../../../services/paymentService';
import {
  setClientSecret,
  setSubscriptionId,
  setPlan,
  setAllProducts,
  setPaymentLogs,
  setIsLoading,
} from './actions';
import { verifyUser } from '../../../services/authService';
import history from '../../../history';
import { setNewErrorNotification } from '../../../actions/errorNotification';
import { generalErrorMessage } from '../../../utils/constants';

function* createSubscriptionSaga(action) {
  const postData = action.payload.data;
  const res = yield call(createSubscriptionService, postData);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: 'Creating subscription error',
        description: 'Error occurred while creating subscription',
      })
    );
  } else {
    yield put(setClientSecret(data.clientSecret));
    yield put(setSubscriptionId(data.subscriptionId));
    yield put(setPlan(data.plan));
  }
}
function* createTrialSubscriptionSaga(action) {
  const postData = action.payload.data;
  const res = yield call(createTrialSubscriptionService, postData);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: 'Creating trial subscription error',
        description: 'Error occurred while creating subscription',
      })
    );
  } else {
    yield put(setSubscriptionId(data.subscriptionId));
    yield put(setPlan(data.plan));
  }
}
function* createSetupIntentsSaga(action) {
  const postData = action.payload.data;
  const res = yield call(createSetupIntentsService, postData);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: 'Creating subscription error',
        description: 'Error occurred while creating subscription',
      })
    );
  } else {
    yield put(setClientSecret(data.clientSecret));
  }
}
function* getAllProducts() {
  const res = yield call(getProducts);
  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: 'Error getting products',
        description: 'Error occurred while getting products',
      })
    );
  } else {
    yield put(setAllProducts(data));
  }
}
function* cancelSubscriptionSaga(action) {
  const postData = action.payload.data;
  const verifyUserDetails = yield call(verifyUser, {
    email: postData.email,
    password: postData.password,
  });
  if (verifyUserDetails.data?.verify) {
    let res;
    if (postData.plan === 'TRIAL') {
      res = yield call(cancelFreeSubscription, postData);
    } else {
      res = yield call(cancelSubscriptionService, postData);
    }
    const { error } = res;
    if (error) {
      yield put(
        setNewErrorNotification({
          title: 'Error canceling subscription',
          description: '',
        })
      );
    } else {
      notification.success({
        message: 'Subscription canceled successfully',
      });
    }
  } else {
    yield put(
      setNewErrorNotification({
        title: 'Incorrect Password',
        description: '',
      })
    );
  }
}
function* getPaymentLogsByUserIdSaga(action) {
  try {
    const res = yield call(getPaymentLogsByUserId, action.payload.id);
    const { data } = res;
    yield put(setPaymentLogs(data));
  } catch (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: '',
      })
    );
  } finally {
    yield put(setIsLoading(false));
  }
}
function* redeemCodeSaga(action) {
  const res = yield call(redeemCode, action.payload.data);
  const { data, error } = res;
  if (data) {
    yield put(setPaymentLogs(data));
  }
  if (error) {
    yield put(
      setNewErrorNotification({
        title: 'Invalid code',
        description: '',
      })
    );
  } else {
    history.push('/payment-status');
  }
}

export default function* paymentSaga() {
  yield all([
    takeLeading(t.CREATE_SUBSCRIPTION, createSubscriptionSaga),
    takeLeading(t.GET_PRODUCTS, getAllProducts),
    takeLeading(t.CANCEL_SUBSCRIPTION, cancelSubscriptionSaga),
    takeLeading(t.GET_PAYMENT_LOGS, getPaymentLogsByUserIdSaga),
    takeLeading(t.CREATE_SETUP_INTENTS, createSetupIntentsSaga),
    takeLeading(t.CREATE_TRIAL_SUBSCRIPTION, createTrialSubscriptionSaga),
    takeLeading(t.REDEEM_CODE, redeemCodeSaga),
  ]);
}
