import React from 'react';
import { Button } from 'antd';
import LetsQuizIcon from '../../../../../images/quiz/LetsQuizIcon.svg';

const LetsQuiz = ({ closeLetsQuizScreen, isQuiz, incrementVideoNum }) => {
  const handleSubmit = () => {
    isQuiz();
    incrementVideoNum();
    closeLetsQuizScreen();
  };

  return (
    <div className="lets-quiz-wrapper">
      <span className="lets-quiz-text">Let’s see how much you learned</span>
      <img src={LetsQuizIcon} alt="lets-quiz-img" />
      <span className="lets-quiz-text">Time for a</span>
      <span className="lets-quiz-text-orange">Post Lesson Quiz</span>
      <Button className="lets-quiz-button" onClick={handleSubmit}>
        Let's Go!
      </Button>
    </div>
  );
};

export default LetsQuiz;
