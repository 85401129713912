import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import '../childCourseQuizzes.scss';

const DragAndDropContent = ({ itemKey, state, answersColumn }) => {
  return (
    <Droppable droppableId={itemKey}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={answersColumn ? `droppable-answer-col answer-item` : `droppable-col `}
          >
            {state[itemKey].items.map((el, index) => {
              return (
                <Draggable key={el.id} index={index} draggableId={el.id}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        className={`item ${snapshot.isDragging && 'dragging'}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {el.value}
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

export default DragAndDropContent;
