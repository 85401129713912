import React from 'react';

import { Row, Button } from 'antd';
import { CaretRightOutlined, CloseOutlined } from '@ant-design/icons';

import NoThumbnail from '../../../../images/child/no-thumbnail.png';

import '../childDashboard.scss';

const ChildLessonDetails = props => {
  const { activeLesson, lessonModal, openWatchNowModal, isParentView, closeLessonModal } = props;

  if (!lessonModal) return null;

  return (
    <div className="modal" id="modalContainer">
      <div className="overlay">
        <div id="lessonModal" className="lesson-modal-content">
          <Row className="modal-title">
            {activeLesson?.title}
            <CloseOutlined onClick={closeLessonModal} />
          </Row>
          <div className="see-details-modal-top-part">
            <div className="see-details-modal-top-part-img">
              {activeLesson?.lessonImages[0]?.url ? (
                <img
                  src={activeLesson?.lessonImages[activeLesson?.lessonImages?.length - 1]?.url}
                  alt="no-thumbnail"
                  className="thumbnail-image-modal"
                />
              ) : (
                <img src={NoThumbnail} alt="no-thumbnail" className="thumbnail-image-modal" />
              )}
            </div>
            <div className="see-details-modal-top-part-text">
              <Row className="lesson-see-details-title">Lesson overview</Row>
              <Row className="lesson-details-description1">{activeLesson?.description}</Row>
            </div>
          </div>
          <div className="see-details-modal-bottom-part">
            {!isParentView && activeLesson?.videos?.length > 0 && (
              <Button
                className="see-details-watch-now-button"
                onClick={() => {
                  openWatchNowModal(activeLesson);
                }}
              >
                <CaretRightOutlined className="see-details-watch-now-icon" />
                Watch now
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChildLessonDetails;
