import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import ChildrenListItem from './ChildrenList/ChildrenListItem';
import AddChild from '../AddChild';

import './parentProfile.scss';
import { useLocation } from 'react-router-dom';

const ParentProfile = props => {
  const { actions, user, childAndActivities } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state } = useLocation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    actions.getUser();
  }, []);

  useEffect(() => {
    if (user) {
      actions.getChildAndActivities(user.id);
    }
  }, [user]);

  useEffect(() => {
    if (state?.openModal) {
      showModal();
    }
  }, []);

  const childListItem = useMemo(
    () =>
      childAndActivities?.map(child => (
        <ChildrenListItem
          key={child?.child?.id}
          child={child}
          setChosenChild={actions.setChosenChild}
        />
      )),
    [childAndActivities, actions.setChosenChild]
  );

  return (
    <div className="background">
      <div className="parent-profile-list-of-children-wrapper">
        <Modal
          className="add-child-modal"
          visible={isModalOpen}
          footer={null}
          onCancel={handleCancel}
          destroyOnClose={true}
        >
          <AddChild />
        </Modal>
        <p className="my-children-count">
          My Children <span>({childAndActivities?.length})</span>
        </p>

        <div className="children-list">
          {childListItem}
          <button onClick={showModal} type="button" className="add-child-button">
            <PlusCircleOutlined className="add-child-button-icon" />
            Add child
          </button>
        </div>
      </div>
    </div>
  );
};

export default ParentProfile;
