import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAvatars, addNewChild, checkUsername, setUsernameTaken } from './modules/actions';
const mapStateToProps = state => ({
  avatars: state.AddChildReducer.avatars,
  usernameTaken: state.AddChildReducer.usernameTaken,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAvatars,
      addNewChild,
      checkUsername,
      setUsernameTaken,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
