import React from 'react';
import '../../style.scss';

const CourseHeader = ({ title }) => {
  return (
    <div className="course-header">
      <span className="title">{title}</span>
      <span className="schedule">Course Schedule</span>
    </div>
  );
};

export default CourseHeader;
