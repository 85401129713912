export const t = {
  GET_COURSES_LIST: 'GET_COURSES_LIST',
  SET_COURSES_LIST: 'SET_COURSES_LIST',
  GET_WEEKS_LIST: 'GET_WEEKS_LIST',
  SET_WEEKS_LIST: 'SET_WEEKS_LIST',
  GET_WEEK_OVERVIEW: 'GET_WEEK_OVERVIEW',
  SET_WEEK_OVERVIEW: 'SET_WEEK_OVERVIEW',
  GET_CHILD_PROFILE_ACTION: 'GET_CHILD_PROFILE_ACTION',
  SET_CHILD_PROFILE_ACTION: 'SET_CHILD_PROFILE_ACTION',
  START_LESSON: 'START_LESSON',
  START_QUIZ: 'START_QUIZ',
};

export const getWeeksList = (courseId, childId) => ({
  type: t.GET_WEEKS_LIST,
  payload: { courseId, childId },
});
export const setWeeksList = data => ({
  type: t.SET_WEEKS_LIST,
  payload: { data },
});
export const getCoursesList = (childId, role) => ({
  type: t.GET_COURSES_LIST,
  payload: { childId, role },
});
export const setCoursesList = data => ({
  type: t.SET_COURSES_LIST,
  payload: { data },
});
export const getWeekOverview = (courseId, week, childId) => ({
  type: t.GET_WEEK_OVERVIEW,
  payload: { courseId, week, childId },
});
export const setWeekOverview = data => ({
  type: t.SET_WEEK_OVERVIEW,
  payload: { data },
});
export const getChildProfileAction = (childId, role) => ({
  type: t.GET_CHILD_PROFILE_ACTION,
  payload: { childId, role },
});
export const setChildProfileAction = data => ({
  type: t.SET_CHILD_PROFILE_ACTION,
  payload: { data },
});
export const startLesson = id => ({
  type: t.START_LESSON,
  payload: { id },
});
export const startQuiz = id => ({
  type: t.START_QUIZ,
  payload: { id },
});
