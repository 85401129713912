import { all, takeLeading, put, call } from 'redux-saga/effects';
import { t } from './types';
import { notification } from 'antd';

import {
  setFinalScreen,
  setAnswers,
  setText,
  setAnswerAttempts,
  setQuestionNum,
  setCoins,
  resetAnswerAttempts,
  setSubmittedAnswers,
  setAnswer,
  setActiveAnswer,
  setQuizOverviewData,
} from './actions';
import { postAnswerQuiz, updatePoints } from '../../ChildLessonWatchNow/modules/actions';
import { eventTrack, events } from '../../../../../utils/googleAnalyticsEventTrack';
import { getQuizOverview } from '../../../../../services/quizService';
import { setNewErrorNotification } from '../../../../../actions/errorNotification';
import { generalErrorMessage } from '../../../../../utils/constants';

function* dragAndDropControllerSaga(action) {
  const { dragAndDropData, quiz, quizId, userId, courseId } = action.payload.data;

  // If quiz have no more questions left
  if (dragAndDropData.questionNum === quiz?.questions?.length) {
    yield put(setFinalScreen(true));
    yield put(
      postAnswerQuiz({
        quizId: quizId,
        answerAttempts: dragAndDropData.answerAttempts,
      })
    );
    yield put(resetAnswerAttempts());
    yield put(
      updatePoints({
        userId: userId,
        courseId: courseId,
        points: dragAndDropData.coins,
      })
    );
  } else {
    // setup for questions
    const data = quiz;
    yield put(setAnswers(data.questions[dragAndDropData.questionNum].answers));
    const questionData = data.questions[dragAndDropData.questionNum].title.split(' ');
    const questionDataWithId = questionData.map((item, questionIndex) => {
      return {
        text: item,
        id: questionIndex.toString(),
      };
    });
    yield put(setText(questionDataWithId));
  }
}

function* dragAndDropConfirmAnswerSaga(action) {
  const { state, dragAndDropData } = action.payload.data;

  const ordered = Object.keys(state)
    .sort()
    .reduce((obj, key) => {
      obj[key] = state[key];
      return obj;
    }, {});
  const answerArray = [];

  for (const [key, value] of Object.entries(ordered)) {
    if (key !== 'Answers') {
      const newObject = {
        key: key,
        value: value?.items,
      };
      answerArray.push(newObject);
    }
  }
  let counter = 0;
  // make the same order of keys and item order so that they can be compared
  for (let i = 0; i < answerArray?.length; i++) {
    answerArray[i].key = counter;
    counter++;
  }
  const numOfItems = answerArray.length;
  let numOfCorrectAnswers = 0;
  const answerIds = [];
  let questionId = '';

  answerArray?.map(item => {
    answerIds.push(item?.value[0]?.id);
    questionId = item?.value[0]?.questionId;
    if (
      item?.key?.toString() === item?.value[0]?.order?.toString() &&
      item?.value[0]?.correct === true
    ) {
      numOfCorrectAnswers = numOfCorrectAnswers + 1;
    }
  });

  if (numOfItems === numOfCorrectAnswers) {
    yield put(setCoins(dragAndDropData.coins + 15));
  }

  yield put(setQuestionNum(dragAndDropData.questionNum + 1));
  const attempt = {
    questionId,
    answerIds,
  };
  yield put(setAnswerAttempts(attempt));
}

const sendCorrectAndIncorrectAnswerEvent = async arr => {
  for (const item of arr) {
    if (item.correct) {
      eventTrack(events.CHILD_EVENTS_CATEGORY, events.CHILD_EVENTS.CORRECT_ANSWER);
    } else {
      eventTrack(events.CHILD_EVENTS_CATEGORY, events.CHILD_EVENTS.WRONG_ANSWER);
    }
  }
};

function* addOrRemoveAnswer(findAnswer, findAnswerCondition, answer, submittedAnswers) {
  if (findAnswer?.id !== answer?.id) {
    let submittedAnswersNew = submittedAnswers;

    if (findAnswerCondition) {
      submittedAnswersNew = submittedAnswers.filter(
        item => item?.questionId !== findAnswer?.questionId
      );
    }
    yield put(setSubmittedAnswers([...submittedAnswersNew, answer]));
  }
}

function* quizControllerNext(
  findAnswerNext,
  findAnswerPrev,
  submittedAnswers,
  quiz,
  questionNum,
  answer,
  activeAnswerIndex
) {
  findAnswerNext = submittedAnswers?.find(
    item => item.questionId === quiz?.questions[questionNum + 1]?.id
  );
  findAnswerPrev = submittedAnswers?.find(
    item => item.questionId === quiz?.questions[questionNum]?.id
  );
  yield put(setQuestionNum(questionNum + 1));
  if (findAnswerNext) {
    yield put(setAnswer(findAnswerNext));
  } else {
    yield put(setAnswer(null));
  }

  yield* addOrRemoveAnswer(
    findAnswerPrev,
    findAnswerPrev,
    answer,
    submittedAnswers,
    activeAnswerIndex
  );
}

function* quizControllerPrev(
  findAnswerNext,
  findAnswerPrev,
  submittedAnswers,
  quiz,
  questionNum,
  answer
) {
  findAnswerPrev = submittedAnswers?.find(
    item => item.questionId === quiz?.questions[questionNum - 1].id
  );
  findAnswerNext = submittedAnswers?.find(
    item => item.questionId === quiz?.questions[questionNum].id
  );
  yield* addOrRemoveAnswer(findAnswerNext, findAnswerPrev, answer, submittedAnswers);
  yield put(setQuestionNum(questionNum - 1));
  yield put(setAnswer(findAnswerPrev));
}

function* quizControllerFinish(
  userId,
  submittedAnswers,
  quiz,
  questionNum,
  answer,
  activeAnswerIndex
) {
  let newSubmittedAnswers;
  const lastQuestionCondition = submittedAnswers?.find(
    item => item.questionId === quiz?.questions[questionNum].id
  );
  if (lastQuestionCondition) {
    newSubmittedAnswers = submittedAnswers;
  } else {
    newSubmittedAnswers = [...submittedAnswers, { ...answer, idx: activeAnswerIndex }];
  }
  let coins = 0;
  const answerAttempts = newSubmittedAnswers.map(item => {
    if (item.correct === true) {
      coins += 10;
    }
    const answerAttempt = {
      questionId: item.questionId,
      answerId: item.id,
    };
    return answerAttempt;
  });
  sendCorrectAndIncorrectAnswerEvent(newSubmittedAnswers);
  yield put(setCoins(coins));
  yield put(postAnswerQuiz({ quizId: quiz?.id, answerAttempts: answerAttempts }));
  yield put(
    updatePoints({
      userId: userId,
      courseId: quiz?.courseId,
      points: coins,
    })
  );
  yield put(setFinalScreen(true));
}

function* quizControllerSaga(action) {
  const { state, answer, activeAnswerIndex, questionNum, submittedAnswers, quiz, userId } =
    action.payload.data;

  let findAnswerNext = null;
  let findAnswerPrev = null;

  switch (state) {
    case 'next':
      yield* quizControllerNext(
        findAnswerNext,
        findAnswerPrev,
        submittedAnswers,
        quiz,
        questionNum,
        answer,
        activeAnswerIndex
      );
      break;
    case 'prev':
      yield* quizControllerPrev(
        findAnswerNext,
        findAnswerPrev,
        submittedAnswers,
        quiz,
        questionNum,
        answer,
        activeAnswerIndex
      );

      break;
    case 'finish':
      yield* quizControllerFinish(
        userId,
        submittedAnswers,
        quiz,
        questionNum,
        answer,
        activeAnswerIndex
      );

      break;
    default:
      break;
  }
}

function* getQuizOverviewDataSaga(action) {
  try {
    const res = yield call(getQuizOverview, action.payload.data);

    const { data } = res;

    if (data) {
      yield put(setQuizOverviewData(data));
    } 
  } catch (error) {
    yield put(
      setNewErrorNotification({
        title: 'Unable to retrieve quiz overview data',
      })
    );
  }
}

function* multipleChoiceQuizControllerSaga(action) {
  const { answer, quiz, userId } = action.payload.data;

  // Group answers by questionId
  const answerAttempt = answer.reduce((acc, cur) => {
    acc[cur.questionId] = [...(acc[cur.questionId] || []), cur];
    return acc;
  }, {});

  let coins = 0;

  sendCorrectAndIncorrectAnswerEvent(answer);

  // Calculate coins earned based on correct answers
  Object.values(answerAttempt).forEach(attempt => {
    const incorrect = attempt.some(e => !e.correct);
    if (!incorrect) {
      coins += 5 * attempt.length;
    }
  });

  const answerAttempts = Object.entries(answerAttempt).map(([questionId, attempt]) => ({
    questionId,
    answerIds: attempt.map(e => e.id),
  }));

  yield put(setCoins(coins));
  yield put(postAnswerQuiz({ quizId: quiz?.id, answerAttempts: answerAttempts }));
  yield put(
    updatePoints({
      userId: userId,
      courseId: quiz?.courseId,
      points: coins,
    })
  );
  yield put(setFinalScreen(true));
}

export default function* courseQuizzesSaga() {
  yield all([
    takeLeading(t.DRAG_AND_DROP_CONTROLLER, dragAndDropControllerSaga),
    takeLeading(t.DRAG_AND_DROP_CONFIRM_ANSWER, dragAndDropConfirmAnswerSaga),
    takeLeading(t.QUIZ_CONTROLLER, quizControllerSaga),
    takeLeading(t.GET_QUIZ_OVERVIEW_DATA, getQuizOverviewDataSaga),
    takeLeading(t.MULTIPLE_CHOICE_QUIZ_CONTROLLER, multipleChoiceQuizControllerSaga),
  ]);
}
