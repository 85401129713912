import { ParentAuthorizedService } from './apiService';

export const createSubscriptionService = async data =>
  ParentAuthorizedService.post(`/create-subscription`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
export const createTrialSubscriptionService = async data =>
  ParentAuthorizedService.post(`/create-trial-subscription`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
export const createSetupIntentsService = async data =>
  ParentAuthorizedService.post(`/create-setup-intents`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getProducts = async () =>
  ParentAuthorizedService.get(`/list-of-products`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const userSubscriptionStatus = async data =>
  ParentAuthorizedService.get(`/user-payment/${data}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const cancelSubscriptionService = async data =>
  ParentAuthorizedService.post(`/cancel-subscription`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
export const createUserPayment = async data =>
  ParentAuthorizedService.post(`/user-payment`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
export const createPaymentLogs = async data =>
  ParentAuthorizedService.post(`/payment-logs`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
export const cancelFreeSubscription = async data =>
  ParentAuthorizedService.post(`/user-payment/cancel/free-plan`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
export const getPaymentLogsByUserId = async id =>
  ParentAuthorizedService.get(`/payment-logs/user/${id}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
export const redeemCode = async data =>
  ParentAuthorizedService.post(`/coupon-code/use?code=${data.code}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
