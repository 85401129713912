import React from 'react';
import ReactPlayer from 'react-player';
import CautionIcon from '../../../../../images/settings/Artwork.svg';

const VideoPlayer = ({ activeLesson, videoNum, afterVideoFinish, onStartVideo }) => {
  return (
    <div>
      {activeLesson?.videos[videoNum]?.url ? (
        <ReactPlayer
          url={activeLesson.videos[videoNum].url}
          playing
          controls
          onEnded={afterVideoFinish}
          onStart={onStartVideo}
        />
      ) : (
        <div className="no-content-wrapper">
          <img src={CautionIcon} />
          <span>No content available</span>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
