import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { ReactComponent as DiscountIcon } from '../../../../../images/settings/Discount.svg';

const RedeemCode = ({ redeemCode, setVisible }) => {
  const [code, setCode] = useState(null);
  const handleInputCode = e => {
    setCode(e.target.value);
  };
  const handleSubmit = () => {
    redeemCode(code);
    setVisible(false);
  };
  return (
    <div className="redeem-code-wrapper">
      <span className="title">Redeem Discount Code</span>
      <Input
        onChange={handleInputCode}
        className="code-input"
        type="number"
        placeholder="Enter your discount code"
        prefix={<DiscountIcon />}
      />
      <Button onClick={handleSubmit} className="code-button">
        Redeem
      </Button>
    </div>
  );
};

export default RedeemCode;
