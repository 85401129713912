import React from 'react';
import Logo from '../../../images/footer/Logo.png';

const RegisterHeading = () => (
  <div>
    <img className="heading-img" src={Logo} alt="logo" />
  </div>
);

export default RegisterHeading;
