export const t = {
  CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
  SET_CLIENT_SECRET: 'SET_CLIENT_SECRET',
  SET_SUBSCRIPTION_ID: 'SET_SUBSCRIPTION_ID',
  SET_PLAN: 'SET_PLAN',
  GET_PRODUCTS: 'GET_PRODUCTS',
  SET_PRODUCTS: 'SET_PRODUCTS',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  SET_PAYMENT_LOGS: 'SET_PAYMENT_LOGS',
  GET_PAYMENT_LOGS: 'GET_PAYMENT_LOGS',
  CREATE_SETUP_INTENTS: 'CREATE_SETUP_INTENTS',
  CREATE_TRIAL_SUBSCRIPTION: 'CREATE_TRIAL_SUBSCRIPTION',
  SET_IS_LOADING_TRIAL: 'SET_IS_LOADING_TRIAL',
  REDEEM_CODE: 'REDEEM_CODE',
};
