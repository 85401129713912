import { all, call, takeLeading, put } from 'redux-saga/effects';
import { notification } from 'antd';
import { t, setPoints, setLessonQuiz } from './actions';
import { postAnswersQuizService } from '../../../../services/quizService';
import { setNewErrorNotification } from '../../../../actions/errorNotification';

function* submitQuiz(action) {
  const { id, quiz, correctAns } = action.payload;

  const postData = {
    quizId: id,
    answerAttempts: quiz,
  };
  const res = yield call(postAnswersQuizService, postData);
  const { data, error } = res;
  if (data) {
    yield put(setPoints(correctAns));
    yield put(setLessonQuiz(null));
  } else if (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    yield put(
      setNewErrorNotification({
        title: 'Invalid Quiz Submission',
        description: message,
      })
    );
  }
}

export default function* parentSaga() {
  yield all([takeLeading(t.SUBMIT_QUIZ, submitQuiz)]);
}
