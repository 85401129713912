import { validateEmail } from '../helpers';

const UseSubmitLogin = (values, actions, keepUserLoggedIn, setEmailErrorHandler) => {
  const onLogin = data => {
    setEmailErrorHandler(false);
    actions.login(data);
  };
  const parentLogin = () => {
    if (validateEmail(values.emailOrUsername)) {
      onLogin({
        email: values.emailOrUsername,
        password: values.password,
        keepUserLoggedIn: keepUserLoggedIn,
      });
    } else {
      setEmailErrorHandler(true);
    }
  };

  if (values.emailOrUsername.includes('@')) {
    parentLogin();
  } else {
    onLogin({
      username: values.emailOrUsername,
      password: values.password,
      keepUserLoggedIn: keepUserLoggedIn,
    });
  }
};

export default UseSubmitLogin;
