import React from 'react';
import classNames from 'classnames';
import { displayAvatar } from '../../../../../utils/helpers';

import '../../../login.scss';

const ProfileCard = ({ selectedProfileHandler, data, selectedProfile }) => {
  const handleOnClick = () => {
    selectedProfileHandler(data);
  };

  return (
    <div
      className={classNames({
        'login-dashboard-profile-card-active': selectedProfile?.id === data?.id,
        'login-dashboard-profile-card': selectedProfile?.id !== data?.id,
      })}
      onClick={handleOnClick}
    >
      <img src={displayAvatar(data)} />
      <div className="login-dashboard-text-wrapper">
        <p className="login-dashboard-text-name">{data?.firstName}</p>
        <p className="login-dashboard-text-scope">{data?.type?.toLowerCase()}</p>
      </div>
    </div>
  );
};

export default ProfileCard;
