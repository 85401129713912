import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SettingsItemIcon from '../../../images/layout/settingsMenuItem.svg';
import SettingsItemIconActive from '../../../images/layout/SettingsActive.svg';
import ChildrenIconActive from '../../../images/layout/childrenActive.svg';
import ChildrenIcon from '../../../images/layout/childrenIcon.svg';
import FaqIcon from '../../../images/layout/faq-icon.svg';
import FaqActive from '../../../images/layout/faq-icon-active.svg';
import MyCoursesIcon from '../../../images/navbar/my-courses-icon.svg';

import '../layout.scss';

const Menu = ({ user }) => {
  const parentMenuItems = [
    {
      title: 'Children',
      path: '/parent-profile',
      icon: ChildrenIcon,
      iconActive: ChildrenIconActive,
    },
    {
      title: 'Settings',
      path: '/parent-profile/settings',
      icon: SettingsItemIcon,
      iconActive: SettingsItemIconActive,
    },
    {
      title: 'FAQ',
      path: '/parent-profile/faq',
      icon: FaqIcon,
      iconActive: FaqActive,
    },
  ];
  const childMenuItems = [
    {
      title: 'My courses',
      path: '/courses',
      icon: MyCoursesIcon,
      iconActive: MyCoursesIcon,
    },
  ];

  const menuItems = user?.type === 'PARENT' ? parentMenuItems : childMenuItems;

  const menuItemMapping = useMemo(
    () =>
      menuItems.map(item => (
        <div>
          <Link
            to={item.path}
            className={classNames({
              'layout-menu-item': true,
              'layout-menu-item-active': window.location.pathname === item.path,
            })}
          >
            <img
              alt="menu-item-icon"
              src={window.location.pathname === item.path ? item.iconActive : item.icon}
            />
            {item.title}
          </Link>
        </div>
      )),
    [menuItems]
  );
  return <div className="layout-menu-wrapper">{menuItemMapping}</div>;
};

export default Menu;
