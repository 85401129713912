import { all, call, takeLeading, put } from 'redux-saga/effects';

import { notification } from 'antd';

import { t, setAvailableCoursesForChild } from './actions';
import { setIsLoading } from '../../../../actions/customLoader';
import { eventTrack, events } from '../../../../utils/googleAnalyticsEventTrack';
import {
  getAvailableCoursesForChilddData,
  enrollChildToCourse,
} from '../../../../services/coursesService';
import { setNewErrorNotification } from '../../../../actions/errorNotification';
import { generalErrorMessage } from '../../../../utils/constants';

function* getAvailableCoursesForChild(action) {
  const { childId } = action.payload;
  yield put(setIsLoading(true));
  const res = yield call(getAvailableCoursesForChilddData, childId);
  yield put(setIsLoading(false));

  const { data, error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setAvailableCoursesForChild(data));
  }
}

function* enrollChild(action) {
  const enrollData = action.enrollData;
  const res = yield call(enrollChildToCourse, enrollData);
  const { error } = res;
  if (error) {
    yield put(
      setNewErrorNotification({
        title: 'Child enrollment error',
        description: 'This child is already enrolled to the course.',
      })
    );
  } else {
    notification.success({
      message: 'Child enrolled!',
      description: 'Your child was successfully enrolled to the course!',
    });
    eventTrack(events.PARENT_EVENTS_CATEGORY, events.PARENT_EVENTS.CHILD_ENROLLED);
  }
}

export default function* enrollChildSaga() {
  yield all([
    takeLeading(t.GET_AVAILABLE_COURSES, getAvailableCoursesForChild),
    takeLeading(t.ENROLL_CHILD, enrollChild),
  ]);
}
