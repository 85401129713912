export const t = {
  SET_ANSWERS: 'SET_ANSWERS',
  SET_TEXT: 'SET_TEXT',
  SET_COINS: 'SET_COINS',
  SET_QUESTION_NUM: 'SET_QUESTION_NUM',
  SET_ANSWERS_ATTEMPTS: 'SET_ANSWERS_ATTEMPTS',
  SET_FINAL_SCREEN: 'SET_FINAL_SCREEN',
  DRAG_AND_DROP_CONTROLLER: 'DRAG_AND_DROP_CONTROLLER',
  DRAG_AND_DROP_CONFIRM_ANSWER: 'DRAG_AND_DROP_CONFIRM_ANSWER',
  RESET_ANSWERS_ATTEMPTS: 'RESET_ANSWERS_ATTEMPTS',
  RESET_STATE: 'RESET_STATE',
  SET_SUBMITTED_ANSWERS: 'SET_SUBMITTED_ANSWERS',
  QUIZ_CONTROLLER: 'QUIZ_CONTROLLER',
  SET_ANSWER: 'SET_ANSWER',
  SET_ACTIVE_ANSWER: 'SET_ACTIVE_ANSWER',
  SET_QUIZ_OVERVIEW_DATA: 'SET_QUIZ_OVERVIEW_DATA',
  GET_QUIZ_OVERVIEW_DATA: 'GET_QUIZ_OVERVIEW_DATA',
  SET_SELECTED_ANSWERS: 'SET_SELECTED_ANSWERS',
  MULTIPLE_CHOICE_QUIZ_CONTROLLER: 'MULTIPLE_CHOICE_QUIZ_CONTROLLER',
  SET_OVERVIEW: 'SET_OVERVIEW',
};
