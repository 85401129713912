import { ChildAuthorizedService, ParentAuthorizedService } from './apiService';

export const getLessonByIdService = async id =>
  ChildAuthorizedService.get(`lessons/${id}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getLessonReactions = async id =>
  ChildAuthorizedService.get(`reactions`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const makeReactionService = async (params, data) =>
  ChildAuthorizedService.post(`lessons/${params.lessonId}/chapters/${params.chapterId}/reactions`, {
    reactionId: data,
  })
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getLessonsByDateChild = async (from, to) =>
  ChildAuthorizedService.get(`lessons?from=${from}&to=${to}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const getLessonsByDateParent = async (from, to) =>
  ParentAuthorizedService.get(`/lessons?from=${from}&to=${to}`)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));

export const lessonWatched = async data =>
  ChildAuthorizedService.post(`lessons/watched`, data)
    .then(res => res)
    .catch(err => ({
      data: null,
      error: err,
    }));
