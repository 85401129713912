import React from 'react';
import { Button } from 'antd';
import LetsQuizIcon from '../../../../../images/quiz/LetsQuizIcon.svg';
import '../childCourseQuizzes.scss';

const LetsQuizScreen = ({ handleSubmit }) => {
  return (
    <div className="lets-quiz-wrapper-standalone">
      <div className="image-wrapper">
        <img src={LetsQuizIcon} alt="lets-quiz-img" />
      </div>
      <span className="lets-quiz-text">Time to collect some points</span>
      <span className="lets-quiz-text-orange">Let's Quiz</span>
      <Button className="lets-quiz-button" onClick={handleSubmit}>
        Let's Go!
      </Button>
    </div>
  );
};

export default LetsQuizScreen;
