import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import queryString from 'query-string';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import renderPasswordIcon from '../../utils/renderPasswordIcon';
import history from '../../history';
import RegisterHeading from '../Register/Heading';
import EmailIcon from '../../images/register/Email.svg';
import LockIcon from '../../images/register/Lock.svg';
import padlockIcon from '../../images/register/padlock.svg';
import { emailRules, passwordRules, repeatPasswordRules } from '../../utils/inputRules';

import './resetPassword.scss';
import '../Login/login.scss';

const ResetPassword = props => {
  const { actions, location, isSuccessful, layoutMode } = props;
  const [requestSent, setRequestSent] = useState(false);

  const sendResetPasswordEmail = values => {
    const { email } = values;
    actions.sendResetPasswordEmail(email);
  };
  const queryParams = queryString.parse(location.search, {
    decode: false,
  });
  const resetPassword = values => {
    const { password } = values;
    actions.resetPassword(queryParams.email, password, queryParams.token);
    setRequestSent(true);
  };
  useEffect(() => {
    if (requestSent && isSuccessful) {
      history.push('/login');
    }
  }, [requestSent, isSuccessful]);

  const handlePushToLogin = () => history.push('/login');

  return (
    <div
      className={classNames({
        background: true,
        'background-black': layoutMode === 'dark',
      })}
    >
      <div className="login-wrapper">
        <RegisterHeading />

        <div
          className={classNames({
            'login-form-box': true,
            'login-dashboard-wrapper': true,
            'login-form-box-black': layoutMode === 'dark',
          })}
        >
          {!queryParams.email ? (
            <>
              <div className="login-form-header">
                <p className="login-form-title">Reset Your Password</p>
                <p className="login-form-subtitle">
                  We will send you an email with instructions on how to reset your password.
                </p>
                <img src={padlockIcon} className="padlock-icon" alt="padlock" />
              </div>
              <Form onFinish={sendResetPasswordEmail}>
                <div className="login-content-box">
                  <div className="login-input-wrapper">
                    <Form.Item name="email" rules={emailRules}>
                      <Input
                        placeholder="Enter your email"
                        prefix={
                          <img className="login-input-icon" alt="email-icon" src={EmailIcon} />
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="login-content-box">
                  <Form.Item>
                    <button className="login-button">Reset Password</button>
                  </Form.Item>
                  <div onClick={handlePushToLogin}>
                    <p className="back-to-login">Back to Login Page</p>
                  </div>
                </div>
              </Form>
            </>
          ) : (
            <>
              <div className="login-form-header">
                <p className="login-form-title">Set New Password</p>
              </div>
              <Form onFinish={resetPassword}>
                <div className="login-content-box">
                  <div className="login-input-wrapper">
                    <Form.Item name="password" rules={passwordRules}>
                      <Input.Password
                        prefix={
                          <img className="login-input-icon" alt="email-icon" src={LockIcon} />
                        }
                        iconRender={renderPasswordIcon}
                        placeholder="New Password"
                      />
                    </Form.Item>
                  </div>

                  <div className="login-input-wrapper">
                    <Form.Item name="confirm" rules={repeatPasswordRules}>
                      <Input.Password
                        autoComplete="off"
                        placeholder="Confirm New Password"
                        prefix={
                          <img className="login-input-icon" alt="email-icon" src={LockIcon} />
                        }
                        iconRender={renderPasswordIcon}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="login-content-box">
                  <Form.Item>
                    <button className="login-button">Change password</button>
                  </Form.Item>
                  <div className="center-content" onClick={handlePushToLogin}>
                    <p className="back-to-login">Cancel</p>
                  </div>
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  actions: PropTypes.shape({
    sendResetPasswordEmail: PropTypes.func,
    resetPassword: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  isSuccessful: PropTypes.bool.isRequired,
};

export default ResetPassword;
