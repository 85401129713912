import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import { Form, Input, DatePicker, Popover, Button } from 'antd';
import {
  addChildNameRules,
  addChildUsernameRules,
  addChildPasswordRules,
  addChildDateRules,
} from '../../../utils/inputRules';
import LockIcon from '../../../images/child/Lock.svg';
import ProfileIcon from '../../../images/child/Profile.svg';
import editAvatarIcon from '../../../images/child/edit-avatar.svg';
import usernameIcon from '../../../images/child/Username.png';
import usernameValidIcon from '../../../images/child/Check.svg';
import usernameInvalidIcon from '../../../images/child/Error.svg';
import calendarIcon from '../../../images/child/Calendar.svg';
import renderPasswordIcon from '../../../utils/renderPasswordIcon';
import usernameDefaultIcon from '../../../images/child/GrayCheck.svg';
import { eventTrack, events } from '../../../utils/googleAnalyticsEventTrack';
import './addChild.scss';

const AddChild = props => {
  const { actions, avatars, usernameTaken } = props;
  const [avatar, setAvatar] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeChild, setActiveChild] = useState('Boy');
  const [username, setUsername] = useState('');

  const defaultAvatarBoy = avatars[8];
  const defaultAvatarGirl = avatars[6];
  const [form] = Form.useForm();

  const hide = () => {
    setOpen(false);
  };

  const toggleOpenChange = () => {
    setOpen(!open);
  };

  const handleActiveChild = parent => () => {
    setActiveChild(parent);
  };

  useEffect(() => {
    actions.getAvatars();
  }, []);
  const before5Years = moment().add(-5, 'years');
  const before12Years = moment().add(-12, 'years');

  const disabledDate = current => {
    return (current && before5Years <= current) || before12Years >= current;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    eventTrack(events.PARENT_EVENTS_CATEGORY, events.PARENT_EVENTS.START_ADDING_CHILD);
  }, []);

  const avatarHandler = property => {
    if (avatar) {
      return avatar?.[property];
    }
    return activeChild === 'Boy' ? defaultAvatarBoy?.[property] : defaultAvatarGirl?.[property];
  };
  const [debouncedCallback] = useDebouncedCallback(
    (e) => {
      actions.checkUsername(e.target.value);
      setUsername(e.target.value)
    },
    500
  );

  const onFinish = values => {
    const firstName = values.name;
    const username = values.username;
    const password = values.password;
    const dateOfBirth = values['date-picker']?.format('YYYY-MM-DD');
    const gender = activeChild === 'Boy' ? 'M' : 'F';
    const child = {
      firstName,
      username,
      password,
      dateOfBirth,
      gender,
      avatarId: avatarHandler('id'),
    };
    actions.addNewChild(child);
  };

  const setAvatarHandler = item => () => {
    setAvatar(item);
  };

  const avatarMapping = useMemo(
    () =>
      avatars?.map(item => (
        <div
          className={classNames({
            'avatars-item': true,
            'selected-avatar': item.id === avatar?.id,
          })}
          key={item.id}
        >
          <img
            alt="avatar"
            onClick={setAvatarHandler(item)}
            src={item.url}
            className="choose-avatar-img"
          />
        </div>
      )),
    [avatar?.id, avatars]
  );

  const suffixIcon = () => {
    if (username !== '' && username.length > 4) {
      return usernameTaken ? (
        <img src={usernameInvalidIcon} alt="username-invalid" />
      ) : (
        <img src={usernameValidIcon} alt="username-valid" />
      )
    } return <img src={usernameDefaultIcon} alt="username-valid" />
  }

  return (
    <div className={`add-child-wrapper ${activeChild}-bg`}>
      <div className="add-child-avatar-and-gender">
        <p className="add-child-title">Create a Child Profile</p>
        <div className="add-child-gender-wrapper">
          <div className="switch-container">
            <button className="switch-btn" onClick={handleActiveChild('Boy')}>
              <p
                className={classNames({
                  'btn-text': true,
                  'btn-color-boy': activeChild === 'Boy',
                })}
              >
                Boy
              </p>
            </button>
            <button className="switch-btn" onClick={handleActiveChild('Girl')}>
              <p
                className={classNames({
                  'btn-text': true,
                  'btn-color-girl': activeChild === 'Girl',
                })}
              >
                Girl
              </p>
            </button>
            <div className={`active-switch-overlay ${activeChild}`}></div>
          </div>
        </div>
      </div>
      <Form form={form} name="add-child" onFinish={onFinish} className="add-child-form">
        <div className="add-child-profile-info">
          <p className="add-child-section">1. Select an avatar</p>
          <Popover
            overlayClassName="popover-wrapper"
            title="Choose avatar"
            content={
              <div className="popover-content">
                {avatarMapping}
                <Button onClick={hide} className="confirm-button">
                  Confirm
                </Button>
              </div>
            }
            trigger="click"
            visible={open}
            onVisibleChange={toggleOpenChange}
          >
            <div onClick={toggleOpenChange} className="add-child-avatar-wrapper">
              <div className="down-icon">
                <img src={editAvatarIcon} alt="edit-avatar" />
              </div>
              <img className="add-child-avatar" src={avatarHandler('url')} alt="avatar" />
            </div>
          </Popover>
          <p className="add-child-section">2. Complete child information</p>
          <div className="add-child-input-wrapper">
            <Form.Item name="name" rules={addChildNameRules}>
              <Input
                prefix={<img src={ProfileIcon} alt="profile-icon" />}
                className="add-child-input capitalized"
                placeholder="Child Name"
              />
            </Form.Item>
          </div>
          <div className="add-child-input-wrapper">
            <Form.Item
              name="username"
              normalize={value => value.replaceAll(' ', '_')}
              rules={addChildUsernameRules}
            >
              <Input
                className="add-child-input"
                placeholder="Create Username"
                prefix={<img src={usernameIcon} alt="username" />}
                autoComplete="off"
                suffix={suffixIcon()}
                onChange={debouncedCallback}
              />
            </Form.Item>
          </div>
          <div className="add-child-input-wrapper">
            <Form.Item name="password" rules={addChildPasswordRules}>
              <Input.Password
                prefix={<img src={LockIcon} alt="lock-icon" />}
                autoComplete="new-password"
                iconRender={renderPasswordIcon}
                className="add-child-input"
                placeholder="Create Pin"
                maxLength={4}
              />
            </Form.Item>
          </div>
          <div className="add-child-input-wrapper">
            <Form.Item name="date-picker" rules={addChildDateRules}>
              <DatePicker
                disabledDate={disabledDate}
                placeholder="Choose age"
                className="add-child-input"
                suffixIcon={<img src={calendarIcon} alt="date-icon" />}
                allowClear={false}
                defaultPickerValue={before5Years}
              />
            </Form.Item>
          </div>
          <button className="add-child-form-button">Create Profile</button>
        </div>
      </Form>
    </div>
  );
};

export default AddChild;


