import { all, call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { t, setCourses, setCurrentUser } from './actions';
import { getCoursesData } from '../../../../services/coursesService';
import { getCurrentUserData } from '../../../../services/notificationService';
import { setIsLoading } from '../../../../actions/customLoader';
import { setNewErrorNotification } from '../../../../actions/errorNotification';
import { generalErrorMessage } from '../../../../utils/constants';

function* getCurrentUser(userType) {
  yield put(setIsLoading(true));
  const { data, error } = yield call(getCurrentUserData, userType);
  yield put(setIsLoading(false));

  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setCurrentUser(data));
  }
}

function* getCourses() {
  const { data, error } = yield call(getCoursesData);
  if (error) {
    yield put(
      setNewErrorNotification({
        title: generalErrorMessage,
        description: error.data.message,
      })
    );
  } else {
    yield put(setCourses(data));
  }
}

export default function* parentSaga() {
  yield all([
    takeLatest(t.GET_CURRENT_USER, getCurrentUser),
    takeLatest(t.GET_COURSES, getCourses),
  ]);
}
