export const t = {
  SET_QUIZ_BY_ID: 'SET_QUIZ_BY_ID',
  SUBMIT_QUIZ: 'SUBMIT_QUIZ',
  CORRECT_ANSWERS: 'CORRECT_ANSWERS',
  SET_LESSON_QUIZ: 'SET_LESSON_QUIZ',
};
export const setQuizById = quiz => ({
  type: t.SET_QUIZ_BY_ID,
  quiz,
});

export const setLessonQuiz = quiz => ({
  type: t.SET_LESSON_QUIZ,
  quiz,
});

export const submitQuiz = (id, quiz, correctAns) => ({
  type: t.SUBMIT_QUIZ,
  payload: {
    quiz,
    id,
    correctAns,
  },
});
export const setPoints = points => ({
  type: t.CORRECT_ANSWERS,
  points,
});
