import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getWeekOverview,
  getWeeksList,
  getChildProfileAction,
  startLesson,
  startQuiz,
} from '../../../modules/actions';
import {
  getCourseById,
  setCourse,
  setWatchNowModal,
  setLessonModal,
  setQuizModal,
  setActiveLesson,
  setActiveQuiz,
} from '../../../../Child/ChildDashboard/modules/actions';

const mapStateToProps = state => ({
  weekOverview: state.CourseReducer.weekOverview,
  childProfile: state.CourseReducer.childProfile,
  watchNowModal: state.ChildLessonReducer.watchNowModal,
  quizModal: state.ChildLessonReducer.quizModal,
  lessonModal: state.ChildLessonReducer.lessonModal,
  layoutMode: state.AuthReducer.layoutMode,
  activeLesson: state.ChildLessonReducer.activeLesson,
  activeQuiz: state.ChildLessonReducer.activeQuiz,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getWeekOverview,
      getWeeksList,
      getChildProfileAction,
      getCourseById,
      setActiveLesson,
      setActiveQuiz,
      setCourse,
      setWatchNowModal,
      setLessonModal,
      setQuizModal,
      startLesson,
      startQuiz,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
