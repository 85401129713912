import { ParentAuthorizedService, AuthorizedApiService } from './apiService';

const BASE_PATH = '/me';

export const getParentChildren = async () => {
  try {
    const response = await AuthorizedApiService.get(`${BASE_PATH}/children`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};
export const getParentChildrenAndActivities = async id => {
  try {
    const response = await AuthorizedApiService.get(`all-children-and-activities?parentId=${id}`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getParentData = async () => {
  try {
    const response = await AuthorizedApiService.get(`${BASE_PATH}`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

export const getChildProfile = async id => {
  try {
    const response = await ParentAuthorizedService.get(`${BASE_PATH}/children/${id}`);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};
