import React from 'react';
import { Button } from 'antd';
import Confetti from 'react-confetti';
import Crown from '../../../../../images/quiz/Crown.svg';
import '../../childDashboard.scss';

const PointsScreen = ({ handleOverview, coins }) => {
  const correctAnswersCount = Math.floor(coins / 10);

  return (
    <div className="final-screen-wrapper">
      <div className="lets-quiz-wrapper">
        <Confetti numberOfPieces={800} recycle={false} />
        <img src={Crown} alt="lets-quiz-img" />
        <span className="lets-quiz-text">You collected</span>
        <span className="lets-quiz-text-orange">{coins} Points</span>
        <span className="lets-quiz-text-gray">
          You answered {correctAnswersCount} {correctAnswersCount > 1 ? 'questions' : 'question'}{' '}
          correct
        </span>
        <Button className="lets-quiz-button" onClick={handleOverview}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default PointsScreen;
