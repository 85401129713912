import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentChild, setCurrentChild } from '../ParentProfile/modules/actions';
import { getActivities, getCoursesOverviewForChild } from './modules/actions';

const mapStateToProps = state => ({
  child: state.ParentReducer.currentChild,
  activities: state.ChildToDoReducer.activities,
  hasMore: state.ChildToDoReducer.hasMoreActivities,
  activityType: state.ChildToDoReducer.activityType,
  courses: state.ChildToDoReducer.courses,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCurrentChild,
      getActivities,
      setCurrentChild,
      getCoursesOverviewForChild,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
