export const types = {
  IS_QUIZ: 'IS_QUIZ',
  NOT_QUIZ: 'NOT_QUIZ',
  OPEN_LETS_QUIZ_SCREEN: 'OPEN_LETS_QUIZ_SCREEN',
  CLOSE_LETS_QUIZ_SCREEN: 'CLOSE_LETS_QUIZ_SCREEN',
  COINS: 'COINS',
  INCREMENT_QUESION_NUM: 'INCREMENT_QUESION_NUM',
  DECREMENT_QUESION_NUM: 'DECREMENT_QUESION_NUM',
  RESTART_QUESION_NUM: 'RESTART_QUESION_NUM',
  INCREMENT_VIDEO_NUM: 'INCREMENT_VIDEO_NUM',
  RESTART_VIDEO_NUM: 'RESTART_VIDEO_NUM',
  FINAL_SCREEN: 'FINAL_SCREEN',
  SET_ANSWER_VALUE: 'SET_ANSWER_VALUE',
  CLEAR_ANSWER_VALUE: 'CLEAR_ANSWER_VALUE',
  SET_ACTIVE_ANSWER_VALUE: 'SET_ACTIVE_ANSWER_VALUE',
  CLEAR_ACTIVE_ANSWER_VALUE: 'CLEAR_ACTIVE_ANSWER_VALUE',
  UPDATE_POINTS: 'UPDATE_POINTS',
  GET_QUIZ_BY_ID_IS_COMPLETED: 'GET_QUIZ_BY_ID_IS_COMPLETED',
  SET_QUIZ_BY_ID_IS_COMPLETED: 'SET_QUIZ_BY_ID_IS_COMPLETED',
  POST_ANSWER_QUIZ: 'POST_ANSWER_QUIZ',
  POST_LESSON_WATCHED: 'POST_LESSON_WATCHED',
  WATCH_NOW_CONTROLLER: 'WATCH_NOW_CONTROLLER',
  RESTART_COINS: 'RESTART_COINS',
  NOT_FINAL_SCREEN: 'NOT_FINAL_SCREEN',
  RESET_STATE: 'RESET_STATE',
};
