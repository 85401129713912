export const t = {
  GET_LESSON_ACTIVITY: 'GET_LESSON_ACTIVITY',
  SET_LESSON_ACTIVITY: 'SET_LESSON_ACTIVITY',
  GET_ACTIVITIES: 'GET_ACTIVITIES',
  SET_ACTIVITIES: 'SET_ACTIVITIES',
  GET_COURSES_OVERVIEW: 'GET_COURSES_OVERVIEW',
  SET_COURSES_OVERVIEW: 'SET_COURSES_OVERVIEW',
  ADD_ACTIVITIES: 'ADD_ACTIVITIES',
  SET_HAS_MORE_ACTIVITIES: 'SET_HAS_MORE_ACTIVITIES',
  SET_ACTIVITY_TYPE: 'SET_ACTIVITY_TYPE',
};
export const getLessonActivity = lesson => ({
  type: t.GET_LESSON_ACTIVITY,
  lesson,
});
export const setLessonActivity = activity => ({
  type: t.SET_LESSON_ACTIVITY,
  activity,
});
export const setPastLessons = lessons => ({
  type: t.SET_PAST_LESSONS,
  lessons,
});

export const getActivities = (childId, type, page, activityType) => ({
  type: t.GET_ACTIVITIES,
  payload: {
    childId,
    type,
    page,
    activityType,
  },
});

export const setActivities = activities => ({
  type: t.SET_ACTIVITIES,
  payload: {
    activities,
  },
});

export const getCoursesOverviewForChild = childId => ({
  type: t.GET_COURSES_OVERVIEW,
  payload: {
    childId,
  },
});

export const setCoursesOverviewForChild = courses => ({
  type: t.SET_COURSES_OVERVIEW,
  payload: {
    courses,
  },
});

export const addActivities = activities => ({
  type: t.ADD_ACTIVITIES,
  payload: {
    activities,
  },
});

export const setHasMoreActivities = hasMore => ({
  type: t.SET_HAS_MORE_ACTIVITIES,
  payload: {
    hasMore,
  },
});

export const setActivityType = activityType => ({
  type: t.SET_ACTIVITY_TYPE,
  payload: {
    activityType,
  },
});
