import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Form, Input, Checkbox } from 'antd';
import LockIcon from '../../../images/register/Lock.svg';
import EmailIcon from '../../../images/register/Email.svg';
import { emailUsernameRules, passwordRules } from '../../../utils/inputRules';

const LoginForm = ({
  onFinish,
  emailError,
  renderPasswordIcon,
  trackPixelEvent,
  keepUserLoggedInHandler,
  layoutMode,
}) => {
  const [email, setEmail] = useState(null);

  const handleTrackPixelEvent = () => {
    trackPixelEvent('ForgotPassword');
  };

  const onFormChange = data => {
    setEmail(data.emailOrUsername);
  };

  return (
    <div
      className={classNames({
        'login-form-box': true,
        'login-form-box-black': layoutMode === 'dark',
      })}
    >
      <div className="login-form-header">
        <p className="login-form-title">Welcome To Kutuby</p>
        <p className="login-form-subtitle">Please login with your user credentials.</p>
      </div>
      <Form name="login" onFinish={onFinish} onValuesChange={onFormChange}>
        <div className="login-content-box">
          <div className="login-input-wrapper">
            <Form.Item name="emailOrUsername" rules={emailUsernameRules}>
              <Input
                prefix={<img className="login-input-icon" alt="email-icon" src={EmailIcon} />}
                placeholder="Email address or Username"
              />
            </Form.Item>
            {emailError && <p className="email-error-msg">Please input valid email!</p>}
          </div>
          <div className="login-input-wrapper">
            <Form.Item name="password" rules={passwordRules}>
              <Input.Password
                prefix={<img className="login-input-icon" alt="lock-icon" src={LockIcon} />}
                placeholder="Password/PIN"
                iconRender={renderPasswordIcon}
              />
            </Form.Item>
          </div>
          <div className="login-checkbox-box">
            <Form.Item name="keep_loggedin" className="login-keep-me-login-wrapper">
              <Checkbox className="login-checkbox" onChange={keepUserLoggedInHandler} />
              <p className="login-forgot-password">Keep Me Logged In</p>
            </Form.Item>
            <NavLink
              to="/reset-password"
              className="login-forgot-password"
              onClick={handleTrackPixelEvent}
            >
              Forgot password?
            </NavLink>
          </div>
        </div>
        <div className="login-content-box">
          <Form.Item className="center-content ">
            <button className="login-button">Login</button>
          </Form.Item>
          <div className="center-content">
            <NavLink to="/register" className="back-to-login" onClick={handleTrackPixelEvent}>
              New to Kutuby? Register Here
            </NavLink>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
