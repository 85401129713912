/* eslint-disable import/no-anonymous-default-export */
import { t } from '../actions/customLoader';
const defaultState = {
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
};
