import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getChildProfile,
  getLessons,
  getSingleQuiz,
  getQuizById,
  getLessonById,
  setLessons,
  getCourseById,
  setCourse,
  setWatchNowModal,
  setLessonModal,
  setQuizModal,
  setActiveLesson,
  setActiveQuiz,
} from './modules/actions';
import { resetState } from './ChildLessonWatchNow/modules/actions';
import { getUserPayment } from '../../../actions/userPayment';
import {
  resetState as resetQuizState,
  setFinalScreen,
  setOverview,
} from './ChildCourseQuizzes/modules/actions';

const mapStateToProps = state => ({
  lessons: state.ChildLessonReducer.lessons,
  course: state.ChildLessonReducer.course,
  hasMore: state.ChildLessonReducer.hasMoreLessons,
  child: state.AuthReducer.user,
  single_quiz_banner: state.ChildLessonReducer.single_quiz_banner,
  quiz_to_do: state.QuizReducer.quizToDo,
  lesson_to_attend: state.ChildLessonReducer.lesson_to_attend,
  watchNowModal: state.ChildLessonReducer.watchNowModal,
  quizModal: state.ChildLessonReducer.quizModal,
  lessonModal: state.ChildLessonReducer.lessonModal,
  layoutMode: state.AuthReducer.layoutMode,
  activeLesson: state.ChildLessonReducer.activeLesson,
  activeQuiz: state.ChildLessonReducer.activeQuiz,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getChildProfile,
      getSingleQuiz,
      getQuizById,
      getLessonById,
      getLessons,
      setLessons,
      getCourseById,
      setCourse,
      setWatchNowModal,
      setLessonModal,
      resetState,
      getUserPayment,
      setQuizModal,
      setActiveLesson,
      setActiveQuiz,
      resetQuizState,
      setFinalScreen,
      setOverview,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
