/* eslint-disable import/no-anonymous-default-export */
import { t } from './actions';
const defaultState = {
  avatars: [],
  usernameTaken: true,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_AVATARS:
      return {
        ...state,
        avatars: action.avatars,
      };
    case t.SET_USERNAME_TAKEN:
      return {
        ...state,
        usernameTaken: action.usernameTaken,
      };
    default:
      return state;
  }
};
