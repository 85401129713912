import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const style = {
  color: '#8E9092',
  fontSize: '20px',
};

const iconRender = visible =>
  visible ? <EyeOutlined style={style} /> : <EyeInvisibleOutlined style={style} />;

export default iconRender;
