import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';

import renderPasswordIcon from '../../../../utils/renderPasswordIcon';
import LockIcon from '../../../../images/child/Lock.svg';
import {
  passwordRegisterRules,
  passwordRules,
  repeatPasswordRules,
  addChildPasswordRules,
} from '../../../../utils/inputRules';

const ChangeParentPassword = props => {
  const { actions, childId, setVisible } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = values => {
    actions.changePassword(values.oldPassword, values.password, childId);
    setVisible(false);
  };

  return (
    <div className="edit-first-name-wrapper">
      <span className="edit-user-modal-title">
        {childId ? "Change child's PIN" : 'Change Your Password'}
      </span>
      <Form name="edit-first-name" onFinish={onFinish}>
        <Form.Item name="oldPassword" rules={passwordRules}>
          <Input.Password
            prefix={<img src={LockIcon} alt="lock-icon" />}
            iconRender={renderPasswordIcon}
            className="edit-user-input"
            placeholder={childId ? 'Parent password' : 'Old password'}
          />
        </Form.Item>

        <Form.Item name="password" rules={childId ? addChildPasswordRules : passwordRegisterRules}>
          <Input.Password
            prefix={<img src={LockIcon} alt="lock-icon" />}
            iconRender={renderPasswordIcon}
            className="edit-user-input"
            placeholder={childId ? 'Enter Child PIN' : 'Enter New Password'}
          />
        </Form.Item>

        <Form.Item name="repeatPassword" rules={repeatPasswordRules}>
          <Input.Password
            prefix={<img src={LockIcon} alt="lock-icon" />}
            iconRender={renderPasswordIcon}
            className="edit-user-input"
            placeholder={childId ? 'Repeat Child PIN' : 'Repeat New Password'}
          />
        </Form.Item>

        <Form.Item>
          <Button className="edit-user-button" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangeParentPassword;
