import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import deletedUserIcon from '../../../../../images/settings/ArtworkDeletedUser.svg';

const DeletedUser = () => {
  const history = useHistory();
  const redirectToLogin = () => {
    history.push('/login');
  };
  return (
    <div className="background">
      <div className="deleted-user-wrapper">
        <img src={deletedUserIcon} alt="delete-icon" />
        <span className="subtitle">We are sorry to see you go,</span>
        <span className="title">Join Kutuby again soon</span>
        <Button className="deleted-user-button" onClick={redirectToLogin}>
          Done
        </Button>
      </div>
    </div>
  );
};

export default DeletedUser;
