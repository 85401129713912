import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getWeekOverview, getWeeksList, getChildProfileAction } from '../../../modules/actions';
import {
  setWatchNowModal,
  setQuizModal,
  setActiveLesson,
  setActiveQuiz,
} from '../../../../Child/ChildDashboard/modules/actions';
import { resetState } from '../../../../Child/ChildDashboard/ChildCourseQuizzes/modules/actions';

const mapStateToProps = state => ({
  weekOverview: state.CourseReducer.weekOverview,
  weeksList: state.CourseReducer.weeksList,
  childProfile: state.CourseReducer.childProfile,
  watchNowModal: state.ChildLessonReducer.watchNowModal,
  quizModal: state.ChildLessonReducer.quizModal,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getWeekOverview,
      getWeeksList,
      getChildProfileAction,
      resetState,
      setActiveLesson,
      setActiveQuiz,
      setWatchNowModal,
      setQuizModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
