import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Plus from '../../../../images/payment/Plus.svg';
import Crispy from '../../../../images/payment/Crispy.svg';
import Logo from '../../../../images/payment/trialBannerLogo.svg';
import { Button } from 'antd';
import '../../payment.scss';
import JwtDecode from 'jwt-decode';

const TrialBanner = ({ actions, paymentLogs, isLoading }) => {
  const token = localStorage.getItem('parent_profile_token');
  let decoded;
  if (token) {
    decoded = JwtDecode(token);
  }

  const history = useHistory();

  const pushToPayment = () => {
    history.push('/plans');
  };

  useEffect(() => {
    actions.getPaymentLogs(decoded?.user?.id);
  }, [actions]);

  if (isLoading || (paymentLogs && paymentLogs.length > 0) || !token) return null;

  return (
    <div className="trial-banner-wrapper">
      <div className="pro-logo-banner">
        <img src={Logo} alt="plus-logo" />
        <span className="pro-logo-plus-mark">PLUS</span>
      </div>
      <span className="title">2 Weeks Free Trial</span>
      <Button onClick={pushToPayment}>
        <img src={Plus} alt="rocket-icon" />
        UPGRADE
      </Button>
      <img src={Crispy} alt="Crispy" />
    </div>
  );
};

export default TrialBanner;
