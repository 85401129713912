import React from 'react';
import { Button } from 'antd';
import CautionIcon from '../../../../../images/settings/Artwork.svg';

const CautionModal = ({ title, buttonText, description, descriptionSm, onSubmit, setVisible }) => {
  const onFinish = () => {
    onSubmit();
    setVisible(false);
  };

  return (
    <div className="caution-modal-wrapper">
      <span className="title">{title}</span>
      <img src={CautionIcon} alt="caution-icon" />
      <span className="description">{description}</span>
      <span className="descriptionSm">{descriptionSm}</span>
      <Button className="caution-button" onClick={onFinish}>
        {buttonText}
      </Button>
    </div>
  );
};

export default CautionModal;
