import React, { useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';

import './scrollToTop.scss';

const ScrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(pageYOffset > 700);
  }, [pageYOffset]);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  if (!visible) {
    return false;
  }

  return (
    <div className="scroll-to-top cursor-pointer text-center" onClick={scrollToTop}>
      <i className="icon fa fa-chevron-up"></i>
    </div>
  );
};

export default ScrollToTop;
