import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { Popover, Button } from 'antd';
import EditUserItem from '../../EditUser/EditUserItem';
import LogoutIcon from '../../../images/settings/logout.svg';
import ProfileIcon from '../../../images/settings/Profile.svg';
import LockIcon from '../../../images/child/Lock.svg';
import usernameIcon from '../../../images/child/Username.png';
import editAvatarIcon from '../../../images/child/edit-avatar.svg';

import './editChildProfile.scss';
import '../AddChild/addChild.scss';
import ChangeParentPassword from '../ParentSettings/ChangeParentPassword';
import { addChildNameRules } from '../../../utils/inputRules';
import CautionModal from '../ParentSettings/Settings/components/CautionModal';
import EditChildAge from './EditChildAge';
import calendarIcon from '../../../images/child/Calendar.svg';

const EditChildProfile = ({ child, actions, avatars }) => {
  const location = useLocation();
  const childId = location?.state?.id;
  const [avatar, setAvatar] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const toggleOpenChange = () => {
    setOpen(prev => !prev);
  };

  const setAvatarHandler = item => () => {
    setAvatar(item);
  };

  const deleteAccount = () => {
    actions.deleteUser(child.id, false);
    history.push('/parent-profile');
  };

  useEffect(() => {
    actions.getCurrentChild(childId);
    actions.getAvatars();
  }, [childId]);

  useEffect(() => {
    setAvatar(child?.avatar);
  }, [child]);

  const avatarMapping = useMemo(
    () =>
      avatars?.map(item => (
        <div
          className={classNames({
            'avatars-item': true,
            'selected-avatar': item.id === avatar?.id,
          })}
          key={item.id}
        >
          <img
            alt="avatar"
            onClick={setAvatarHandler(item)}
            src={item.url}
            className="choose-avatar-img"
          />
        </div>
      )),
    [avatar?.id, avatars]
  );

  const editChildAvatar = () => {
    actions.updateUser({
      dataToEdit: { avatarId: avatar?.id },
      id: child.parent.id,
      childId: child.id,
    });
    setOpen(false);
  };

  const editDateOfBirth = newDate => {
    actions.updateUser({
      dataToEdit: { dateOfBirth: newDate },
      id: child.parent.id,
      childId: child.id,
    });
  };

  const editChildFirstName = data => {
    actions.updateUser({
      dataToEdit: data.dataToEdit,
      id: child.parent.id,
      childId: child.id,
    });
  };

  return (
    <div className="background">
      <div className="edit-child-wrapper">
        <span className="edit-child-profile-title">Edit Profile</span>

        <div className="edit-child-form-wrapper">
          <span className="section-title">Avatar</span>
          <div className="edit-chid-avatar">
            <Popover
              overlayClassName="popover-wrapper"
              title="Choose avatar"
              content={
                <div className="popover-content">
                  {avatarMapping}
                  <Button onClick={editChildAvatar} className="confirm-button">
                    Confirm
                  </Button>
                </div>
              }
              trigger="click"
              visible={open}
              onVisibleChange={toggleOpenChange}
            >
              <div className="add-child-avatar-wrapper">
                <div className="down-icon">
                  <img src={editAvatarIcon} alt="edit-avatar" />
                </div>
                <img className="add-child-avatar" src={avatar?.url} alt="avatar" />
              </div>
            </Popover>
          </div>
          <span className="section-title">Child Details</span>

          <EditUserItem
            item={child?.firstName}
            icon={ProfileIcon}
            modalTitle="Change Profile Name"
            formFields={[
              {
                name: 'firstName',
                placeholder: 'Change First Name',
              },
            ]}
            handleOnFinish={{
              action: editChildFirstName,
              parentId: child?.parent?.id,
              childId: child?.id,
            }}
            inputRules={addChildNameRules}
          />
          <EditUserItem item={child?.username} disabled icon={usernameIcon} />
          <EditUserItem
            item="*******"
            icon={LockIcon}
            modalTitle="Change Your Password"
            ModalContent={ChangeParentPassword}
            modalContentProps={{ childId: child?.id }}
            modalContentShow={true}
          />

          <EditUserItem
            item={`${child?.age} Years`}
            icon={calendarIcon}
            modalTitle="Change Your Password"
            ModalContent={EditChildAge}
            modalContentProps={{ onFinish: editDateOfBirth, defaultDate: child?.dateOfBirth }}
            modalContentShow={true}
          />

          <span className="section-title">More Settings</span>
          <EditUserItem
            item="Delete Account"
            icon={LogoutIcon}
            ModalContent={CautionModal}
            modalContentProps={{
              title: 'Delete a child',
              onSubmit: deleteAccount,
              description: 'Are you sure you want to delete a child?',
              descriptionSm: 'This cannot be undone',
              buttonText: 'Delete',
            }}
            modalContentShow={true}
          />
        </div>
      </div>
    </div>
  );
};

export default EditChildProfile;
